import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form as ReactstrapForm,
  Label,
  Input,
  Progress,
} from "reactstrap";
import AuthContext from "../../pages/authProvider";
import { mediaTypes } from "../../config/index";
import { Form, Field } from "react-final-form";
import { CustomDropdown, FormInput, MediaItemDropdown } from "./components";
import * as mediaManagementSelectors from "../../ducks/media-management/selectors";
import Wizard from "./wizardForm";
import {
  getCategoies,
  createMedia,
  uploadImage,
  uploadReels,
  submitForm,
  updateMediaType,
} from "../../ducks/media-management/index";
import { Sidebar } from "../../pages";
import Header from "../header";
import "./index.scss";

import { language } from "../../config";
import { actionTypeExists } from "../../ducks/generics";

const MediaManagement = (props) => {
  const [category, setCategory] = useState("");
  const [videopercentage, setVideoPercentage] = useState(0);
  const [image1percentage, setImage1Percentage] = useState(0);
  const [image2percentage, setImage2Percentage] = useState(0);
  const [imagepercentage, setImagePercentage] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [mediaCategoryItems, setMediaCategoryItems] = useState([]);
  const [videoFile, setvideoFile] = useState(null);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const {
    categories,
    imageData,
    videoData,
    audioData,
    imageUploading,
    audioUploading,
    videoUploading,
  } = props;

  // call category and subcategories api to load the data
  // useEffect(() => {
  //   const {
  //     actions,
  //   } = props;
  //   actions.getCategoies();
  // },[])

  const submitData = (formData) => {
    // console.log('media form data: ', {...formData});
  };

  const onSubmit = (values, page, reset) => {
    // window.alert(JSON.stringify(values, 0, 2));
    console.log("values", values);
    const { actions } = props;

    // page 0 will call meta api
    // const audio = document.getElementsByClassName('audio')[0].files[0];
    if (page == 0) {
      actions.submitForm(values, function (responseDate) {
        console.log("responseDate", responseDate);
        reset();
      });
      // actions.uploadAudio(values, updateAudioUploadPercentage, audio);
    }
    // // page 2 will redirect to media list page
    // if(page == 1) {
    //   // callbackFn(values, callbackFn);
    //   navigate('/media-list');
    // }
  };

  const firstvalidatePage = (values, video) => {
    const { actions } = props;
    const errors = {};

    if (!values.title) {
      errors.title = "Title is required";
    }
    if (!values.tags) {
      errors.tags = "Tags is required";
    }
    if (!values.description) {
      errors.description = "Description is required";
    }

    // merchant_logo
    // merchant_name
    // redirect_url
    // timestamp
    // product_name
    // product_image_url

    // update sub category dropdown
    if (values.category) {
      setCategory(values.category);
      actions.updateMediaType(values.category);
    }

    return errors;
  };

  const updateAudioUploadPercentage = (value) => {
    setImage1Percentage(value);
  };

  const handleImage1Upload = (e) => {
    const { actions, mediaId } = props;
    e.preventDefault();
    // console.log(videoFile);
    const image = document.getElementsByClassName("image1")[0].files[0];
    //validate
    if (!image) {
      window.alert("Please select an Image file!");
    } else {
      // let video = videoFile.video;
      // don't upload if file size if greater than 5 MB.
      // if (picture.size / (1024 * 1024) > 5) {
      //   window.alert('Please size should not exceed more than 5 MB!');
      // }
      // else if (picture.name.substr(picture.name.length - 4) !== 'jpeg' || picture.name.substr(picture.name.length - 3) !== 'jpg') {
      //   window.alert('Please upload jpg files only!');
      // } else {
      actions.uploadImage(image, mediaId, updateAudioUploadPercentage);
      // }
      // reset audio form value
      document.getElementsByClassName("image1")[0].value = null;
    }
  };

  const handleReelsUpload = (e) => {
    const { actions, mediaId } = props;
    e.preventDefault();
    // console.log(videoFile);
    const reel = document.getElementsByClassName("reels")[0].files[0];
    //validate
    if (!reel) {
      window.alert("Please select a Reels file!");
    } else {
      // let video = videoFile.video;
      // don't upload if file size if greater than 5 MB.
      // if (picture.size / (1024 * 1024) > 5) {
      //   window.alert('Please size should not exceed more than 5 MB!');
      // }
      // else if (picture.name.substr(picture.name.length - 4) !== 'jpeg' || picture.name.substr(picture.name.length - 3) !== 'jpg') {
      //   window.alert('Please upload jpg files only!');
      // } else {
      actions.uploadReels(reel, mediaId, updateAudioUploadPercentage);
      // }
      // reset audio form value
      document.getElementsByClassName("reels")[0].value = null;
    }
  };

  const handleThumbnailUpload = (e) => {
    const { actions, mediaId } = props;
    e.preventDefault();
    // console.log(videoFile);
    const image = document.getElementsByClassName("image1")[0].files[0];
    //validate
    if (!image) {
      window.alert("Please select an Image file!");
    } else {
      // let video = videoFile.video;
      // don't upload if file size if greater than 5 MB.
      // if (picture.size / (1024 * 1024) > 5) {
      //   window.alert('Please size should not exceed more than 5 MB!');
      // }
      // else if (picture.name.substr(picture.name.length - 4) !== 'jpeg' || picture.name.substr(picture.name.length - 3) !== 'jpg') {
      //   window.alert('Please upload jpg files only!');
      // } else {
      actions.uploadImage(image, mediaId, updateAudioUploadPercentage);
      // }
      // reset audio form value
      document.getElementsByClassName("image1")[0].value = null;
    }
  };

  const handleAudioFileChange = (e) => {
    const target = e.target;
    // console.log('file', target.files[0]);
  };
  const { authenticatedSession } = props;
  if (!authenticatedSession) {
    auth.setUserDataToNull();
    navigate("/", { replace: true });
  }
  console.log("form props", props);
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content">
        <div className="profile-cover bg-dark"></div>
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4>{"Add Media"}</h4>
                  </CardHeader>
                  <Wizard initialValues={{ test: "main" }} onSubmit={onSubmit}>
                    <Wizard.Page validate={firstvalidatePage}>
                      <CardBody>
                        <Row>
                          <Col md="6 mb-3">
                            <Field
                              name="category"
                              data={mediaTypes}
                              label={"Media Type"}
                            >
                              {(props) => <CustomDropdown {...props} />}
                            </Field>
                          </Col>
                        </Row>
                        {category === "Images" && (
                          <>
                            <Row>
                              <Col md="6 mb-3">
                                <Label htmlFor="image1">{"Upload Image"}</Label>
                                <Field name="image1">
                                  {(props) => (
                                    <>
                                      <Input
                                        className="form-control image1"
                                        name={props.input.name}
                                        onChange={props.input.onChange}
                                        // onChange={(e)=>handleAudioFileChange(e)}
                                        type="file"
                                      />
                                      {(props.meta.error ||
                                        props.meta.submitError) &&
                                        props.meta.touched && (
                                          <span className="error-text">
                                            {props.meta.error ||
                                              props.meta.submitError}
                                          </span>
                                        )}
                                    </>
                                  )}
                                </Field>
                                <br></br>
                                <Progress animated value={image1percentage} />
                              </Col>

                              <Col md="6 mb-3 upload-btn">
                                <Button
                                  color="primary"
                                  disabled={imageUploading}
                                  onClick={handleImage1Upload}
                                  className="mr-1"
                                >
                                  {"Upload Image"}
                                </Button>
                              </Col>
                            </Row>
                            {/* <Row>
                            <Col md="6 mb-3">
                              <Label htmlFor="image2">{"Upload Image2"}</Label>
                              <Field name="image2">
                                {props => (
                                  <>
                                    <Input
                                      className="form-control image2"
                                      name={props.input.name}
                                      onChange={props.input.onChange}
                                      // onChange={(e)=>handleAudioFileChange(e)}
                                      type="file" />
                                    {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                                      <span className="error-text">{props.meta.error || props.meta.submitError}</span>
                                    )}
                                  </>
                                )}
                              </Field>
                              <br></br>
                              <Progress animated value={image2percentage} />
                            </Col>
                            
                            <Col md="6 mb-3 upload-btn">
                              <Button  color="primary" disabled={audioUploading} onClick={handleImage1Upload} className="mr-1">{"Upload Image"}</Button>
                            </Col>
                          </Row> */}
                          </>
                        )}
                        {category === "Reels" && (
                          <>
                            <Row>
                              <Col md="6 mb-3">
                                <Label htmlFor="reels">{"Upload Reels"}</Label>
                                <Field name="reels">
                                  {(props) => (
                                    <>
                                      <Input
                                        className="form-control reels"
                                        name={props.input.name}
                                        onChange={props.input.onChange}
                                        // onChange={(e)=>handleAudioFileChange(e)}
                                        type="file"
                                      />
                                      {(props.meta.error ||
                                        props.meta.submitError) &&
                                        props.meta.touched && (
                                          <span className="error-text">
                                            {props.meta.error ||
                                              props.meta.submitError}
                                          </span>
                                        )}
                                    </>
                                  )}
                                </Field>
                                <br></br>
                                <Progress animated value={videopercentage} />
                              </Col>

                              <Col md="6 mb-3 upload-btn">
                                <Button
                                  color="primary"
                                  disabled={videoUploading}
                                  onClick={handleReelsUpload}
                                  className="mr-1"
                                >
                                  {"Upload Reels"}
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6 mb-3">
                                <Label htmlFor="ThumbnailImage">
                                  {"Upload Thumbnail Image"}
                                </Label>
                                <Field name="tileImageUrl">
                                  {(props) => (
                                    <>
                                      <Input
                                        className="form-control image1"
                                        name={props.input.name}
                                        onChange={props.input.onChange}
                                        // onChange={(e)=>handleAudioFileChange(e)}
                                        type="file"
                                      />
                                      {(props.meta.error ||
                                        props.meta.submitError) &&
                                        props.meta.touched && (
                                          <span className="error-text">
                                            {props.meta.error ||
                                              props.meta.submitError}
                                          </span>
                                        )}
                                    </>
                                  )}
                                </Field>
                              </Col>
                              <Col md="6 mb-3 upload-btn">
                                <Button
                                  color="primary"
                                  disabled={imageUploading}
                                  onClick={handleThumbnailUpload}
                                  className="mr-1"
                                >
                                  {"Upload Thumbnail"}
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                        {/* <Row>
                          <span>Audio size should not exceed 10 MB and only .mp3</span>
                        </Row> */}
                        {/* percentage bar */}
                        <Row>
                          <Col md="6 mb-3">
                            <Label htmlFor="title">{"Media Title"}</Label>
                            <Field name="title" placeholder="Enter Media Title">
                              {(props) => <FormInput {...props} />}
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            <Label htmlFor="tags">{"Tags"}</Label>
                            <Field name="tags" placeholder="Enter Tags">
                              {(props) => <FormInput {...props} />}
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            <Label htmlFor="description">{"Description"}</Label>
                            <Field
                              name="description"
                              placeholder="Enter Description"
                            >
                              {(props) => <FormInput {...props} />}
                            </Field>
                          </Col>
                        </Row>
                      </CardBody>
                    </Wizard.Page>
                  </Wizard>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
};

MediaManagement.propTypes = {
  categories: PropTypes.arrayOf(),
};

MediaManagement.defaultProps = {
  categories: [],
};

const mapStateToProps = (state) => ({
  ...state.mediaManagement,
  data: mediaManagementSelectors.dataSelector(state),
  categories: mediaManagementSelectors.categoriesSelector(state),
  mediaData: mediaManagementSelectors.mediaDataSelector(state),
  imageData: mediaManagementSelectors.imageDataSelector(state),
  videoData: mediaManagementSelectors.videoDataSelector(state),
  audioData: mediaManagementSelectors.audioDataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators(
    {
      getCategoies,
      createMedia,
      uploadImage,
      uploadReels,
      submitForm,
      updateMediaType,
    },
    dispatch
  );

  return {
    actions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaManagement);
