import { BASE_URL } from './../../config';
export const REDUCER_KEY = 'mediaList';

export const DATA = 'data';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const MEDIA_LIST = 'mediaList';
export const MEDIA_LIST_API = id => `${BASE_URL}/media/getMediaByCreator/${id}`;
export const AUTHENTICATED_SESSION = 'authenticatedSession';

export const mediaTableConfig = 
  {
    columns: [
      {
        Header: 'Video',
        // accessor: 'album',
        meta: {
          video_column: true
        }
      },
      // {
      //   Header: 'Category',
      //   accessor: 'category',
      //   Filter: () => SelectColumnFilter,
      //   filter: "includes"
      // },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Artist',
        accessor: 'album_artist',
      },
      {
        Header: 'Album',
        accessor: 'album',
      },
      {
        Header: 'Composer',
        accessor: 'composer',
      },
      {
        Header: 'Publisher',
        accessor: 'publisher',
      },
      {
        Header: 'Genre',
        accessor: 'genre',
      },
      {
        Header: 'Language',
        accessor: 'language_id',
      },
      // {
      //   Header: 'Action',
      // },
    ],
    options: {
      displayFilter: true,
      displaySearch: true
    }
  };

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [MEDIA_LIST]: [],
  [AUTHENTICATED_SESSION]: true,
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
