import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import {UN_AUTHERIZED_CODE} from '../../config';
import { GetUserToken } from '../../utils';

export { REDUCER_KEY } from './constants';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

export const getProfileData = () => (dispatch) => {
  // // const updatedData = beforeSubmit(data, currentState.mediaManagement);
  // // http post request
  // dispatch(dataChanged({
  //   data: {
  //     firstname: 'Mani',
  //     lastname: 'Ram',
  //     email: 'rammani6020@gmail.com',
  //     phoneNumber: 8867627752,
  //     businessName: 'Reelive',
  //     logo: ''
  //   } // dummy data
  // }));
  // const getApi = constants.MEDIA_LIST_API;
  const options = {
    headers: {
      'authorization': GetUserToken(),
    },
  };

  axios.get([constants.PROFILE_GET_API],options)
  .then(function (response) {
    const data = response.data;
    if (data.errorMessage) {
      dispatch(
        dataChanged({
          [constants.DATA_ERROR]: data.errorMessage,
          loading: false
        })
      );
    } else {
      dispatch(
        dataChanged({
          [constants.DATA]: data.responseData[0],
          loading: false
        })
      );
    }
    
    dispatch(boundSuccess({data: response, loading: false}));
  })
  .catch(function (error) {
     const { status } = error && error.response || 0;
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      window.location.href = '/';
    }
    dispatch(dataChanged({loading: false}));
    // dispatch(boundError({data: error, loading: false}));
  });
};


export const updateProfileData = (formdata) => (dispatch) => {
  dispatch(dataChanged({loading: true}));
  let data = new FormData();

  data.append('logo', formdata.logo);
  data.append('businessName', formdata.businessName);
  data.append('firstname', formdata.firstname);
  data.append('lastname', formdata.lastname);

  const options = {
    headers: {
      'authorization': GetUserToken(),
    },
  };

  axios.post([constants.PROFILE_UPDATE_API],data,options)
  .then(function (response) {
    const data = response.data;
    if (data.errorMessage) {
      dispatch(
        dataChanged({
          [constants.DATA_ERROR]: data.errorMessage,
          loading: false
        })
      );
    } else {
      dispatch(
        dataChanged({
          [constants.DATA]: data.responseData[0],
          [constants.EDIT_FORM]: false,
          loading: false
        })
      );
    }
    dispatch(boundSuccess({data: response, loading: false}));
  })
  .catch(function (error) {
     const { status } = error && error.response || 0;
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      dispatch(dataChanged({
        loading: false,
        authenticatedSession: false,
        [constants.EDIT_FORM]: false
      }));
    } else {
      dispatch(dataChanged({
        loading: false,
        [constants.EDIT_FORM]: false,
      }));
    }
    
    // dispatch(boundError({data: error, loading: false}));
  });
};


export const editFormData = () => (dispatch, getState) => {
  dispatch(dataChanged({
    [constants.EDIT_FORM]: true,
  }));
};

export const updateLogo = (file) => (dispatch, getState) => {
  dispatch(dataChanged({
    [constants.LOGO_FILE]: file,
  }));
};

