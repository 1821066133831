import axios from "axios";
import React, { createContext, useState } from "react";
import { login } from "../ducks/login";
import { BASE_URL } from './../config';

const AuthContext = createContext();
const LOGIN_URL = `${BASE_URL}/user/login`;
const LOGOUT_URL = `${BASE_URL}/user/logout`;


export const Auth = {
  // isAuthenticated: false,
  signin(data, callback) {

    // const dummy_data = {
    //   status: 200,
    //   responseData:[
    //     {
    //       userid: 'test-user'
    //     }
    //   ]
    // }
    // setTimeout(()=>{
    //   callback(dummy_data);
    // }, 200)
    // Auth.isAuthenticated = true;
    const {
      inputUsername,
      inputPassword
    } = data;
    
    axios.post(LOGIN_URL, {
      // options
      username: inputUsername,
      password: inputPassword,
      usertype:"admin"
    },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
      },
    })
    .then(function (response) {
      // console.log('login data', response.data);
      const responseData = response.data;
      
      callback(responseData);
    })
    .catch(function (error) {
      console.log(error);
    });
  },

  signout(callback) {
    // Auth.isAuthenticated = false;
    // setTimeout(callback, 100);
    const userData = localStorage.getItem('userData') != 'undefined' && JSON.parse(localStorage.getItem('userData')) || null;
    axios.post(LOGOUT_URL, {
      // options
      username: userData && userData.email,
    },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': userData && userData.token
      },
    })
    .then(function (response) {
      const data = response.data;
      callback(data);
    })
    .catch(function (error) {
      console.log(error);
      callback();
    });
    
  },
};

export const AuthProvider = ({children}) => {
  let [user, setUser] = useState(null);
  const userdata  = localStorage.getItem('userData');
  if (userdata && !user) {
    // use local storage
    setUser(JSON.parse(userdata).userid);
  }
  let signin = (data, callback) => {
    return Auth.signin(data, (response) => {

      if (response.status = 200) {
        // set local storage
        if (response.responseData) {
          localStorage.setItem('userData', JSON.stringify(response.responseData[0]));
          setUser(response.responseData[0].userid);
          callback(response.responseData);
        } else {
          callback(response);
        }
      }

    });
  };
  

  let signout = (callback) => {
    return Auth.signout((response) => {
      setUser(null);
      // remove local storage
      localStorage.removeItem('userData');
      callback(response);
    });
  };

  let setUserDataToNull = () => {
    setUser(null);
  }



  let value = { user, signin, signout, setUserDataToNull };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext;