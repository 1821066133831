import { BASE_URL } from './../../config';
export const REDUCER_KEY = 'dashboard';

export const DATA = 'data';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const DASHBOARD_API = `${BASE_URL}/admin/dashboard`;
export const AUTHENTICATED_SESSION = 'authenticatedSession';

export const tableConfig = 
  {
    columns: [
      {
        Header: 'Cover Photo',
        // accessor: 'album',
        meta: {
          video_column: true,
          best_seller: true
        }
      },
      // {
      //   Header: 'Category',
      //   accessor: 'category',
      //   Filter: () => SelectColumnFilter,
      //   filter: "includes"
      // },
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Artist',
        accessor: 'album_artist',
      },
      // {
      //   Header: 'Album',
      //   accessor: 'album',
      // },
      {
        Header: 'Composer',
        accessor: 'composer',
      },
      // {
      //   Header: 'Publisher',
      //   accessor: 'publisher',
      // },
      {
        Header: 'Genre',
        accessor: 'genre',
      },
      {
        Header: 'Language',
        accessor: 'language_id',
      },
      // {
      //   Header: 'Action',
      // },
    ],
    options: {
      maxHeight: 1350
    }
  };

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [AUTHENTICATED_SESSION]: true
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
