import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Form, Field } from "react-final-form";
import AuthContext from '../../pages/authProvider';

import { Form as BootstrapForm, FormControl, Button, Alert } from 'react-bootstrap';
import * as loginSelectors from '../../ducks/login/selectors';
import {
  showError
} from '../../ducks/login/index';
import LOGIN_BG from '../../images/admin-bg.jpeg';
import Input from '../../components/input';
import './index.scss';

export const Login = (props) => {
  const navigate = useNavigate();
  useEffect(()=> {
    const userdata  = localStorage.getItem('userData');
    if (userdata) {
      navigate('dashboard' , { replace: true });
    }
  }, []);
  const location = useLocation();
  const auth = useContext(AuthContext);
  
  const handleSubmit = (formData) => {
    const {
      actions,
    } = props;
    const {
      inputUsername,
      inputPassword
    } = formData;
    
    // actions.login(inputUsername, inputPassword);
    // console.log('auth', auth);

    // if (typeof data[0] === 'object' && Object.keys(data[0]).length > 0 && data[0].token) {
      auth.signin(formData, (data)=> {
        if (data.errorMessage) {
          // show some banner for error message
          actions.showError(data.errorMessage);
        } else {
          if(window.location.pathname != '/') {
          navigate(window.location.pathname.substring(1) , { replace: true });
          } else {
          navigate('dashboard' , { replace: true });

          }
        }
      });
      // navigate('dashboard' , { replace: true });
    // }
  }

  const handleRegClick = (e) => {
    navigate('registration' , { replace: true });
  }

    const {
      data,
      authenticatedSession,
    } = props;

    return (
      <>
          <main className="authentication-content">
            <div className="container-fluid">
              <div className="authentication-card">
                <div className="card shadow rounded-0 overflow-hidden">
                  <div className="row g-0">
                    <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                      <img src={LOGIN_BG} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6">
                      
                      <div className="card-body p-4 p-sm-5">
                        <h5 className="card-title">Sign In</h5>
                        {(typeof data == 'string') && <Alert color="danger" className='alert-danger'>{data}</Alert>}
                        <p className="card-text mb-5"></p>
                        <Form
                          onSubmit={handleSubmit}
                          initialValues={{ stooge: 'larry'}} // if needed we can pass it
                          render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <BootstrapForm onSubmit={handleSubmit}>
                              <div className="row g-3">
                              <div className="col-12">
                                <label htmlFor="inputUsername" className="form-label">Username</label>
                                <div className="ms-auto position-relative">
                                  <div className="position-absolute top-50 translate-middle-y search-icon px-3"><i className="bi bi-envelope-fill"></i></div>
                                  <Field name="inputUsername" placeholder="Enter Username">
                                    {props => (
                                      <FormControl
                                        name={props.input.name}
                                        placeholder={props.placeholder}
                                        onChange={props.input.onChange}
                                      />
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="col-12">
                                <label htmlFor="inputPassword" className="form-label">Password</label>
                                <div className="ms-auto position-relative">
                                  <div className="position-absolute top-50 translate-middle-y search-icon px-3"><i className="bi bi-lock-fill"></i></div>
                                  {/* <input type="password" className="form-control radius-30 ps-5" id="inputChoosePassword" placeholder="Enter Password" /> */}
                                  <Field name="inputPassword" placeholder="Enter Password">
                                    {props => (
                                      <FormControl
                                        type="password"
                                        name={props.input.name}
                                        placeholder={props.placeholder}
                                        onChange={props.input.onChange}
                                      />
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-check form-switch">
                                  <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onClick={() => {}} />
                                  <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Remember Me</label>
                                </div>
                              </div>
                              <div className="col-6 text-end">	<Link to="/forgot-password">Forgot Password ?</Link>
                              </div>
                              <div className="col-12">
                                <div className="d-grid">
                                  <Button type="submit" variant="primary">Sign In</Button>
                                </div>
                              </div>
                              {/* <div className="col-12">
                                <p className="mb-0">Don't have an account yet? <a className="link" onClick={handleRegClick}>Sign up here</a></p>
                              </div> */}
                            </div>
                            </BootstrapForm>
                        )}/>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </main>
      </>
    )
}

Login.propTypes = {
  username: PropTypes.string,
  authenticatedSession: PropTypes.bool,
}
Login.defaultProps = {
  username: '',
  authenticatedSession: false,
}

const mapStateToProps = state => ({
  ...state.login,
  data: loginSelectors.dataSelector(state),
  authenticatedSession: loginSelectors.authenticatedSessionSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    showError,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
