import {
  ConvertTimeStringtoDateFormat,
} from '../../utils';
export const REDUCER_KEY = 'auctions';
import { BASE_URL } from './../../config';
export const DATA = 'data';
export const IMAGE_DATA = 'imageData';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const IMAGE_UPLOAD_API = `${BASE_URL}/media/upload/image`;

export const AUCTIONS_API = `${BASE_URL}/auction`;
export const BIDDING_API = `${BASE_URL}/bidding`;
export const IMAGE_KEY = 'imageKey';

export const CATEGORY_ITEM_ID = 'categoryItemId';
export const MEDIA_ID = 'mediaId';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const FORM_DATA = 'formData';
export const AUCTIONS = 'auctions';
export const BIDDING_DATA = 'biddingData';
export const IMAGE_UPLOADING = 'imageUploading';
export const VIDEO_UPLOADING = 'videoUploading';


export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [CATEGORY_ITEM_ID]: '',
  [MEDIA_ID]: '',
  [AUTHENTICATED_SESSION]: true,
  [FORM_DATA]: {},
  [AUCTIONS]: [],
  [BIDDING_DATA]: [],
  [IMAGE_UPLOADING]: false,
  [VIDEO_UPLOADING]: false,
};

export const auctionsTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header: 'Name',
      accessor: (row, i) => row.name || '---',
      id: 'name',
    },
    {
      Header: 'Status',
      accessor: (row, i) => row.status || '---',
      id: 'status',
    },
    {
      Header: 'Min Bid Amount',
      accessor: (row, i) => <>&#8377;{row.minBidAmount}</> || '---',
      id: 'minBidAmount',
    },
    {
      Header: 'Max Bid Amount',
      accessor: (row, i) => <>&#8377;{row.maxBidAmount}</> || '---',
      id: 'maxBidAmount'
    },
    {
      Header: 'Start Date',
      accessor: (row, i) => ConvertTimeStringtoDateFormat(row.startDate) || '---',
      id: 'startDate'
    },
    {
      Header: 'End Date',
      accessor: (row, i) => ConvertTimeStringtoDateFormat(row.endDate) || '---',
      id: 'endDate'
    },
    {
      Header: 'Total Bids',
      accessor: (row, i) => row.totalBids || '---',
      id: 'totalBids'
    },
    {
      Header: 'Action',
    },
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};

export const biddingTableConfig = 
  {
    columns: [
      {
        Header: 'First Name',
        accessor: (row) => row.user && row.user.firstName ? row.user.firstName : '---',
        id: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: (row) => row.user && row.user.lastName ? row.user.lastName : '---',
        id: 'lastName',
      },
      {
        Header: 'Bidding Amount',
        accessor: (row) => <>&#8377;{row.bidAmount}</>,
        id: "bidAmount"
      },
      {
        Header: 'Bidding Count',
        accessor: row => row.bidding_count,
        id: 'bidding_count'
      },
      {
        Header: 'Created',
        accessor: row => (row.user && row.user.createdAt) ? new Date(row.user.createdAt).toDateString() : '',
        id: 'user.created'
      },
      {
        Header: 'Active',
        accessor: (row) => row.user && row.user.active ? 'Active' : 'Unactive',
        id: 'user.active'
      },
    ],
    options: {
      displayFilter: false,
      displaySearch: true
    }
  };


export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
