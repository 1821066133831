export const REDUCER_KEY = 'reelsList';
import { BASE_URL } from './../../config';
export const DATA = 'data';
export const IMAGE_DATA = 'imageData';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const PRODUCTS_API = `${BASE_URL}/product/all?page=1&limit=500`;
export const REELS_API = `${BASE_URL}/post/all`;
export const REELS_STATUS_UPDATE_API = id => `${BASE_URL}/post/status/${id}`;
export const PRODUCT_STOCK_UPDATE_API = id => `${BASE_URL}/product/updateStockStatus/${id}`;
export const PRODUCTS_MAP_API = id => `${BASE_URL}/post/product/map/${id}`;
export const REELS_DELETE_API =`${BASE_URL}/post`;
export const DELETE_REEL_MAPPING_API = `${BASE_URL}/post/product/map/`;


//Mapping Reels to List
export const MAPPED_REELS = 'mappedReels';
export const FUNCTION_REELS_MAP_API = id => `${BASE_URL}/media/map/reels/${id}`;
export const FUNCTION_GET_REELS_BY_MEDIA_API = id =>`${BASE_URL}/media/get/${id}`;


export const CATEGORY_ITEM_ID = 'categoryItemId';
export const MEDIA_ID = 'mediaId';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const FORM_DATA = 'formData';
export const REELS = 'reels';
export const ACTIVE = 'active';
export const REEL = 'testReels';
export const SHOW_DELETE_FORM = 'showDeleteForm';
export const SELECTED_ROW_ID = 'selectedRowId';
export const PRODUCTS_LIST = 'productList';
export const REEL_SEARCH = 'reelSearch';
const COMMON_SEARCH_PATTERN ={
  isSearch:false,
  searchResult:[],
  searchText:''
}

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [CATEGORY_ITEM_ID]: '',
  [MEDIA_ID]: '',
  [AUTHENTICATED_SESSION]: true,
  [FORM_DATA]: {},
  [REELS]: [],
  [ACTIVE]: true,
  [SHOW_DELETE_FORM]: false,
  [SELECTED_ROW_ID]: '',
  [PRODUCTS_LIST]:[],
  [MAPPED_REELS]:[],
  [REEL_SEARCH]: COMMON_SEARCH_PATTERN,
};

export const ordersTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header: 'Title',
      accessor: (row, i) => row.title || '---',
      id: 'title',
    },
    {
      Header: 'Created',
      accessor: (row, i) => {
        const d= new Date(row.created_at).toString();
        if(d) {
          return d.substring(0, 21);
        } else {
          return '---'
        }
      },
      id: 'brand',
    },
    {
      Header: 'Tags',
      accessor: (row, i) => row.tags && row.tags.join(', ') || '---',
      id: 'tags',
    },
    {
      Header: 'Description',
      accessor: (row, i) => row.description || '---',
      id: 'description'
    },

    {
      Header: 'Status',
      type: 'static',
      accessor: (row) => row.active || false,
      id: 'active'
    },
    {
      Header: 'Action',
    },
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};

//used in reel-map component (song-reel mapping)
export const reelListTableForMapping = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header: 'Title',
      accessor: (row, i) => row.title || '---',
      id: 'title',
    },
    {
      Header: 'Created',
      accessor: (row, i) => {
        const d= new Date(row.created_at).toString();
        if(d) {
          return d.substring(0, 21);
        } else {
          return '---'
        }
      },
      id: 'brand',
    },
    {
      Header: 'Tags',
      accessor: (row, i) => row.tags && row.tags.join(', ') || '---',
      id: 'tags',
    },
    {
      Header: 'Description',
      accessor: (row, i) => row.description || '---',
      id: 'description'
    },

    {
      Header: 'Status',
      type: 'static',
      accessor: (row) => row.active || false,
      id: 'active'
    },
    {
      Header: 'Action',
    },
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};





export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
