import React from 'react';
import { Link } from 'react-router-dom';
import {
  House,
  FileEarmarkMusic,
  PersonSquare,
  BasketFill,
  CollectionPlayFill,
  GeoAltFill,
  MusicPlayerFill,
  GraphUp,
  GridFill,
  PaletteFill,
  Box2Fill,
  Hammer,
} from 'react-bootstrap-icons';

const menuClick = (event) => {
  // event.preventDefault();
  const targetClass = event.currentTarget.className;
  const subnav = event.currentTarget.parentElement.querySelector('.subnav-wrapper');
  if (targetClass.indexOf('toggle-menu') > -1) {
    if (subnav.className.indexOf('active') > -1) {
      subnav.className = 'subnav-wrapper';
    } else {
      subnav.className = 'active subnav-wrapper';
    }
  }
}

const MenuList = (menuList) => {
  const pathname = window.location.pathname;
  return(
    <ul className="metismenu mm-show" id="menu">
      {menuList.menuList.map((menu)=> {
        const {
          icon,
          title,
          linkTo,
          subNav,
        } = menu;
        let active = '';
        if(pathname === linkTo) {
          active = 'active';
        } else if(linkTo === '#' && subNav.length > 0) {
          subNav.forEach(element => {
           if(pathname === element.linkTo) {
            active = 'active';
           }
          });
        }
        return (
        <li key={title}>
          <Link to={linkTo} aria-expanded="true" data-active={active} className={subNav ? 'toggle-menu side-main-nav' : 'side-main-nav'} onClick={menuClick}>
            {icon === 'House' && <House />}
            {icon === 'FileEarmarkMusic' && <FileEarmarkMusic />}
            {icon === 'PersonSquare' && <PersonSquare />}
            {icon === 'BasketFill' && <BasketFill />}
            {icon === 'CollectionPlayFill' && <CollectionPlayFill />}
            {icon === 'GeoAltFill' && <GeoAltFill />}
            {icon === 'MusicPlayerFill' && <MusicPlayerFill />}
            {icon === 'GraphUp' && <GraphUp />}
            {icon === 'GridFill' && <GridFill />}
            {icon === 'PaletteFill' && <PaletteFill />}
            {icon === 'Box2Fill' && <Box2Fill />}
            {icon === 'Hammer' && <Hammer />}
            <div className="menu-title">{title}</div>
          </Link>
          {subNav
          ?
          <ul className={`subnav-wrapper ${active}`}>
            {
              subNav.map(menu=> {
                const {
                  title,
                  linkTo,
                } = menu;
                let subMenuActive = '';
                if(pathname === linkTo) {
                  subMenuActive = 'active';
                }
                return (
                <li key={title}>
                  <Link to={linkTo} data-active={subMenuActive} aria-expanded="true">
                    - <div className="menu-title">{title}</div>
                  </Link>
                  </li>
              )})
            }
          </ul>
          :
          ''}
        </li>
        )
      })}
    </ul>
  );
}

export default MenuList;
