import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as auctionsSelectors from '../../ducks/auctions/selectors';
import {
  ViewProduct,
  DeleteForm,
  AddForm,
} from './components';
import {
  getAuctions,
  deleteAuction,
  viewFormData,
  deleteAuctionForm,
  AddAuctionForm,
  getBiddingByAuctionId,
  submitAuction,
  uploadPicture,
} from '../../ducks/auctions/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';

import { auctionsTableConfig } from '../../ducks/auctions/constants';
import CustomTable from '../../components/table';

const Auctions = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  // Toggle for Modal
  const toggle = (data) => {
    const {
      actions
    } = props;
    actions.viewFormData(data);

    setModal(!modal)
  }

  const toggleDeleteForm = (data) => {
    const {
      actions
    } = props;
    actions.deleteAuctionForm(data);
    setDeleteModal(!deleteModal)
  }

  const toggleAddForm = (data) => {
    const {
      actions
    } = props;
    actions.AddAuctionForm(data);
    setAddModal(!addModal)
  }

  const submitData = () => setModal(!modal);
  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getAuctions();
  },[]);

  const {
    authenticatedSession,
    data,
    auctions,
    actions,
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content auctions">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                {
                  modal ? 
                <ViewProduct getBiddingByAuctionId={actions.getBiddingByAuctionId} toggle={toggle} {...props} />
                :
                <CardBody>
                  <CardHeader>
                    <h4 className="category-header-label">Auctions</h4>
                    <div className="add-button">
                      <Button color="primary" onClick={toggleAddForm}>Add Auction</Button>
                    </div>
                    <div>
                      <Modal isOpen={deleteModal} className="delete-form">
                        <ModalBody>
                          <DeleteForm {...props} />
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={addModal} className="add-auction-form">
                        <ModalBody>
                          <AddForm onCancelClick={toggleAddForm} handleSubmit={actions.submitAuction} {...props} />
                        </ModalBody>
                      </Modal>
                    </div>
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="auctions">
                      {/* orderTableConfig */}
                      <CustomTable
                      data={auctions}
                      onViewClick={toggle}
                      onDeleteClick={toggleDeleteForm}
                      options = {auctionsTableConfig.options}
                      columns={auctionsTableConfig.columns} />
                    </div>
                  </div>
                </CardBody>
              }
              </Card>
            </Col>
          </Row>
        </Container>

      </main>
    </>
  )
}

Auctions.propTypes = {
  auctions: PropTypes.arrayOf(PropTypes.shape()),
};

Auctions.defaultProps = {
  auctions: [],
};

const mapStateToProps = state => ({
  ...state.auctions,
  data: auctionsSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getAuctions,
    deleteAuction,
    viewFormData,
    deleteAuctionForm,
    getBiddingByAuctionId,
    AddAuctionForm,
    submitAuction,
    uploadPicture,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Auctions);
