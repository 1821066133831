import React from 'react';
import './index.scss';
import { Spinner } from 'reactstrap';
function Loader(props) {
  const {
    loading
  } = props;
  if(loading) {
    return (
      <div className="loader">
        <Spinner color="light"></Spinner>
      </div>
    )
  }
  return ''
};

export default Loader;