import React from 'react';
import { Row, Col, Button} from 'reactstrap';
import IMAGE_BG from '../../../images/iphone_bg.png';
import { GetImageUrl } from '../../../utils';
import media from '..';

const ViewMapReels = (props) => {


  // const updatedProductsArray = products && products.map((el) => el.productId) || [];
  const{ selectedMedia } = props;
  
  const handleProductUnMapping = (reel) => {
    // event.preventDefault();
    const {
      actions,
    } = props;
    console.log(reel);
    // const productId = parseInt(event.target.dataset.id);
    // updatedProductsArray.splice(updatedProductsArray.indexOf(productId), 1);

    actions.unmapReelFromMedia(reel.id, selectedMedia.mediaId);
  };
  
  // const urlArray = path && path.split('.');
  console.log("selectedMedia selectedMedia", selectedMedia)
  // const fileExt = urlArray && urlArray.length && urlArray[urlArray.length-1].toLowerCase();
  

  const list = selectedMedia.reels
  .map((el,index) => {
    const {
      title,
      description,
      tileImageUrl,
      active,

    } = el;
    return (
      <>
        <li key={index} className="thumb-product-list">
        <Row>
          <Col md="4" className="thumb-product-image">
            <img width={100} style={{height:"auto", maxHeight:"none"}} src={GetImageUrl(tileImageUrl)} />
            <div>

            <Button color="danger"  onClick={()=>handleProductUnMapping(el)}>Unmap</Button>
            </div>
          </Col>
          <Col md="8" className="thumb-product-desc">
            <h4 className="thumb-product-name">{title}</h4>
            <p>{description}</p>
            <div className="thumb-product-desc-item">Active: <span className={`${active ? 'success' : 'failure'}`}>{active ? 'Active' : 'Inactive'}</span></div>
            
          </Col>
        </Row>
        
        </li>
      </>
    )
  });
  
  return(
    <div className="map-product-detail">
      <h4 className="product-name">{media?.title}</h4>
      <div className="iphone-cover">
      </div>
      <Row>
        <Col md="7">
          <ul>
            {
              (list.length > 0)
              ?
              <>
                {list}
              </>
              :
              <div className="no-product-mapped">
                No Reels have been mapped to this Song.
              </div>
            }
          </ul>
        </Col>
      </Row>
    </div>
    // <h2>Helloo</h2>
  )
}

export default ViewMapReels;
