import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
export { REDUCER_KEY } from './constants';
import { GetUserToken } from '../../utils';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);


// const getSubgategories = (categoriesArray) => {
//   let subCategories = [];
//   let innerSubCategories = [];
//   subCategories = categoriesArray.reduce(
//   (previousValue, currentValue) => {
//     //add parent categories to each subcategoryItem
//     let updatedCurrentValue = [];
//       currentValue.subCategory.forEach((element, index) => {
//       element.parentCategory = currentValue.name;
//       updatedCurrentValue.push(element);
//     });
//     return [...previousValue , ...updatedCurrentValue]
//   },[]);
//   return subCategories;
// }

// const getInnerSubgategories = (subCategoriesArray, categoriesArray) => {
//   if (subCategoriesArray.length > 0) {
//     let innerSubCategories = [];
//     innerSubCategories = subCategoriesArray.reduce(
//     (previousValue, currentValue) => {
//       //add parent categories to each subcategoryItem
//       let updatedCurrentValue = [];
//         currentValue.subCategory.forEach((element, index) => {
//         element.parentCategory = currentValue.name;
//         updatedCurrentValue.push(element);
//       });
//       return [...previousValue , ...updatedCurrentValue]
//     },[]);
//     return innerSubCategories;
//   } else {
//     const subCategories = getSubgategories(categoriesArray);
//     let innerSubCategories = [];
//     innerSubCategories = subCategories.reduce(
//     (previousValue, currentValue) => {
//       //add parent categories to each subcategoryItem
//       let updatedCurrentValue = [];
//         currentValue.subCategory.forEach((element, index) => {
//         element.parentCategory = currentValue.name;
//         updatedCurrentValue.push(element);
//       });
//       return [...previousValue , ...updatedCurrentValue]
//     },[]);
//     return innerSubCategories;
//   }
  
// };

export const getOrders = (type) => (dispatch, getState) => {
  const { mediaCategory } = getState();
  const { subcategories } = mediaCategory;
  dispatch(dataChanged({loading: true}));
  axios.post([constants.ORDERS_API],
    {
      page: 1,
      limit: 1000
    },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const ordersData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        // update category array
        let updatedOrderDetails = [];
        ordersData[0].orders.forEach(element => {
          const address = element.address;
          const orderId = element.orderId;
          if(element.orderDetails && element.orderDetails.length > 0) {
            element.orderDetails.forEach(element => {
              element.address = address;
              element.orderId = orderId;
              updatedOrderDetails.push(element);
            })
          }
        });
        dispatch(dataChanged({
          [constants.ORDERS]: updatedOrderDetails || [],
          loading: false
        }));
      }
      
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};


export const initFormValues = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MEDIA_DATA]: {},
      [constants.MEDIA_ID]: '',
    })
  )
}

export const viewFormData = (data) => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
    })
  )
};


export const addUpdateTracking = (formdata, orderDetails, orderId) => (dispatch, getState) => {
  const {
    trackingType,
    trackingstate,
  } = formdata;

  let data = {
    orderDetails: orderDetails.id,
    trackingstate:[
      {
        notes: trackingstate,
      }
    ],
    trackingType,
    orderId,
  };
  let url = [constants.TRACKING_UPDATE_API];
  dispatch(dataChanged({loading: true}));
  axios.post(url,data,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      const responseData = data.responseData[0];

      dispatch(dataChanged({
        [constants.TRACKING_DETAILS]: responseData,
        loading: false,
      }));
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
    });
};


export const generateInvoice = (data) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.GENERATE_INVOICE_API;
  const url = api(data.orderId);

  axios.post(url,null,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      }
       else {
        dispatch(dataChanged({
          // [constants.ACTIVE]: activeData,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};

export const acceptOrder = (data) => (dispatch) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.ACCEPT_ORDER_API;

  axios.post(api,{
    orderId: data.orderId
  },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      if (data.errorMessage) {
        window.alert(data.errorMessage);
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      }
       else {
        dispatch(dataChanged({
          // [constants.ACTIVE]: activeData,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};


export const rejectOrder = (data) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.REJECT_ORDER_API;

  axios.post(api,{
    orderId: data.orderId
  },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      if (data.errorMessage) {
        window.alert(data.errorMessage);
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      }
       else {
        dispatch(dataChanged({
          // [constants.ACTIVE]: activeData,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};

export const downloadInvoice = (data, callback) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.DOWNLOAD_INVOICE_API;
  const url = api(data.orderId);

  axios.get(url,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const downloadData = data.responseData[0];
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      }
       else {
        dispatch(dataChanged({
          // [constants.ACTIVE]: activeData,
          loading: false
        }));
        callback(downloadData);
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};
