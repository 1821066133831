import { BASE_URL } from './../../config';
export const REDUCER_KEY = 'registration';

export const DATA = 'data';
export const VERIFY_DATA = 'verifyData';
export const USERNAME = 'username';
export const PASSWORD = 'password';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const LOGIN_API = 'login/user=123';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const AUTH_TOKEN = 'authToken';
export const CREATE_USER_API = `${BASE_URL}/user/createUser`;
export const VERIFY_USER_API = `${BASE_URL}/user/verify/otp`;
export const USER_TYPE = 'content_creator'; // usertype
export const LOGIN = 'login';

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [USERNAME]: '',
  [PASSWORD]: '',
  [DATA]: {},
  [VERIFY_DATA]: {},
  [AUTHENTICATED_SESSION]: false,
  [AUTH_TOKEN]: '',
  [LOGIN]: false,
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
