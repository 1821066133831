import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import {Container,Row,Col,Card,CardBody,Table,CardFooter,
  Pagination,PaginationItem,PaginationLink,
  FormGroup,Label,Input,
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';

import { mediaTableConfig } from '../../ducks/media-list/constants';
import CustomTable from '../../components/table';
// import TableList from './tableList';
import MediaEditForm from './mediaEditForm';
import MediaDeleteForm from './mediaDeleteForm';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import * as mediaListSelectors from '../../ducks/media-list/selectors';
import {
  getMediaList
} from '../../ducks/media-list/index';
import './index.scss';
const MediaList = (props) => {
  const auth = useContext(AuthContext);
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getMediaList();
  }, []);
  const {
    data,
    authenticatedSession,
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content">
        <div className="profile-cover bg-dark"></div>
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h3 className="media-list-header">
                    {"Media List"}
                  </h3>
                  <div className="best-seller-table">
                    
                    <div className="item  mb-3">
                      <div className="product-list">
                        {/* mediaTableConfig */}
                        <CustomTable
                        data={data}
                        options = {mediaTableConfig.options}
                        columns={mediaTableConfig.columns} />
                      </div>
                    </div>
                  </div>
                  
                  <CardFooter>
                    <Pagination aria-label="..." className="nav-pagination">
                      <ul className="pagination pagination-primary">
                        <PaginationItem disabled><PaginationLink>{"Previous"}</PaginationLink></PaginationItem>
                        <PaginationItem active><PaginationLink href="#javascript" >{"1"}</PaginationLink></PaginationItem>
                        <PaginationItem><PaginationLink href="#javascript">{"2"} <span className="sr-only">{"(current)"}</span></PaginationLink></PaginationItem>
                        <PaginationItem><PaginationLink href="#javascript">{"3"}</PaginationLink></PaginationItem>
                        <PaginationItem><PaginationLink href="#javascript">{"Next"}</PaginationLink></PaginationItem>
                      </ul>
                    </Pagination>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </main>
    </>
  )
}

MediaList.propTypes = {
  username: PropTypes.string,
};

MediaList.defaultProps = {
  username: '',
};

const mapStateToProps = state => ({
  ...state.mediaList,
  data: mediaListSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getMediaList,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaList);
