const GetStatusLocalizeText = (key) => {
  let localStatusName = key;
    if(key === 'READY_FOR_SHIPPMENT') {
      localStatusName = 'Ready for shipment';
    } else if(key === 'DELIVERED') {
      localStatusName = 'Delivered';
    } else if(key === 'CANCELLED') {
      localStatusName = 'Cancelled';
    } else if(key === 'ORDERED') {
      localStatusName = 'Ordered';
    } else if(key === 'CREATED') {
      localStatusName = 'Created';
    } else if(key === 'RETURNED') {
      localStatusName = 'Returned';
    } else if(key === 'FAILED') {
      localStatusName = 'Failed';
    } else if(key === 'OUT_FOR_DELIVERY') {
      localStatusName = 'Out for Delivery';
    } else if(key === 'ACCEPTED') {
      localStatusName = 'Order Accepted';
    } else if(key === 'REJECTED') {
      localStatusName = 'Order Rejected';
    } else if(key === 'RETURN_REQUEST') {
      localStatusName = 'Return Requested';
    } else if(key === 'IN_TRANSIT') {
      localStatusName = 'In Transit';
    }
    return localStatusName;
};

export default GetStatusLocalizeText;