import React, { useEffect } from 'react';
import { Row, Col, CardBody,CardHeader} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

import { biddingTableConfig } from '../../../ducks/auctions/constants';
import CustomTable from '../../../components/table';
import { GetImageUrl } from '../../../utils';

const ViewProduct = (props) => {
  useEffect(() => {
    props.getBiddingByAuctionId(props.formData);
  }, []);
  const {
    created_at,
    description,
    endDate,
    startDate,
    totalBids,
    name,
    maxBidAmount,
    minBidAmount,
    images,
  } = props.formData;
  const {
    biddingData = [],
    toggle
  } = props;
  
  return(
    <div className="auction-details">
      <CardHeader>
      <h4 className="auction-details-header">
       <FontAwesomeIcon onClick={toggle} icon={faArrowLeftLong} ></FontAwesomeIcon> View Auction Details
      </h4>
      </CardHeader>
      <CardBody>
        <div className="product-detail">
          <Row>
            <Col md="5" className="product-image">
              <img width={300} src={images && GetImageUrl(images[0])} />
            </Col>
            <Col md="7">
              <h3 className="product-name">{name}</h3>
              {/* <p><span className="discounted-price">&#8377;{discountedPrice}</span> <span className="original-price">&#8377;{price}</span></p> */}
              <Row>
                <Col md="4">
                    Created
                </Col>
                <Col md="8">
                  : {created_at ? new Date(created_at).toDateString() : ''}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                    Total Bids
                </Col>
                <Col md="8">
                  : { totalBids}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                    Start Date
                </Col>
                <Col md="8">
                  : {startDate ? new Date(parseInt(startDate)).toDateString() : ''}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                    End Date
                </Col>
                <Col md="8">
                  : {endDate ? new Date(parseInt(endDate)).toDateString() : ''}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  Minimum Bid Amount
                </Col>
                <Col md="8">
                  : &#8377;{minBidAmount}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  Maximum Bid Amount
                </Col>
                <Col md="8">
                  : &#8377;{maxBidAmount}
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="desc">Description</h4>
                  <p>{description}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="bidding-wrapper">
          <h4>Bidding Details</h4>
          <CustomTable
            data={biddingData}
            // onViewClick={toggle}
            // onDeleteClick={toggleDeleteForm}
            options = {biddingTableConfig.options}
            columns={biddingTableConfig.columns} />
          {/* <ul>
            <li>
              <div className="bid-cell header">First Name</div>
              <div className="bid-cell header">Last Name</div>
              <div className="bid-cell header">Bid Amout</div>
              <div className="bid-cell header">Bidding Count</div>
              <div className="bid-cell header">Created</div>
              <div className="bid-cell header">Active</div>
            </li>
            {
              biddingList
            }
          </ul> */}
        </div>
      </CardBody>
    </div>
  )
}

export default ViewProduct;
