import React, { useState } from 'react';
import { Row, Col, CardBody,CardHeader, Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { BASE_URL } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faTimes, faDownload, faGears } from '@fortawesome/free-solid-svg-icons';

import { AddTrackingDetails } from './../components';

import { GetStatusLocalizeText, GetImageUrl } from './../../../utils';

const ViewOrderDetails = (props) => {
  const {
    formData,
    toggle,
    // addTrackFormData,
    addUpdateTracking,
    trackModal,
    trackFormToggle,
  } = props;

  const {
    address,
    orderDetails = [],
    orderId,
    totalOrderPrice,
    totalProducts,
    created_at,
    payment,

    freeShipping,
    product,
    status,
    quantity,
    // created_at: itemCreatedDate,
    deliveredDate,
    taxAmount,
    shipping,
    merchant,
    id,
    trackingDetails,
    productVariants,
    updated_at,
  } = formData;

  const displayAcceptRejectOrder = () => {
    let display = false;
    orderDetails.forEach(el => {
      if(el.status == 'ORDERED' || el.status == 'CREATED') {
        display = true;
      }
    });
    return display;
  }

  const {
    address_line1,
    address_line2,
    city,
    country,
    name,
    phonenumber,
    postal_code,
    state,
  } = address;
  

  // const list = formData.map(item => {
    const {
      productname = '',
      price,
      brand,
      productImages,
      discountedPrice,
      sku,
      shippingCost,
      shortDescription,
    } = product || {};

  const imageUrl = productImages && GetImageUrl(productImages[0]);

    const {
      created_at: shipping_created_date,
      trackingNumber,
      provider,
      shippingType,
      desc,
    } = shipping || {};

    const {
      store_name,
      phoneNumber,
      email,
    } = merchant || {};

    let deliveryInfo = {};
    let inTransitInfo = {};
    let outForDeliveryInfo = {};

    trackingDetails.forEach(element => {
      if(element.trackingType == 'DELIVERED') {
        deliveryInfo = element.trackingstate[0];
      }

      if(element.trackingType == 'OUT_FOR_DELIVERY') {
        outForDeliveryInfo = element.trackingstate[0];
      }

      if(element.trackingType == 'IN_TRANSIT') {
        inTransitInfo = element.trackingstate[0];
      }
    });
const list = 
          <>
        <li className="order-detail" key={id}>
        <div>
            <Modal isOpen={trackModal} className="tracking-details">
              <ModalHeader
                close={<FontAwesomeIcon onClick={trackFormToggle} icon={faTimes}></FontAwesomeIcon>}
              >
                Add Tracking Details
              </ModalHeader>
              <ModalBody>
                <AddTrackingDetails orderId={orderId} orderDetails1={formData} handleSubmit={addUpdateTracking} {...props} trackFormToggle={trackFormToggle} />
              </ModalBody>
            </Modal>
          </div>
        <Row>
          <Col md="4">
            <Row>
              <Col md="4">
                <div className="order-image-container">
                  <img className="product-thumbnail" src={imageUrl} />
                </div>
              </Col>
              <Col md="8">
                <h3 className="product-name">{productname}</h3>
                <div className="quantity">
                  {brand}
                </div>
                <div>
                  <span className="discounted-price">&#8377;{discountedPrice}</span>&nbsp;
                  <span className="original-price">&#8377;{price}</span>
                </div>
                <div>
                  <span>
                    {productVariants[0]}
                  </span>,&nbsp;
                  <span>
                  {productVariants[1]}
                  </span>
                </div>
                <div>
                  SKU: <b>{sku}</b>
                </div>
                <div className="quantity">
                  Quantity: {quantity}
                </div>
                <div>
                  Status: <span className="order-status">{GetStatusLocalizeText(status)}</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="3">
            <h5>Merchant Details</h5>
            <div>
              Name: {store_name}
            </div>
            <div>
              Phone: {phoneNumber}
            </div>
            <div>
              Email: {email}
            </div>
            {/* <div>
              Tracking Number: {trackingNumber}
            </div> */}
          </Col>
          <Col md="3">
            <h5>Shipment Details</h5>
            <div>
              Created: {new Date(shipping_created_date).toLocaleString()}
            </div>
            <div>
              Provider: {provider}
            </div>
            <div>
              Shipment Type: {shippingType}
            </div>
            <div>
              Tracking Number: {trackingNumber}
            </div>
          </Col>
          <Col md="2">
            <h5>Tracking Details</h5>
            <div className="tracking-update">
                <Button color="primary" disabled={(status === 'FAILED' || status === 'CANCELLED' || status === 'REJECTED' || status === 'CREATED' || status === 'ORDERED'|| status === 'RETURN_REQUEST') ? true : false}  onClick={trackFormToggle}>Add</Button>&nbsp;&nbsp;
                {/* <Button color="primary" onClick={trackFormToggle}>View</Button> */}
            </div>
          </Col>
        </Row>
        <br />
          <div className="stepper-wrapper">
            <div className={`${created_at ? '' : 'hidden'}`}>
              <span className="dot">&nbsp;</span>Created
            </div>
            {
              status == 'CANCELLED' || status == 'RETURNED' || status == 'FAILED' ?
              <div className={`${'danger'}`}>
                <span className="dot">&nbsp;</span>{`${GetStatusLocalizeText(status)}`}
              </div>
              :
              ''
            }
            <div className={`${shipping_created_date ? '' : 'hidden'}`}>
              <span className="dot">&nbsp;</span>Shipped
            </div>
            <div className={`${inTransitInfo.createdAt ? '' : 'hidden'}`}>
              <span className="dot">&nbsp;</span>In Transit
            </div>
            <div className={`${outForDeliveryInfo.createdAt ? '' : 'hidden'}`}>
              <span className="dot">&nbsp;</span>Out for Delivery
            </div>
            <div className={`${deliveryInfo.createdAt ? '' : 'hidden'}`}>
              <span className="dot">&nbsp;</span>Delivered
            </div>
          </div>
          <div className="stepper-bottom">
            <div className={`${created_at ? '' : 'hidden'}`}>
              <div>{new Date(created_at).toLocaleString()}</div>
              Your order has been successfully placed!
            </div>
            {
              status == 'CANCELLED' || status == 'RETURNED' || status == 'FAILED' ?
              <div className={`${'danger'}`}>
                <div>{new Date(updated_at).toLocaleString()}</div>
                Your order has been {`${GetStatusLocalizeText(status)}!`}
              </div>
              :
              ''
            }
            <div className={`${shipping_created_date ? '' : 'hidden'}`}>
              <div>{new Date(shipping_created_date).toLocaleString()}</div>
              Your Item has been shipped!
            </div>
            <div className={`${inTransitInfo.createdAt ? '' : 'hidden'}`}>
              <div>{new Date(inTransitInfo.createdAt).toLocaleString()}</div>
              {inTransitInfo.notes}
            </div>
            <div className={`${outForDeliveryInfo.createdAt ? '' : 'hidden'}`}>
              <div>{new Date(outForDeliveryInfo.createdAt).toLocaleString()}</div>
              {outForDeliveryInfo.notes}
            </div>
            <div className={`${deliveryInfo.createdAt ? '' : 'hidden'}`}>
              <div>{new Date(deliveryInfo.createdAt).toLocaleString()}</div>
              {deliveryInfo.notes}
            </div>
          </div>
      </li>
      </>
  // });

  const handleGenerateInvoice = () => {
    const {
      formData,
      generateInvoice,
    } = props;
    generateInvoice(formData);
  };

  const handleAcceptOrder = () => {
    const {
      formData,
      acceptOrder,
    } = props;
    acceptOrder(formData);
  };

  const handleRejectOrder = () => {
    const {
      formData,
      rejectOrder,
    } = props;
    rejectOrder(formData);
  };

  const handleDownloadInvoice = () => {
    const {
      formData,
      downloadInvoice,
    } = props;
    downloadInvoice(formData, (data) => {
      const updatedUrl = data.preSignedUrl;
      let dlink = document.createElement('a');
        dlink.download = 'invoice';
        dlink.target = '_blank';
        dlink.href = updatedUrl;
        dlink.click();
        dlink.remove();
    });
  };

  return (
    <>
      <CardHeader>
        <h4 className="order-details-header">
          <FontAwesomeIcon onClick={toggle} icon={faArrowLeftLong} ></FontAwesomeIcon>
          View Order Details
        </h4>
        </CardHeader>
        <CardBody className="pt-10 ">
        <div className="order-id-details">
          <div> 

          <span className="id">
            Order ID: {orderId}
          </span>
          <span className="date">
            Created Date: {new Date(created_at).toLocaleString()}
          </span>
          </div>
          {
            displayAcceptRejectOrder() ?
            <div>
              <Button color="secondary" onClick={handleRejectOrder}>
                Reject Order
              </Button>
              &nbsp;&nbsp;
              <Button className="green" onClick={handleAcceptOrder}>
                Accept Order
              </Button>
            </div>
            :
            ''
          }
        </div>
        <ul className="order-list">
          {list}
        </ul>
        <div className="customer-address">
          <div>
            <b>Customer Details:&nbsp;</b><br />
            <div className={`${name ? '' : 'hidden'}`}>{name},</div>
            <div className={`${address_line1 ? '' : 'hidden'}`}>{address_line1},</div>
            <div className={`${address_line2 ? '' : 'hidden'}`}>{address_line2},</div>
            <div className={`${city ? '' : 'hidden'}`}>{city},</div>
            <div className={`${state ? '' : 'hidden'}`}>{state},</div>
            <div className={`${country ? '' : 'hidden'}`}>{country}-{postal_code}.</div>
            <div className={`${phonenumber ? '' : 'hidden'}`}>Tel: {phonenumber}</div>
          </div>
          {
            payment ?
            <div>
            <b>Payment Details</b><br />
            <div>Amount: {payment.amount}</div>
            <div>Payment Type: {payment.paymentType}</div>
            <div>Status: {GetStatusLocalizeText(payment.status)}</div>
            <div>Order ID: {payment.providerOrderId}</div>
            <div>Payment ID: {payment.providerPaymentId}</div>
          </div>
            :
            ''
          }
          
          <div>
            <Button className="custom-btn" onClick={handleGenerateInvoice}>
              <FontAwesomeIcon onClick={() => undefined} icon={faGears}></FontAwesomeIcon>&nbsp;
              Generate Invoice
            </Button>
            &nbsp;&nbsp;
            <Button className="custom-btn" onClick={handleDownloadInvoice}>
              <FontAwesomeIcon onClick={() => undefined} icon={faDownload}></FontAwesomeIcon>&nbsp;
              Download Invoice
            </Button>
          </div>
        </div>
        </CardBody>
    </>
    
  )
}

export default ViewOrderDetails;
