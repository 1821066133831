
const getImageUrl = (str) => {
  const userData = localStorage.getItem('userData') != 'undefined' && JSON.parse(localStorage.getItem('userData')) || {};
  if (!str) return null;
  if (str.includes('http')) {
    return str;
  }
  return userData.cdnURL + str;
};

export default getImageUrl;