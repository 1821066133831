import React from 'react';
import { Row, Col,} from 'reactstrap';
import { GetImageUrl } from '../../../utils';

const ViewProduct = (props) => {
  const {
    brand,
    productImages,
    productname,
    price,
    returnDays,
    shippingCost,
    shortDescription,
    sku,
    tags,
    isReturnAvaialble,
    isProductVariationAvailable,
    inventory,
    freeShipping,
    estShippingDays,
    discountedPrice,
  } = props.formData;
  const imageUrl = productImages && GetImageUrl(productImages[0]);
  return(
    <div className="product-detail">
      <Row>
        <Col md="5" className="product-image">
          <img width={300} src={imageUrl} />
        </Col>
        <Col md="7">
          <h3 className="product-name">{productname}</h3>
          <p><span className="discounted-price">&#8377;{discountedPrice}</span> <span className="original-price">&#8377;{price}</span></p>
          <Row>
            <Col md="4">
                SKU
            </Col>
            <Col md="8">
              : {sku}
            </Col>
          </Row>
          <Row>
            <Col md="4">
                In Stock
            </Col>
            <Col md="8">
              : { inventory && inventory.quantity} Items Available
            </Col>
          </Row>
          {
            freeShipping && <>
            <Row>
              <Col md="4">
                  Free Shipping In
              </Col>
              <Col md="8">
                : {freeShipping}
              </Col>
          </Row>
            </>
          }
          <Row>
            <Col md="4">
                Estimated Shipping in days
            </Col>
            <Col md="8">
              : {estShippingDays}
            </Col>
          </Row>
          <Row>
            <Col md="4">
                Shipping Cost
            </Col>
            <Col md="8">
              : &#8377;{shippingCost}
            </Col>
          </Row>
          {
            isReturnAvaialble && <>
            <Row>
              <Col md="4">
                  Return In
              </Col>
              <Col md="8">
                : {returnDays} Days
              </Col>
            </Row>
            </>
          }
          <Row>
            <Col>
              <h4 className="desc">Description</h4>
              <p>{shortDescription}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default ViewProduct;
