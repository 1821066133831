export const REDUCER_KEY = 'products';
import { BASE_URL } from './../../config';
export const DATA = 'data';
export const IMAGE_DATA = 'imageData';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const PRODUCTS_API = `${BASE_URL}/product/all?page=1&limit=500`;
export const PRODUCT_STATUS_UPDATE_API = id => `${BASE_URL}/product/updateActiveStatus/${id}`;
export const PRODUCT_STOCK_UPDATE_API = id => `${BASE_URL}/product/updateStockStatus/${id}`;
export const PRODUCTS_MAP_API = id => `${BASE_URL}/post/product/map/${id}`;
export const GET_PRODUCT_BY_REEL_ID = `${BASE_URL}/post/products/`;

export const PRODUCTS_IMAGE_MAP_API = id => `${BASE_URL}/image/product/map/${id}`;
export const GET_PRODUCT_BY_IMAGE_ID = `${BASE_URL}/image/products/`;

export const CATEGORY_ITEM_ID = 'categoryItemId';
export const MEDIA_ID = 'mediaId';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const FORM_DATA = 'formData';
export const PRODUCTS = 'products';
export const MAPPED_PRODUCTS = 'mappedProducts';
export const ACTIVE = 'active';
export const PRODUCT_SEARCH = 'productSearch';
const COMMON_SEARCH_PATTERN ={
  isSearch:false,
  searchResult:[],
  searchText:''
}

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [CATEGORY_ITEM_ID]: '',
  [MEDIA_ID]: '',
  [AUTHENTICATED_SESSION]: true,
  [FORM_DATA]: {},
  [PRODUCTS]: [],
  [ACTIVE]: true,
  [MAPPED_PRODUCTS]: [],
  [PRODUCT_SEARCH]:COMMON_SEARCH_PATTERN,
};

export const ordersTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header: 'Product Name',
      accessor: (row, i) => row.productname || '---',
      id: 'productname',
    },
    {
      Header: 'Brand',
      accessor: (row, i) => row.brand || '---',
      id: 'brand',
    },
    {
      Header: 'Category',
      accessor: (row, i) => row.productCategoryItemId && row.productCategoryItemId.name || '---',
      id: 'productCategoryItemId',
    },
    {
      Header: 'SKU',
      accessor: (row, i) => row.sku || '---',
      id: 'sku'
    },
    {
      Header: 'Product ID',
      accessor: (row, i) => row.productId || '---',
      id: 'productId'
    },

    {
      Header: 'Price',
      accessor: (row, i) => <>&#8377;{row.price}</> || '---',
      id: 'price'
    },
    {
      Header: 'Status',
      type: 'static',
      accessor: (row) => row.active || false,
      id: 'active'
    },
    {
      Header: 'Stock',
      type: 'static',
      accessor: (row) => row.isStockAvailable || false,
      id: 'stock'
    },
    {
      Header: 'Action',
    },
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};


export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
