import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as mediaSelectors from '../../ducks/media/selectors';
import { ViewMapReels, DeleteForm } from './components';
import {
  getMedia
} from '../../ducks/media/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';

import { ordersTableConfig } from '../../ducks/media/constants';
import { setSelectedMedia, unmapReelFromMedia } from '../../ducks/media';
import { getReelsForMapping } from '../../ducks/reels-list';
import CustomTable from '../../components/table';

const MediaComponent = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  // const [status, setStatus] = useState(true);

  // Toggle for Modal
  const handleToggle = (data) => {
    const { actions } = props;
    console.log("data", data)
    if(data) actions.setSelectedMedia(data);
    if(!modal) setModal(!modal)
  }

  const handleUpdateActive = (data) => {
    const {
      actions
    } = props;
    actions.updateActive(data);
  }

  const handleProductRoute = (data) => {
    navigate(`/reel-map/${data.mediaId}`);
  }

  const submitData = () => setModal(!modal);
  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getMedia();
  },[]);

  const {
    authenticatedSession,
    data,
    media,
    active,
    actions,
    showDeleteForm,
    selectedReel
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }
  console.log("selectedReel", selectedReel);
  
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content product">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4 className="category-header-label">Songs List</h4>
                    <div>

                      {/* VIEW POPUP SECTION */}
                      <Modal isOpen={modal} className="map-product">
                        <ModalHeader
                          close={<FontAwesomeIcon onClick={()=> setModal(!modal)} icon={faTimes}></FontAwesomeIcon>}
                        >
                          Mapped Products
                        </ModalHeader>
                        <ModalBody>
                          <ViewMapReels {...props} />
                        </ModalBody>
                      </Modal>


                    </div>
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="reels">
                      {/* orderTableConfig */}
                      <CustomTable
                      data={media}
                      onViewClick={handleToggle}
                      onStatusClick={handleUpdateActive}
                      onDeleteClick={actions.toggleDeleteForm}
                      onMapProductClick={handleProductRoute}
                      // onStockClick={handleUpdateStock}
                      options = {ordersTableConfig.options}
                      columns={ordersTableConfig.columns} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

MediaComponent.propTypes = {
  mediaListArray: PropTypes.arrayOf(PropTypes.shape()),
};

MediaComponent.defaultProps = {
  mediaListArray: [],
};

const mapStateToProps = state => ({
  ...state.mediaListArray,
  data: mediaSelectors.dataSelector(state),
});


const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getMedia,
    getReelsForMapping, 
    setSelectedMedia, 
    unmapReelFromMedia
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaComponent);
