import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
export { REDUCER_KEY } from './constants';
import { GetUserToken } from '../../utils';
import { BASE_URL } from './../../config';
import _ from 'lodash';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

export const getReels = (isMapping) => (dispatch, getState) => {
  const { mediaCategory } = getState();
  const { subcategories } = mediaCategory;
  dispatch(dataChanged({loading: true}));
  axios.get([constants.REELS_API],
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const reelsData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            authenticatedSession: true,
            loading: false
          })
        )
      } else {
        let reelsResponse = reelsData[0] && reelsData[0].reels || [];

        //For Mapping it marks the reels mapped
        if(isMapping){
          reelsResponse.forEach(element => {
            element.mapped = [].indexOf(element.productId) > -1
          });
        }

        dispatch(dataChanged({
          [constants.REELS]: reelsResponse,
          authenticatedSession: true,
          loading: false
        }));
      }
      
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}
export const getReelsForMapping = (mediaId) =>  (dispatch, getState) => {

  dispatch(dataChanged({loading: true}));
 //For Mapping it marks the reels mapped
 let mappedReels = [];
 if(mediaId){
    const getReelsByMediaIdFunction = constants.FUNCTION_GET_REELS_BY_MEDIA_API ;
    try {
      axios.get(getReelsByMediaIdFunction(mediaId),{
          headers: {
            'authorization': GetUserToken(),
          },
        })
      .then(function (response) {
        const data = response.data;
        // update category array
        const mediaData = data.responseData;

        if (data.errorMessage) {
          dispatch(
            dataChanged({
              [constants.DATA_ERROR]: data.errorMessage,
              loading: false
            })
          )
        } else {
          mappedReels = mediaData[0].reels.map(el => el.id);
          dispatch(dataChanged({
            [constants.MAPPED_REELS]: mappedReels,
          }));








          const { mediaCategory } = getState();
          const { subcategories } = mediaCategory;
          dispatch(dataChanged({loading: true}));
          axios.get([constants.REELS_API],
            {
              headers: {
                // 'Access-Control-Allow-Origin': '*',
                'authorization': GetUserToken(),
              },
            })
            .then(function (response) {
              const data = response.data;
              // update category array
              const reelsData = data.responseData;
              if (data.errorMessage) {
                dispatch(
                  dataChanged({
                    [constants.DATA_ERROR]: data.errorMessage,
                    authenticatedSession: true,
                    loading: false
                  })
                )
              } else {
                let reelsResponse = reelsData[0] && reelsData[0].reels || [];
        
                //For Mapping it marks the reels mapped
                if(reelsResponse && reelsResponse.length>0){
                  reelsResponse.forEach(element => {
                    element.mapped = mappedReels.indexOf(element.id) > -1
                  });
                }
        
                dispatch(dataChanged({
                  [constants.REELS]: reelsResponse,
                  authenticatedSession: true,
                  loading: false
                }));
              }
              
            })
            .catch(function (error) {
               const { status } = error && error.response || 0;
              if (status == UN_AUTHERIZED_CODE) {
                //redirect to login, clear local storage
                localStorage.removeItem('userData');
                dispatch(dataChanged({loading: false, authenticatedSession: false}));
              } else {
                dispatch(dataChanged({loading: false}));
              }
              // dispatch(boundError({data: error, loading: false}));
            });














        }
      });
    } catch (error) {
      dispatch(dataChanged({loading: false}));
      const { status } = error && error.response || {};
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({ authenticatedSession: false}));
      } else {
        dispatch(dataChanged({
          authenticatedSession: true,
        }));
      }
    }
 }




}

export const initFormValues = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MEDIA_ID]: '',
    })
  )
}

export const viewFormData = (data) => (dispatch) => {
  // console.log('pr', data);
  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
    })
  )
};

export const updateActive = (reelData) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.REELS_STATUS_UPDATE_API;
  const url = api(reelData.id);
  const reelsState = getState().reelsList;



  axios.post(url,{
    active: !reelData.active
  },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const activeData = data.responseData[0];
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {




        const updatedData = reelsState.reels.map(element => {
          if (element.id === reelData.id) {
            element.active = !reelData.active
          }
          return element;
        });
      

      
        if(reelsState.reelSearch.isSearch && !_.isEmpty(reelsState?.reelSearch?.searchResult)){
                
          const updatedSearchData = reelsState.reelSearch.searchResult.map(element => {
            if (element.id === reelData.id) {
              element.active = !reelData.active
            }
            return element;
          });
      
          dispatch(dataChanged({
            [constants.REEL_SEARCH]:{
              searchResult:updatedSearchData || [],
              isSearch:true
            }
          }));
        }

        dispatch(dataChanged({
          [constants.REELS]: updatedData || [],

          // loading: false
          [constants.ACTIVE]: activeData,
          loading: false
        }));
      }
    })
    .catch(function (error) {
      console.log(error)
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};


export const toggleDeleteForm = (data) => (dispatch) => {
  // dispatch(boundModalLoading({ [constants.MODAL_LOADING]: false }));
  dispatch(dataChanged({
    loading: false,
    [constants.SHOW_DELETE_FORM]: data && data.id ? true : false,
    [constants.SELECTED_ROW_ID]: data ? data.id : null,
  }));
};

export const deleteReelForm = (id) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  let api = [constants.REELS_DELETE_API];
  api = api + `/${id}`;
  axios.delete(api,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    }
  )
  .then(
    function (response) {
      const data = response.data;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        );
      } else {
        dispatch(dataChanged({
          loading: false,
          [constants.SHOW_DELETE_FORM]: false,
        }));
      }
      dispatch(getReels());
    }
  )
  .catch(
    function (error) {
      const { status } = error && error.response || 0;
     if (status == UN_AUTHERIZED_CODE) {
       //redirect to login, clear local storage
       dispatch(dataChanged({loading: false}));
     } else {
       dispatch(dataChanged({loading: false}));
     }
   }
  )
};

export const toggleMapProduct = (data) => async (dispatch, getState) => {
  // console.log('pr', data);
  dispatch(dataChanged({loading: true}));

  // let reelProducts = [];
  // if (reelList.productList && reelList.productList.length === 0) {
    try {
      await axios.get([constants.PRODUCTS_API],{
          headers: {
            'authorization': GetUserToken(),
          },
        })
      .then(function (response) {
        const data = response.data;
        // update category array
        const productData = data.responseData;
        if (data.errorMessage) {
          dispatch(
            dataChanged({
              [constants.DATA_ERROR]: data.errorMessage,
              loading: false
            })
          )
        } else {
          // reelProducts = productData[0] && productData[0].products;
          // update category array
          dispatch(dataChanged({
            [constants.PRODUCTS_LIST]: productData[0] && productData[0].products || [],
            loading: false
          }));
        }
      });
    } catch (error) {
      dispatch(dataChanged({loading: false}));
      const { status } = error && error.response || {};
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({ authenticatedSession: false}));
      } else {
        // dispatch(dataChanged({loading: false}));
      }
    }
  // }
  // const updatedData = {
  //   productList: reelProducts,
  //   ...data
  // }
  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
      loading: false
    })
  )
};


export const mapReelToProduct = (productArray, reelId, productId) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.DELETE_REEL_MAPPING_API + reelId + '/' + productId;
  const reelsState = getState().reelsList;

  const updatedData = reelsState.formData && reelsState.formData.products.filter(element => {
    if (productArray.indexOf(element.productId) > -1) {
      return true;
    } else {
      return false;
    }
    // if (element.productId === productId) {
    //   element.active = !data.active
    // }
    // return element;
  });

  reelsState.formData.products = updatedData;

  dispatch(dataChanged({
    [constants.FORM_DATA]: reelsState.formData,
    // loading: false
  }));

  axios.delete(api,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      // const mapData = data.responseData[0];
      // console.log(mapData);
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        window.alert('Product Unmapped Successfully!')
        dispatch(dataChanged({
          // [constants.ACTIVE]: activeData,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};


export const addOrRemoveReelsToMediaMappingArray = (data) => (dispatch, getState) => {
  const reelsListState = getState().reelsList;
  const { mappedReels, reels } = reelsListState;
  const { id } = data;
  console.warn(id);
  const updatedArray = [...mappedReels];

  if (updatedArray.indexOf(id) > -1) {
    updatedArray.splice(updatedArray.indexOf(id), 1);
  } else {
    updatedArray.push(id);
  }

  // expense loop
  const updatedReels = [...reels];
  updatedReels.forEach(element => {
    element.mapped = updatedArray.indexOf(element.id) > -1
  });


  dispatch(
    dataChanged({
      [constants.MAPPED_REELS]: updatedArray,
      [constants.REELS]: reels,
    })
  )
};

export const mapReelToMedia = ( mediaId )  => (dispatch, getState) => {
  const reelsState = getState().reelsList;
  const { mappedReels } = reelsState;
  dispatch(dataChanged({loading: true}));
  const getApiFunction = constants.FUNCTION_REELS_MAP_API;
  const url = getApiFunction(mediaId);

  console.log('mapReelToMedia', url);
  axios.post(url,{ reelsIds:[...mappedReels] },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;

      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        window.alert('Selected Reels Mapped Successfully!');
        dispatch(dataChanged({
          // [constants.ACTIVE]: activeData,
          authenticatedSession: true,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};

export const getReelSearchResult =  (searchText)=>(dispatch)=>{
  dispatch(dataChanged({loading: true}));

  axios.post(`${BASE_URL}/post/search?query=${searchText}`,{},
     {
       headers: {
         // 'Access-Control-Allow-Origin': '*',
         'authorization': GetUserToken(),
       },
     }).then( (response)=> {
      const data = response.data;

      dispatch(dataChanged({
        [constants.REEL_SEARCH]:{
          searchResult:  data.responseData,
          isSearch:true,
          searchText: searchText
        },
      }));
      dispatch(dataChanged({loading: false}));

    })
    .catch(function (error) {
       const { status } = error && error.response || 0;

        console.error(error);
        dispatch(dataChanged({loading: false}));
    });
     // return response;
 
 }

export const searchResultOff = (data) => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.REEL_SEARCH]: {
        isSearch : false,
        searchText:''
      },
    })
  )
};
