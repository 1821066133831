import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
export { REDUCER_KEY } from './constants';
import { GetUserToken } from '../../utils';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

export const getImages = (type) => (dispatch, getState) => {
  const { mediaCategory } = getState();
  const { subcategories } = mediaCategory;
  dispatch(dataChanged({loading: true}));
  axios.get([constants.IMAGES_API],
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const imageData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        // update category array
        dispatch(dataChanged({
          [constants.IMAGES]: imageData[0] && imageData[0].images || [],
          loading: false
        }));
      }
      
    })
    .catch(function (error) {

       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}

export const initFormValues = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MEDIA_ID]: '',
    })
  )
}

export const viewFormData = (data) => (dispatch) => {
  // console.log('pr', data);
  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
    })
  )
};

export const updateActive = (data) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.IMAGES_STATUS_UPDATE_API;
  const url = api(data.id);
  const imagesState = getState().imagesList;

  const updatedData = imagesState.images.map(element => {
    if (element.id === data.id) {
      element.active = !data.active
    }
    return element;
  });

  dispatch(dataChanged({
    [constants.IMAGES]: updatedData || [],
    // loading: false
  }));

  axios.post(url,{
    active: data.active
  },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const activeData = data.responseData[0];
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        dispatch(dataChanged({
          [constants.ACTIVE]: activeData,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};


export const toggleDeleteForm = (data) => (dispatch) => {
  // dispatch(boundModalLoading({ [constants.MODAL_LOADING]: false }));
  dispatch(dataChanged({
    loading: false,
    [constants.SHOW_DELETE_FORM]: data && data.id ? true : false,
    [constants.SELECTED_ROW_ID]: data ? data.id : null,
  }));
};

export const deleteImagesForm = (id) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  let api = [constants.IMAGES_DELETE_API];
  api = api + `/${id}`;
  axios.delete(api,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    }
  )
  .then(
    function (response) {
      const data = response.data;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        );
      } else {
        dispatch(dataChanged({
          loading: false,
          [constants.SHOW_DELETE_FORM]: false,
        }));
      }
      dispatch(getImages());
    }
  )
  .catch(
    function (error) {
      const { status } = error && error.response || 0;
     if (status == UN_AUTHERIZED_CODE) {
       dispatch(dataChanged({loading: false}));
     } else {
       dispatch(dataChanged({loading: false}));
     }
   }
  )
};


export const toggleMapProduct = (data) => async (dispatch, getState) => {
  // console.log('pr', data);
  dispatch(dataChanged({loading: true}));

  try {
    await axios.get([constants.PRODUCTS_API],{
        headers: {
          'authorization': GetUserToken(),
        },
      })
    .then(function (response) {
      const data = response.data;
      // update category array
      const productData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            authenticatedSession: true,
            loading: false
          })
        )
      } else {
        // reelProducts = productData[0] && productData[0].products;
        // update category array
        dispatch(dataChanged({
          [constants.PRODUCTS_LIST]: productData[0] && productData[0].products || [],
          authenticatedSession: true,
          loading: false
        }));
      }
    });
  } catch (error) {
    dispatch(dataChanged({loading: false}));
    const { status } = error && error.response || {};
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      dispatch(dataChanged({ authenticatedSession: false}));
    } else {
      // dispatch(dataChanged({loading: false}));
    }
  }

  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
      loading: false
    })
  )
};


export const mapImageToProduct = (productArray, reelId, productId) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.DELETE_REEL_MAPPING_API + reelId + '/' + productId;
  const reelsState = getState().reelsList;

  const updatedData = reelsState.formData && reelsState.formData.products.filter(element => {
    if (productArray.indexOf(element.productId) > -1) {
      return true;
    } else {
      return false;
    }
    // if (element.productId === productId) {
    //   element.active = !data.active
    // }
    // return element;
  });

  reelsState.formData.products = updatedData;

  dispatch(dataChanged({
    [constants.FORM_DATA]: reelsState.formData,
    // loading: false
  }));

  axios.delete(api,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      // const mapData = data.responseData[0];
      // console.log(mapData);
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        window.alert('Product Unmapped Successfully!')
        dispatch(dataChanged({
          // [constants.ACTIVE]: activeData,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};
