import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promise from 'redux-promise';
import createDebounce from 'redux-debounced';

import {
  login,
  dashboard,
  mediaList,
  mediaCategory,
  productCategory,
  profile,
  registration,
  merchants,
  orders,
  products,
  creators,
  auctions,
  mediaManagement,
  reelsList,
  imagesList,
  advManagement,
  advList, mediaListArray,movieList, sales } from './ducks';

import actionTypes from './ducks/login/action-types';

const unorderedReducers = {
  [login.REDUCER_KEY]: login.reducer,
  [dashboard.REDUCER_KEY]: dashboard.reducer,
  [profile.REDUCER_KEY]: profile.reducer,
  [mediaList.REDUCER_KEY]: mediaList.reducer,
  [mediaCategory.REDUCER_KEY]: mediaCategory.reducer,
  [productCategory.REDUCER_KEY]: productCategory.reducer,
  [registration.REDUCER_KEY]: registration.reducer,
  [merchants.REDUCER_KEY]: merchants.reducer,
  [orders.REDUCER_KEY]: orders.reducer,
  [products.REDUCER_KEY]: products.reducer,
  [auctions.REDUCER_KEY]: auctions.reducer,
  [mediaManagement.REDUCER_KEY]: mediaManagement.reducer,
  [creators.REDUCER_KEY]: creators.reducer,
  [reelsList.REDUCER_KEY]: reelsList.reducer,
  [imagesList.REDUCER_KEY]: imagesList.reducer,
  [advList.REDUCER_KEY]: advList.reducer,
  [advManagement.REDUCER_KEY]: advManagement.reducer,
  [mediaListArray.REDUCER_KEY]:mediaListArray.reducer,
  [movieList.REDUCER_KEY]:movieList.reducer,
  [sales.REDUCER_KEY]:sales.reducer
};

const orderedReducers = Object.keys(unorderedReducers)
  .sort()
  .reduce((hash, key) => ({
    ...hash,
    [key]: unorderedReducers[key],
  }), {});

export function configureStore(initialState = {}) {
  const reducer = combineReducers(orderedReducers);

  const rootReducer = (state, action) => {
    // in case of Logout restore store
    if (action.type === actionTypes.RESET_STORE) {
      // eslint-disable-next-line
      state = undefined;
    }
    return reducer(state, action);
  };

  const middleware = [
    // fuxA(), // google analytics, mixpanel...future use
    createDebounce(),
    promise,
    thunkMiddleware, // lets us dispatch() functions
  ];

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  return store;
}

export default configureStore;