import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
export { REDUCER_KEY } from './constants';
import { GetUserToken } from '../../utils';
import { BASE_URL } from '../../config';
import _ from 'lodash';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);


// const getSubgategories = (categoriesArray) => {
//   let subCategories = [];
//   let innerSubCategories = [];
//   subCategories = categoriesArray.reduce(
//   (previousValue, currentValue) => {
//     //add parent categories to each subcategoryItem
//     let updatedCurrentValue = [];
//       currentValue.subCategory.forEach((element, index) => {
//       element.parentCategory = currentValue.name;
//       updatedCurrentValue.push(element);
//     });
//     return [...previousValue , ...updatedCurrentValue]
//   },[]);
//   return subCategories;
// }

// const getInnerSubgategories = (subCategoriesArray, categoriesArray) => {
//   if (subCategoriesArray.length > 0) {
//     let innerSubCategories = [];
//     innerSubCategories = subCategoriesArray.reduce(
//     (previousValue, currentValue) => {
//       //add parent categories to each subcategoryItem
//       let updatedCurrentValue = [];
//         currentValue.subCategory.forEach((element, index) => {
//         element.parentCategory = currentValue.name;
//         updatedCurrentValue.push(element);
//       });
//       return [...previousValue , ...updatedCurrentValue]
//     },[]);
//     return innerSubCategories;
//   } else {
//     const subCategories = getSubgategories(categoriesArray);
//     let innerSubCategories = [];
//     innerSubCategories = subCategories.reduce(
//     (previousValue, currentValue) => {
//       //add parent categories to each subcategoryItem
//       let updatedCurrentValue = [];
//         currentValue.subCategory.forEach((element, index) => {
//         element.parentCategory = currentValue.name;
//         updatedCurrentValue.push(element);
//       });
//       return [...previousValue , ...updatedCurrentValue]
//     },[]);
//     return innerSubCategories;
//   }
  
// };

export const getMerchants = (type) => (dispatch, getState) => {
  const { mediaCategory } = getState();
  const { subcategories } = mediaCategory;
  dispatch(dataChanged({loading: true}));
  axios.get([constants.MERCHANT_API],
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const merchantsData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        // update category array
        dispatch(dataChanged({
          [constants.MERCHANTS]: merchantsData,
          loading: false
        }));
      }
      
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}

export const getSubCategories = () => (dispatch) => {
  dispatch(dataChanged({loading: true}));
  axios.get([constants.SUBCATEGORY_API],
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const subcategoriesData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        // update sub-category array
        dispatch(dataChanged({
          [constants.SUBCATEGORIES]: subcategoriesData,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}


export const getInnerSubCategories = () => (dispatch) => {
  dispatch(dataChanged({loading: true}));
  axios.get([constants.INNER_SUBCATEGORY_API],
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const innerSubcategoriesData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        // update innersub-category array
        dispatch(dataChanged({
          [constants.INNER_SUBCATEGORIES]: innerSubcategoriesData,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}

export const addCategory = (value) => (dispatch, getState) => {
  const { mediaCategory } = getState();
  const { categoryFormData } = mediaCategory;
  let data = {};
  let url = [constants.ADD_MEDIA_CATEGORY_API];
  if(Object.keys(categoryFormData).length > 0) {
    data.name = value.category;
    data.id = categoryFormData.id;
    url = [constants.UPDATE_MEDIA_CATEGORY_API];
  } else {
    data.name = value.category;
  }
  dispatch(dataChanged({loading: true}));
  if (!value.category) return;
  axios.post(url,data,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const categories = data.responseData;
      const subCategories = categories;
      // update sub-category array
      dispatch(getCategories());
      dispatch(dataChanged({loading: false}));
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
    });
}

export const addSubCategory = (value) => (dispatch, getState) => {
  const state = getState();
  const {
    subCategoryFormData,
    subcategories,
  } = state.mediaCategory;
  const updateForm = Object.keys(subCategoryFormData).length > 0;
  let data = {};
  let url = [constants.ADD_MEDIA_SUBCATEGORY_API];
  if(updateForm) {
    data.name = value.subcategory;
    data.id = subCategoryFormData.id;
    data.categoryId = Number(value.category);
    url = [constants.UPDATE_MEDIA_SUBCATEGORY_API];
  } else {
    data.name = value.subcategory;
    data.categoryId = Number(value.category);
  }

  dispatch(dataChanged({loading: true}));
  if (!value.category) return;
  axios.post(url,data,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const newSubCategory = data.responseData[0];
      let updatedSubCategories = [];
      if(!updateForm) {
        updatedSubCategories = [...subcategories, newSubCategory];
      } else {
        const elIndex = subcategories.findIndex((el) => el.id == newSubCategory.id);
        subcategories[elIndex].name = newSubCategory.name;
        updatedSubCategories = [...subcategories];
      }

      // console.log('subcategories', subcategories)

      dispatch(dataChanged({
        [constants.SUBCATEGORIES]: updatedSubCategories,
        loading: false,
      }));
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
    });
}

export const updateSubCategory = (value) => (dispatch, getState) => {
  const state = getState();
  const {
    subCategoryFormData,
    subcategories,
  } = state.mediaCategory;

  let data = {};
  let url = [constants.ADD_MEDIA_SUBCATEGORY_API];
  if(Object.keys(subCategoryFormData).length > 0) {
    data.name = value.subcategory;
    data.id = subCategoryFormData.id;
    data.categoryId = Number(value.category);
    url = [constants.UPDATE_MEDIA_SUBCATEGORY_API];
  } else {
    data.name = value.subcategory;
    data.categoryId = Number(value.category);
  }

  dispatch(dataChanged({loading: true}));
  if (!value.category) return;
  axios.post(url,data,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const newSubCategory = data.responseData[0];
      const updatedSubCategories = [...subcategories, newSubCategory];

      dispatch(dataChanged({
        [constants.SUBCATEGORIES]: updatedSubCategories,
        loading: false,
      }));
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
    });
}


export const initFormValues = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MEDIA_DATA]: {},
      [constants.MEDIA_ID]: '',
    })
  )
}

export const updateFormData = (data) => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
    })
  )
};


export const updatesubCategoryFormData = (data) => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.SUBCATEGORY_FORM_DATA]: data,
    })
  )
};

export const getMerchantSearchResult =  (searchText)=>(dispatch)=>{
  dispatch(dataChanged({loading: true}));
  axios.get(`${BASE_URL}/merchant/search?searchText=${searchText}`,
     {
       headers: {
         // 'Access-Control-Allow-Origin': '*',
         'authorization': GetUserToken(),
       },
     }).then( (response)=> {
      const data = response.data;
      
      dispatch(dataChanged({
        [constants.MERCHANTS_SEARCH]:{
          searchResult:  data.responseData[0],
          isSearch:true,
          searchText:searchText
        },
      }));
      dispatch(dataChanged({loading: false}));
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;

        console.error(error);
        dispatch(dataChanged({loading: false}));
    });
     // return response;
 
 }

 export const searchResultOff = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MERCHANTS_SEARCH]: {
        isSearch : false,
        searchText:''
      },
    })
  )
};

export const updateActive = (merchantData) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.FUNCTION_MERCHANT_UPDATE_STATUS_API;
  const url = api(merchantData.merchantId);
  const merchantState = getState().merchants;



  axios.post(url,{
    active: !merchantData.active
  },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const activeData = data.responseData[0];
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {




        const updatedData = merchantState.merchants.map(element => {
          if (element.merchantId === merchantData.merchantId) {
            element.active = !merchantData.active
          }
          return element;
        });
      

      
        if(merchantState.merchantsSearch.isSearch && !_.isEmpty(merchantState?.merchantsSearch?.searchResult)){
                
          const updatedSearchData = merchantState.merchantsSearch.searchResult.map(element => {
            if (element.merchantId === merchantData.merchantId) {
              element.active = !merchantData.active
            }
            return element;
          });
      
          dispatch(dataChanged({
            [constants.MERCHANTS_SEARCH]:{
              searchResult:updatedSearchData || [],
              isSearch:true
            }
          }));
        }

        dispatch(dataChanged({
          [constants.MERCHANTS]: updatedData || [],
          loading: false
        }));
      }
    })
    .catch(function (error) {
      console.log(error)
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};
