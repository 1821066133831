import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthProvider } from './pages/authProvider';
import Layout from './pages/layout';
import RequireAuth from './pages/RequireAuth';

import {
  Nav,
  Login,
  ForgotPassword,
  Registration,
  Profile,
  Dashboard,
  MediaCategory,
  MediaSubCategory,
  MediaInnerSubCategory,
  MediaList,
  Merchants,
  Orders,
  Products,
  ContentCreators,
  ProductCategory,
  ProductSubCategory,
  ProductInnerSubCategory,
  Sidebar,
  Auctions,
  MediaManagement,
  ReelsList,
  ImagesList,
  AdList,
  AdManagement,
  MediaComponent,
  MovieComponent,
  ReelMap,SalesPageComponent,ProductMappingReportComponent
} from './pages';

import { configureStore } from './store';

import './style.scss';

export const store = configureStore();

const App = () => {
  return (
    <AuthProvider> {/* it might go to layout */}
      <Provider store={store}>
        <Router>
          <Routes>
            <Route>
              <Route path="/" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="registration" element={<Registration />} />
              <Route path="profile" element={<Profile />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="media-category" element={<MediaCategory />} />
              <Route path="media-subcategory" element={<MediaSubCategory />} />
              <Route path="media-inner-subcategory" element={<MediaInnerSubCategory />} />
              <Route path="product-category" element={<ProductCategory />} />
              <Route path="product-subcategory" element={<ProductSubCategory />} />
              <Route path="product-inner-subcategory" element={<ProductInnerSubCategory />} />
              <Route path="merchants" element={<Merchants />} />
              <Route path="orders" element={<Orders />} />
              <Route path="products/image/:customId" element={<Products />} />
              <Route path="products/reel/:customId" element={<Products />} />
              <Route path="products" element={<Products />} />
              <Route path="auctions" element={<Auctions />} />
              <Route path="content-creators" element={<ContentCreators />} />
              <Route path="media-list" element={<MediaList />} />
              <Route path="media-management" element={<MediaManagement />} />
              <Route path="reels-list" element={<ReelsList />} />
              <Route path="songs" element={<MediaComponent />} />
              <Route path="reel-map" element={<ReelMap />} />
              <Route path="reel-map/:customId" element={<ReelMap />} />
              <Route path="movie-list" element={<MovieComponent />} />
              <Route path="images-list" element={<ImagesList />} />
              <Route path="adv-list" element={<AdList />} />
              <Route path="adv-management" element={<AdManagement />} />
              <Route path="sales" element={<SalesPageComponent />} />
              <Route path="product-map-report" element={<ProductMappingReportComponent />} />
            </Route>
          </Routes>
        </Router>
      </Provider>
    </AuthProvider>
  )
}

export default App;