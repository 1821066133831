import { BASE_URL } from './../../config';
export const REDUCER_KEY = 'movieList';

export const DATA = 'data';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const MOVIE_LIST = 'movieList';
export const AUTHENTICATED_SESSION = 'authenticatedSession';

export const GET_MOVIE_LIST_API = `${BASE_URL}/movie`;

export const ordersTableConfig = {
  columns: [
    {
      Header: "Cover Photo",
      id: "cover_photo",
      meta: {
        movie_column: true,
      },
    },
    {
      Header: "Movie ID",
      accessor: (row, i) => row.movieID,
      id: "movieID",
    },
    {
      Header: "Title",
      accessor: (row, i) => row.title || "---",
      id: "title",
    },
    {
      Header: "Release Date",
      accessor: (row, i) => {
        if(row.releaseDate){
          let dateVar = new Date(row.releaseDate);
          return `${dateVar.getDate()}-${dateVar.getMonth()}-${dateVar.getFullYear()}`;
        }
        return  "---";
      },
      id: "release-date",
    },
    {
      Header: "Language",
      accessor: (row, i) => row.language.join(", ") || "---",
      id: "language_id",
    },
    {
      Header: "Country",
      accessor: (row, i) => row.country || "---",
      id: "country",
    },
    {
      Header: "Duration",
      accessor: (row, i) => row.duration + " mins" || "---",
      id: "duration",
    },

  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400,
  },
};

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [MOVIE_LIST]: [],
  [AUTHENTICATED_SESSION]: true,
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
