import { BASE_URL } from './../../config';
export const REDUCER_KEY = 'productCategory';

export const DATA = 'data';
export const MEDIA_DATA = 'mediaData';
export const IMAGE_DATA = 'imageData';
export const AUDIO_DATA = 'audioData';
export const VIDEO_DATA = 'videoData';

export const DATA_LOADING = 'loading';
export const IMAGE_UPLOADING = 'imageUploading';
export const VIDEO_UPLOADING = 'videoUploading';
export const AUDIO_UPLOADING = 'audioUploading';
export const DATA_ERROR = 'error';
export const MEDIA_MANAGEMENT_FORM_DATA = 'mediaManagementFormData';
export const CATEGORY_API = `${BASE_URL}/product/category/all`;
export const SUBCATEGORY_API = `${BASE_URL}/product/subcategory/all`;
export const INNER_SUBCATEGORY_API = `${BASE_URL}/product/innerSubCategory/all`;
export const ADD_MEDIA_CATEGORY_API = `${BASE_URL}/product/category/add`;
export const UPDATE_MEDIA_CATEGORY_API = `${BASE_URL}/product/category/update`;
export const ADD_MEDIA_SUBCATEGORY_API = `${BASE_URL}/product/subcategory/add`;
export const UPDATE_MEDIA_SUBCATEGORY_API = `${BASE_URL}/product/subcategory/update`;
export const ADD_MEDIA_INNER_SUBCATEGORY_API = `${BASE_URL}/product/innerSubCategory/add`;
export const UPDATE_MEDIA_INNER_SUBCATEGORY_API = `${BASE_URL}/product/innerSubCategory/update`;
export const CATEGORIES = 'categories';
export const SUBCATEGORIES = 'subcategories';
export const INNER_SUBCATEGORIES = 'innersubcategories';
export const CATEGORY_ITEM_ID = 'categoryItemId';
export const MEDIA_ID = 'mediaId';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const FORM_DATA = 'categoryFormData';
export const SUBCATEGORY_FORM_DATA = 'subCategoryFormData';
export const INNER_SUBCATEGORY_FORM_DATA = 'innerSubCategoryFormData';
export const CATEGORY_ID = 'categoryId';

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [MEDIA_MANAGEMENT_FORM_DATA]: {},
  [CATEGORIES]: [],
  [SUBCATEGORIES]: [],
  [INNER_SUBCATEGORIES]: [],
  [CATEGORY_ITEM_ID]: '',
  [MEDIA_ID]: '',
  [AUTHENTICATED_SESSION]: true,
  [FORM_DATA]: {},
  [SUBCATEGORY_FORM_DATA]: {},
  [INNER_SUBCATEGORY_FORM_DATA]: {},
  [CATEGORY_ID]: ''
};

export const subcategoryTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header: 'Category',
      accessor: 'productCategory.name',
    },
    {
      Header: 'Subcategory',
      accessor: 'name',
    },
    {
      Header: 'Status',
      type: 'static'
    },
    {
      Header: 'Action',
    },
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};

export const innerSubcategoryTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header: 'Category',
      accessor: 'productCategory.name',
    },
    {
      Header: 'Subcategory',
      accessor: 'productSubCategory.name',
    },
    {
      Header: 'Inner Subcategory',
      accessor: 'name',
    },
    {
      Header: 'Status',
      type: 'static'
    },
    {
      Header: 'Action',
    },
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};

export const categoryTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header: 'Category Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      type: 'static'
    },
    {
      Header: 'Action',
    },
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
