import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';

import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as reelsSelectors from '../../ducks/reels-list/selectors';
import { ViewProduct } from './components';
import { addOrRemoveReelsToMediaMappingArray, mapReelToMedia,getReelSearchResult, searchResultOff } from '../../ducks/reels-list/index';
import {  getReelsForMapping } from '../../ducks/reels-list/index.js'
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';
import { reelListTableForMapping } from '../../ducks/reels-list/constants';
import CustomTable from '../../components/table';

const ReelMap = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [productMap, setProductMap] = useState(null);
  const [mediaToMap, setMediaToMap] = useState(null);
  const pathName = window.location.pathname;
  const isImage = pathName.split("/").includes("image");

  // Toggle for Modal
  const handleToggle = (data) => {
    const { actions } = props;
    actions.viewFormData(data);

    setModal(!modal);
  };

  const handleCheckBoxClick = (data) => {
    const { actions } = props;
    actions.addOrRemoveReelsToMediaMappingArray(data);
  };

  const handleMapProductClick = (event) => {
    const { actions } = props;
    actions.mapReelToMedia(mediaToMap);
  };

  const submitData = () => setModal(!modal);

  // call category and subcategories api to load the data
  useEffect(() => {
    const { actions } = props;

    //URL params ->Song (Media) ID
    const { customId } = params;

    if (customId) setMediaToMap(customId);
    // actions.getProducts(customId, isImage);
  }, [params]);

  useEffect(async () => {
    // Getting all reels into store
    if(mediaToMap){
      actions.getReelsForMapping(mediaToMap);
    }
  }, [mediaToMap]);

  const {
    authenticatedSession,
    data,
    products,
    active,
    actions,
    reels,
    mappedReels,
    reelSearch
  } = props;
  if (!authenticatedSession) {
    auth.setUserDataToNull();
    navigate("/", { replace: true });
  }

//Search functionality
  const searchFunction = (searchText)=>{
    const {
      actions,
    } = props;
    if(_.isEmpty(_.trim(searchText))){

      actions.searchResultOff(false);
    }else{
      actions.getReelSearchResult(searchText);
    }
  }

  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content product">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    {mediaToMap ? (
                      <>
                        <Link to="/songs" className="back-to-reels">
                          <FontAwesomeIcon icon={faArrowLeft} /> Go back to
                        </Link>
                        <Row>
                          <Col md="6">
                            <h4 className="category-header-label">
                              Reels Mapping
                            </h4>
                          </Col>
                          <Col md="6" className="map-button">
                            <Button
                              disabled={mappedReels.length < 1}
                              color="primary"
                              onClick={handleMapProductClick}
                            >
                              Map Selected Reels
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <h4 className="category-header-label">Reels</h4>
                    )}

                    <div>
                      <Modal isOpen={modal} className="product">
                        <ModalHeader
                          close={
                            <FontAwesomeIcon
                              onClick={handleToggle}
                              icon={faTimes}
                            ></FontAwesomeIcon>
                          }
                        >
                          View Product
                        </ModalHeader>
                        <ModalBody>
                          <ViewProduct {...props} />
                        </ModalBody>
                      </Modal>
                    </div>
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="products">
                      {/* orderTableConfig */}
                      <CustomTable
                        data={reelSearch.isSearch ?  reelSearch.searchResult: reels}
                        onViewClick={mediaToMap ? null : handleToggle}
                        onCheckboxClick={mediaToMap ? handleCheckBoxClick : null}
                        onMapProductClick={(data) => console.log(data)}
                        options={reelListTableForMapping.options}
                        columns={reelListTableForMapping.columns}
                        searchFunctionality={{
                          isSearchState: reelSearch.isSearch,
                          searchTextState: reelSearch.searchText,
                          
                          searchHandler: (data) => { searchFunction(data) },
                          searchClear : actions.searchResultOff,
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
}

ReelMap.propTypes = {
  reels: PropTypes.arrayOf(PropTypes.shape()),

};

ReelMap.defaultProps = {
  reels: [],
};

const mapStateToProps = state => ({
  ...state.reelsList,
  data: reelsSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getReelsForMapping,
    addOrRemoveReelsToMediaMappingArray,
    mapReelToMedia,
    getReelSearchResult,searchResultOff
  }, dispatch);

  return { actions }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReelMap);
