import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { Form as BootstrapForm, FormControl, Button, Alert } from 'react-bootstrap';
import { Form, Field } from "react-final-form";
import Layout from '../../pages/layout';
import * as registrationSelectors from '../../ducks/registration/selectors';
import {
  createUser,
  verifyUser,
} from '../../ducks/registration/index';
import LOGIN_BG from '../../images/login-bg1.jpeg';
import './index.scss';

const Registration = (props) => {
  const navigate = useNavigate();

  const handleSubmit = (data) => {
    const {
      username,
      password,
      actions,
    } = props;
    // console.log('reg form data', data);
    actions.createUser(data);
  }
  const validateForm = (values) => {
      const errors = {}

      if (!values.firstName) {
        errors.firstName = 'First Name Required'
      }
      if (!values.lastName) {
        errors.lastName = 'Last Name Required'
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone Number Required'
      }
      if (!values.email) {
        errors.email = 'Email Required'
      }
      if (!values.password) {
        errors.password = 'Password Required'
      }
      return errors
  }
  const validateVerifyForm = (values) => {
    const errors = {}

    if (!values.email) {
      errors.email = 'Email is Required'
    }
    if (!values.otp) {
      errors.otp = 'OTP is Required'
    }
    return errors
  }
  const handleVerify = (formData) => {
    const {
      verifyData,
      actions,
    } = props;
    
    actions.verifyUser(formData, (data) => {
      // re-route to login page once user suuccessfully verified
      window.location.href = '/'
    });
  }
  const {
    data,
    login
  } = props;
  
  const showError = typeof data == 'string';
  // if (true) {
  if (data.responseData) {
    return (
      <>
          <main className="authentication-content registration-content">
            <div className="container-fluid">
                <div className="card shadow rounded-0 overflow-hidden">
                  <div className="row g-0">
                    <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                      <img src={LOGIN_BG} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6">
                      <div className="card-body p-4 p-sm-5">
                      <h5 className="card-title">Verify Account</h5>
                      <p className="card-text mb-5"></p>
                      <Form
                          onSubmit={handleVerify}
                          initialValues={{}} // if needed we can pass it
                          validate={validateVerifyForm}
                          render={({ handleSubmit, form, submitting, pristine, values, submitError }) => (
                          <BootstrapForm onSubmit={handleSubmit}>
                            <div className="row g-3">
                              <div className="col-6">
                                <label htmlFor="email" className="form-label">Email Address</label>
                                <div className="ms-auto position-relative input-wrapper">
                                  <Field name="email" type="email" placeholder="Enter Email Address">
                                    {props => (
                                      <>
                                      <FormControl
                                        name={props.input.name}
                                        placeholder={props.placeholder}
                                        onChange={props.input.onChange}
                                        type="email"
                                        value={props.input.value}
                                      />
                                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                                        <span>{props.meta.error || props.meta.submitError}</span>
                                      )}
                                    </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="col-6">
                                <label htmlFor="otp" className="form-label">Verification Code</label>
                                <div className="ms-auto position-relative input-wrapper">
                                  <Field name="otp" placeholder="Enter Verification Code">
                                    {props => (
                                      <>
                                        <FormControl
                                          name={props.input.name}
                                          placeholder={props.placeholder}
                                          onChange={props.input.onChange}
                                          type="number"
                                        />
                                        {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                                          <span>{props.meta.error || props.meta.submitError}</span>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-grid">
                                  <button type="submit" className="btn btn-primary radius-30">Verify</button>
                                </div>
                              </div>
                            </div>
                          </BootstrapForm>
                        )}/>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </main>
      </>
    )
  }
  return (
    <>
        <main className="authentication-content registration-content">
          <div className="container-fluid">
              <div className="card shadow rounded-0 overflow-hidden">
                <div className="row g-0">
                  <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center">
                    <img src={LOGIN_BG} className="img-fluid" alt="" />
                  </div>
                  <div className="col-lg-6">
                    <div className="card-body p-4 p-sm-5">
                    <h5 className="card-title">Create Account</h5>
                    {showError && <Alert color="danger" className='alert-danger'>{data}</Alert>}
                    <p className="card-text mb-5"></p>
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={{}} // if needed we can pass it
                        validate={validateForm}
                        render={({ handleSubmit, form, submitting, pristine, values, submitError }) => (
                          <BootstrapForm onSubmit={handleSubmit}>
                            <div className="row g-3">
                              <div className="col-6">
                              <label htmlFor="firstName" className="form-label">First Name*</label>
                              <div className="ms-auto position-relative input-wrapper">
                              <Field name="firstName" placeholder="Enter First Name">
                                  {props => (
                                    <>
                                      <FormControl
                                        name={props.input.name}
                                        placeholder={props.placeholder}
                                        onChange={props.input.onChange}
                                      />
                                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                                        <span>{props.meta.error || props.meta.submitError}</span>
                                      )}
                                    </>
                                  )}
                                </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="lastName" className="form-label">Last Name*</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="lastName" placeholder="Enter Last Name">
                                  {props => (
                                    <>
                                      <FormControl
                                        name={props.input.name}
                                        placeholder={props.placeholder}
                                        onChange={props.input.onChange}
                                      />
                                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                                        <span>{props.meta.error || props.meta.submitError}</span>
                                      )}
                                    </>
                                  )}
                                </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="password" className="form-label">Password*</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="password" placeholder="Enter Password">
                                  {props => (
                                    <>
                                      <FormControl
                                        name={props.input.name}
                                        placeholder={props.placeholder}
                                        onChange={props.input.onChange}
                                        type="password"
                                      />
                                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                                        <span>{props.meta.error || props.meta.submitError}</span>
                                      )}
                                    </>
                                  )}
                                </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="phoneNumber" className="form-label">Phone Number*</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="phoneNumber" placeholder="Enter Phone Number">
                                {props => (
                                  <>
                                    <FormControl
                                      name={props.input.name}
                                      placeholder={props.placeholder}
                                      onChange={props.input.onChange}
                                      type="tel"
                                    />
                                    {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                                      <span>{props.meta.error || props.meta.submitError}</span>
                                    )}
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="email" className="form-label">Email Address*</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="email" type="email" placeholder="Enter Email Address">
                                {props => (
                                  <>
                                  <FormControl
                                    name={props.input.name}
                                    placeholder={props.placeholder}
                                    onChange={props.input.onChange}
                                    type="email"
                                  />
                                  {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                                    <span>{props.meta.error || props.meta.submitError}</span>
                                  )}
                                </>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="address_line1" className="form-label">Address1</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="address_line1" type="text" placeholder="Address1">
                                {props => (
                                  <FormControl
                                    name={props.input.name}
                                    placeholder={props.placeholder}
                                    onChange={props.input.onChange}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="address_line2" className="form-label">Address2</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="address_line2" type="text" placeholder="Address2">
                                {props => (
                                  <FormControl
                                    name={props.input.name}
                                    placeholder={props.placeholder}
                                    onChange={props.input.onChange}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="city" className="form-label">City</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="city" type="text" placeholder="Enter City">
                                {props => (
                                  <FormControl
                                    name={props.input.name}
                                    placeholder={props.placeholder}
                                    onChange={props.input.onChange}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="postal_code" className="form-label">Postal Code</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="postal_code" type="number" placeholder="Enter Postal Code">
                                {props => (
                                  <FormControl
                                    name={props.input.name}
                                    placeholder={props.placeholder}
                                    onChange={props.input.onChange}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="state" className="form-label">State</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="state" type="text" placeholder="Enter State">
                                {props => (
                                  <FormControl
                                    name={props.input.name}
                                    placeholder={props.placeholder}
                                    onChange={props.input.onChange}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="country" className="form-label">Country</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="country" type="text" placeholder="Enter Country">
                                {props => (
                                  <FormControl
                                    name={props.input.name}
                                    placeholder={props.placeholder}
                                    onChange={props.input.onChange}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="landmarks" className="form-label">Landmark</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="landmarks" type="text" placeholder="Enter Landmark">
                                {props => (
                                  <FormControl
                                    name={props.input.name}
                                    placeholder={props.placeholder}
                                    onChange={props.input.onChange}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <label htmlFor="telephone" className="form-label">Telephone</label>
                            <div className="ms-auto position-relative input-wrapper">
                              <Field name="landmarks" placeholder="Enter Telephone">
                                {props => (
                                  <FormControl
                                    name={props.input.name}
                                    placeholder={props.placeholder}
                                    onChange={props.input.onChange}
                                    type="tel"
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="d-grid">
                              <button type="submit" className="btn btn-primary radius-30">Sign Up</button>
                            </div>
                          </div>
                          <div className="col-12">
                            <p className="mb-0">Already have an account? <a className="link" onClick={()=>{navigate('/', {replace: true})}}>Sign In here</a></p>
                          </div>
                        </div>
                      </BootstrapForm>
                      )}/>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </main>
    </>
  )
}


Registration.propTypes = {
  username: PropTypes.string,
  verifyData: PropTypes.shape(),
}

const mapStateToProps = state => ({
  ...state.registration,
  data: registrationSelectors.dataSelector(state),
  verifyData: registrationSelectors.verifyDataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    createUser,
    verifyUser,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
