import { BASE_URL } from './../../config';

export const REDUCER_KEY = 'contentCreators';

export const DATA = 'data';
export const MEDIA_DATA = 'mediaData';
export const IMAGE_DATA = 'imageData';
export const AUDIO_DATA = 'audioData';
export const VIDEO_DATA = 'videoData';

export const DATA_LOADING = 'loading';
export const IMAGE_UPLOADING = 'imageUploading';
export const VIDEO_UPLOADING = 'videoUploading';
export const AUDIO_UPLOADING = 'audioUploading';
export const DATA_ERROR = 'error';
export const MEDIA_MANAGEMENT_FORM_DATA = 'mediaManagementFormData';
export const CATEGORY_API = `${BASE_URL}/media/category/all`;
export const SUBCATEGORY_API = `${BASE_URL}/media/subcategory/all`;
export const INNER_SUBCATEGORY_API = `${BASE_URL}/media/innerSubCategory/all`;
export const ADD_MEDIA_CATEGORY_API = `${BASE_URL}/media/category/add`;
export const UPDATE_MEDIA_CATEGORY_API = `${BASE_URL}/media/category/update`;
export const ADD_MEDIA_SUBCATEGORY_API = `${BASE_URL}/media/subcategory/add`;
export const UPDATE_MEDIA_SUBCATEGORY_API = `${BASE_URL}/media/subcategory/update`;
export const ADD_MEDIA_INNER_SUBCATEGORY_API = `${BASE_URL}/media/innerSubCategory/add`;
export const UPDATE_MEDIA_INNER_SUBCATEGORY_API = `${BASE_URL}/media/innerSubCategory/update`;

export const CREATORS_API = `${BASE_URL}/admin/getContentCreators`;

export const CATEGORIES = 'categories';
export const SUBCATEGORIES = 'subcategories';
export const INNER_SUBCATEGORIES = 'innersubcategories';
export const CATEGORY_ITEM_ID = 'categoryItemId';
export const MEDIA_ID = 'mediaId';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const FORM_DATA = 'categoryFormData';
export const SUBCATEGORY_FORM_DATA = 'subCategoryFormData';
export const CONTENT_CREATORS = 'contentCreators';

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [MEDIA_MANAGEMENT_FORM_DATA]: {},
  [CATEGORIES]: [],
  [SUBCATEGORIES]: [],
  [INNER_SUBCATEGORIES]: [],
  [CATEGORY_ITEM_ID]: '',
  [MEDIA_ID]: '',
  [AUTHENTICATED_SESSION]: true,
  [FORM_DATA]: {},
  [SUBCATEGORY_FORM_DATA]: {},
  [CONTENT_CREATORS]: [],
};

export const creatorsTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header: 'First Name',
      accessor: 'firstname',
    },
    {
      Header: 'Last Name',
      accessor: 'lastname',
    },
    {
      Header: 'Business Name',
      accessor: (row, i) => row.businessName || '---',
      id: 'businessName'
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber'
    },
    {
      Header: 'Status',
      type: 'static'
    },
    // {
    //   Header: 'Action',
    // },
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};


export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
