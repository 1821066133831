import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as mediaManagementSelectors from '../../ducks/product-category/selectors';
import { AddSubCategoryForm } from './components';
import {
  getInnerSubCategories,
  addInnerSubCategory,
  updateSubCategory,
  updateInnersubCategoryFormData,
} from '../../ducks/product-category';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';

import { innerSubcategoryTableConfig } from '../../ducks/product-category/constants';
import CustomTable from '../../components/table';

const ProductInnerSubCategory = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);

  // Toggle for Modal
  const toggle = (data) => {
    const {
      actions
    } = props;
    if (data.type == 'click') {
      actions.updateInnersubCategoryFormData({});
    } else {
      actions.updateInnersubCategoryFormData(data);
    }

    setModal(!modal)
  }

  const submitData = () => setModal(!modal);
  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getInnerSubCategories();
  },[]);

  const handleSubmitClick = (values) => {
    const {
      actions
    } = props;
    setModal(false);
    actions.addInnerSubCategory(values);
  }

  const {
    authenticatedSession,
    data,
    innersubcategories,
    innerSubCategoryFormData,
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content category">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4 className="category-header-label">Inner Sub Category</h4>
                    <Button color="primary" onClick={toggle} className="category-header-button">Add Inner Subcategory</Button>
                    <div>
                      <Modal isOpen={modal} className="category">
                        <ModalHeader
                          close={<FontAwesomeIcon onClick={toggle} icon={faTimes}></FontAwesomeIcon>}
                        >
                          {`${innerSubCategoryFormData.productCategoryItemId ? 'Update' : 'Add'} Inner Subcategory`}
                        </ModalHeader>
                        <ModalBody>
                          <AddSubCategoryForm {...props}
                           handleSubmit={handleSubmitClick} />
                        </ModalBody>
                      </Modal>
                    </div>
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="media-category">
                      {/* mediaTableConfig */}
                      <CustomTable
                      data={innersubcategories}
                      onEditClick={toggle}
                      options = {innerSubcategoryTableConfig.options}
                      columns={innerSubcategoryTableConfig.columns} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </main>
    </>
  )
}

ProductInnerSubCategory.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape()),
};

ProductInnerSubCategory.defaultProps = {
  categories: [],
};

const mapStateToProps = state => ({
  ...state.productCategory,
  data: mediaManagementSelectors.dataSelector(state),
  categories: mediaManagementSelectors.categoriesSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getInnerSubCategories,
    addInnerSubCategory,
    updateSubCategory,
    updateInnersubCategoryFormData,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInnerSubCategory);
