import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Form as ReactstrapForm, CardBody, Row, CardFooter, Col, Button, Label
} from 'reactstrap';
import FormInput from './formInput';
import CustomDropdown from './dropdown';

const AddSubCategoryForm = (props) => {

  const onSubmit = values => {
    const {
      handleSubmit
    } = props;
    handleSubmit(values);
  }

  const validatePage= values => {
    const errors = {}
    if (!values.category || values.category === 'Select') {
      errors.category = 'Category is Required';
    }

    if (!values.subcategory) {
      errors.subcategory = 'Subcategory is Required';
    }
    

    return errors;
  }

  const getCategoryId = (name) => {
    const {
      categories,
    } =props;

    let categoryId = '';
    for(let i = 0; i < categories.length; i++) {
      if(categories[i].name == name) {
        categoryId = categories[i].id;
        break;
      }
    }
    return categoryId;
  }

  const {
    categories,
    subCategoryFormData,
  } =props;
  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validatePage}
        initialValues={{subcategory: subCategoryFormData.name, category: subCategoryFormData.productCategory &&  subCategoryFormData.productCategory.id}}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
        <ReactstrapForm onSubmit={handleSubmit} className="form theme-form">
          <CardBody>
            <Row>
              <Col>
                <Field name="category" data={categories} label={"Category"}>
                  {props => (<CustomDropdown {...props} />)}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col md="mb-3">
                <Label htmlFor='subcategory'>Subcategory</Label>
                <Field name="subcategory" placeholder="Enter Subcategory">
                  {props => <FormInput {...props} />}
                </Field>
              </Col>
            </Row>
          </CardBody>
          <div className="form-footer">
            <Button type="submit" color="primary">{subCategoryFormData.subCategoryId ? 'Update' : 'Add'}</Button>
          </div>
        </ReactstrapForm>
      )}
    />
    </>
  )
};

export default AddSubCategoryForm;