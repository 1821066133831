import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import {Container,Row,Col,Card,CardBody,CardHeader , Button, Label} from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import { Sidebar } from '..';
import Header from '../header';
import {getMerchants} from '../../ducks/merchants/index';
import {getProductMappingReport} from '../../ducks/sales/index';
import { productMapReportTableConfig } from '../../ducks/sales/constants';
import CustomTable from '../../components/table';
import AuthContext from '../authProvider';
import Loader from '../../components/loader';
import './index.scss';


const ProductMappingReportComponent = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const { actions } = props;
    actions.getMerchants();
  },[]);

  const {
    authenticatedSession,
    actions,
    merchantList, 
    productMappingData
  } = props;

  const { getProductMappingReport } = actions;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [merchantSelected, setMerchantSelected] = useState(null)

  const getFormattedDate =(date)=>{
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
  }

  // let totalPrices;
  // let saleDates;
  // let sumBySaleDate = {};
  // if(productMappingData){
  //   productMappingData.forEach((sale) => {
  //     const saleDate = sale.saleDate;
  //     const totalPrice = parseFloat(sale.totalPrice);
    
  //     // Check if the saleDate already exists in the sumBySaleDate object
  //     if (sumBySaleDate[saleDate]) {
  //       sumBySaleDate[saleDate] += totalPrice;
  //     } else {
  //       sumBySaleDate[saleDate] = totalPrice;
  //     }
  //   });
    
  //   // Extract unique saleDates and their corresponding summed total prices
  //   saleDates = Object.keys(sumBySaleDate);
  //   saleDates.sort((a, b) => new Date(b) - new Date(a));

  //   totalPrices = saleDates.map((date) => sumBySaleDate[date]);
  //   // console.log("Sale Dates:", saleDates);
  //   // console.log("Total Prices:", totalPrices);
  // }




  // CSV GENERATION
  function generateCSV(data) {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map((row) =>
      Object.values(row)
        .map((value) => `"${value}"`)
        .join(',')
    );
    return [header, ...rows].join('\n');
  }

  function downloadCSV(data) {
    const csvContent = generateCSV(data);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Product Mapped Data.csv';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content product">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                      <div className='sales-date-range-merchant-wrapper'>
                        <Label htmlFor="date-range">{`Merchants`}</Label>

                        <Form.Select onChange={(event)=>{setMerchantSelected(event.target.value)}} aria-label="Default select example">
                        {
                          !_.isEmpty(merchantList) && merchantList.map(
                            (merchantItem, index)=> <option key={index} value={merchantItem.merchantId}>{merchantItem.store_name}</option>)
                        }
                        <option selected disabled>Select Merchant</option>
                        </Form.Select>
                        <Label htmlFor="date-range">{`Date Range`}</Label>

                        <DatePicker className='form-control'
                              id={'startDateId'}
                              selected={startDate} onChange={(date) => {
                                // if(endDate) setEndDate(null);
                                setStartDate(date)
                                setEndDate(new Date(new Date(date).setDate(date.getDate()+30))); 
                              } }
                              placeholderText='Start Date'
                              selectsStart
                              dateFormat="dd/MM/yyyy"
                            />

                        <DatePicker className='form-control'
                            id={'endDateId'}
                            selected={endDate ? endDate : startDate} onChange={(date) => setEndDate(date)}
                            placeholderText='End Date'
                            selectsEnd
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            maxDate={ startDate ? new Date(startDate).setDate(startDate.getDate()+30) : null }
                            />

                        <Button variant="info" className='get-sales-button' onClick={
                          ()=>{
                            if(!_.isEmpty(merchantSelected) && startDate){
                              getProductMappingReport(
                                {
                                  merchantId:merchantSelected,
                                  startDate:getFormattedDate(startDate),
                                  endDate:getFormattedDate(endDate)
                                }
                              )
                            }
                          }
                        }>Get Report</Button>
                        <Button onClick={()=>{
                          
                          downloadCSV(productMappingData.map((productMapDataItem, index)=>{
                              let tempSale = {};
                              // tempSale.serial = index+1;
                              tempSale.reels_id = productMapDataItem.reels_id;
                              tempSale.created_at = productMapDataItem.created_at;
                              tempSale.product_mapped_at = productMapDataItem.product_mapped_at;
                              tempSale.title = productMapDataItem.title;
                              tempSale.sku = productMapDataItem.sku;
                              tempSale.productName = productMapDataItem.productname;
                              tempSale.brand = productMapDataItem.brand;
                              tempSale.videopath = productMapDataItem.videopath;
                              tempSale.imagepath = productMapDataItem.imagepath;
                              tempSale.redirecturl = productMapDataItem.redirecturl;
                              tempSale.price = productMapDataItem.price;
                              return tempSale;
                            })  )}}> Download CSV</Button>
                      </div>

                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="reels">

                      {/* Listing Component */}
                      <CustomTable
                      data={productMappingData ?  productMappingData: []}
                      options = {productMapReportTableConfig.options}
                      columns={productMapReportTableConfig.columns} 
                      />


                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

// ProductMappingReportComponent.propTypes = {
//   reels: PropTypes.arrayOf(PropTypes.shape()),
// };

// ProductMappingReportComponent.defaultProps = {
//   reels: [],
// };

const mapStateToProps = state => ({
  ...state.reelsList,
  merchantList: state.merchants.merchants,
  productMappingData:state.sales.productMappingData
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getMerchants,
    getProductMappingReport
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductMappingReportComponent);
