import React from 'react';
import {
  Input,
  Button
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTimes, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { GetStatusLocalizeText } from './../../../utils';

export default function ColumnFilter(props) {

  const { filterValue, setFilter } = props;
  return (
    <div>
      <select className="form-control" value={filterValue || ''} placeholder="Search.." onChange={(e) => setFilter(e.target.value == 'SELECT' ? '' : e.target.value)}>
        <option value={''}>SELECT</option>
        <option value={'CREATED'}>{GetStatusLocalizeText('CREATED')}</option>
        <option value={'ACCEPTED'}>{GetStatusLocalizeText('ACCEPTED')}</option>
        <option value={'ORDERED'}>{GetStatusLocalizeText('ORDERED')}</option>
        <option value={'DELIVERED'}>{GetStatusLocalizeText('DELIVERED')}</option>
        <option value={'CANCELLED'}>{GetStatusLocalizeText('CANCELLED')}</option>
        <option value={'RETURNED'}>{GetStatusLocalizeText('RETURNED')}</option>
        <option value={'OUT_FOR_DELIVERY'}>{GetStatusLocalizeText('OUT_FOR_DELIVERY')}</option>
        <option value={'IN_TRANSIT'}>{GetStatusLocalizeText('IN_TRANSIT')}</option>
        <option value={'FAILED'}>{GetStatusLocalizeText('FAILED')}</option>
      </select>
      {/* <Button onClick={() => setFilter('')}>{'All'}</Button>
      <Button onClick={() => setFilter('CREATED')}>{GetStatusLocalizeText('CREATED')}</Button>
      <Button onClick={() => setFilter('ACCEPTED')}>{GetStatusLocalizeText('ACCEPTED')}</Button>
      <Button onClick={() => setFilter('ORDERED')}>{GetStatusLocalizeText('ORDERED')}</Button>
      <Button onClick={() => setFilter('DELIVERED')}>{GetStatusLocalizeText('DELIVERED')}</Button>
      <Button onClick={() => setFilter('FAILED')}>{GetStatusLocalizeText('FAILED')}</Button> */}

      {(typeof filterValue !== 'undefined') && <FontAwesomeIcon onClick={() => setFilter(undefined)} icon={faTimes}></FontAwesomeIcon>}
      {/* <input type="text" value={filter || ''} onChange={(e) => setFilter(e.target.value)} /> */}
      
    </div>
  )
};
