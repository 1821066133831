import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
export { REDUCER_KEY } from './constants';
import { GetUserToken } from '../../utils';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);


// Store data handling
export const getSalesData = (payload) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));

  axios.post([constants.SALES_DATA_API],payload,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      const salesData = data.responseData;
        dispatch(
          dataChanged({
            [constants.SALES_DATA]: salesData,
            loading: false
          })
        )
      
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status === UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
    });
};
export const getProductMappingReport = (payload) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));

  axios.post([constants.PRODUCT_MAPPING_REPORT_API],payload,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      const productMappingData = data.responseData;
        dispatch(
          dataChanged({
            [constants.PRODUCT_MAPPING_DATA]: productMappingData,
            loading: false
          })
        )
      
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status === UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
    });
};

