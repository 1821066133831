import React from 'react';
import { Row, Col, Button} from 'reactstrap';
import IMAGE_BG from '../../../images/iphone_bg.png';
import { GetImageUrl } from '../../../utils';

const ViewMapProduct = (props) => {
  const {
    productList,
    actions,
    formData,
  } = props;
  const {
    title,
    path,
    products,
    id,
  } = formData;

  const updatedProductsArray = products && products.map((el) => el.productId) || [];
  const handleProductMapping = (event) => {
    event.preventDefault();
    const {
      actions,
    } = props;
    const productId = parseInt(event.target.dataset.id);
    const productsArray = updatedProductsArray.push(productId);
    actions.mapReelToProduct([productsArray], id, productId);
  };

  const handleProductUnMapping = (event) => {
    event.preventDefault();
    const {
      actions,
    } = props;
    const productId = parseInt(event.target.dataset.id);
    updatedProductsArray.splice(updatedProductsArray.indexOf(productId), 1);

    actions.mapReelToProduct(updatedProductsArray, id, productId);
  };

  const urlArray = path && path.split('.');
  const fileExt = urlArray && urlArray.length && urlArray[urlArray.length-1].toLowerCase();
  const list = productList
  .filter(el => 
    updatedProductsArray && updatedProductsArray.indexOf(el.productId) > -1
  )
  .map((el) => {
    const {
      productname,
      tileImageUrl,
      isStockAvailable,
      brand,
      productId,
      discountedPrice,
      price,
      active,
      sku,
    } = el;
    return (
      <>
        <li key={productname} className="thumb-product-list">
        <Row>
          <Col md="4" className="thumb-product-image">
            <img width={100} src={tileImageUrl} />
            <div>

            <Button color="danger" data-id={productId} onClick={handleProductUnMapping}>Unmap</Button>
            </div>
          </Col>
          <Col md="8" className="thumb-product-desc">
            <h4 className="thumb-product-name">{productname}</h4>
            <div className="thumb-product-desc-item">Brand: {brand}</div>
            <div className="thumb-product-desc-item">SKU: {sku}</div>
            <div className="thumb-product-desc-item">Active: <span className={`${active ? 'success' : 'failure'}`}>{active ? 'Active' : 'Inactive'}</span></div>
            <div className="thumb-product-desc-item">Stock: <span className={`${isStockAvailable ? 'success' : 'failure'}`}>{isStockAvailable ? 'In-Stock' : 'Out of Stock'}</span></div>
            <div className="thumb-product-desc-item">Price: <span className="discounted-price">{discountedPrice}</span> <span className="original-price">{price}</span></div>
            
          </Col>
        </Row>
        
        </li>
      </>
    )
  });
  
  return(
    <div className="map-product-detail">
      <h4 className="product-name">{title}</h4>
      <div className="iphone-cover">
      </div>
      <Row>
        <Col md="5" className="product-image">
          <div className="product-media-wrapper">
            <div className="coverimg">
              <img src={IMAGE_BG} alt="" />
            </div>
            {
              (fileExt === 'mp4' || fileExt === 'webm')
              ?
              (
                <video autoplay="autoplay">
                  <source src={path && GetImageUrl(path)}/>
                </video>
              )
              :
              (
                <img src={path && GetImageUrl(path)} />
              )
            }
          </div>
        </Col>
        <Col md="7">
          <ul>
            {
              (list.length > 0)
              ?
              <>
                {list}
              </>
              :
              <div className="no-product-mapped">
                No Products have been mapped to this reel.
              </div>
            }
          </ul>
        </Col>
      </Row>
    </div>
  )
}

export default ViewMapProduct;
