import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import {Container,Row,Col,Card,CardBody,CardHeader , Button, Label} from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


import { Sidebar } from '..';
import Header from '../header';
import {getMerchants} from '../../ducks/merchants/index';
import {getSalesData} from '../../ducks/sales/index';
import { salesTableConfig } from '../../ducks/sales/constants';
import CustomTable from '../../components/table';
import AuthContext from '../authProvider';
import Loader from '../../components/loader';
import './index.scss';

ChartJS.register( LinearScale,CategoryScale,BarElement, Title, Tooltip );

const SalesPageComponent = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    const { actions } = props;
    actions.getMerchants();
  },[]);

  const {
    authenticatedSession,
    actions,
    merchantList, 
    salesData
  } = props;

  const { getSalesData } = actions;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [merchantSelected, setMerchantSelected] = useState(null)

  const getFormattedDate =(date)=>{
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
  }

  let totalPrices;
  let saleDates;
  let sumBySaleDate = {};

  if(salesData){
    salesData.forEach((sale) => {
      const saleDate = sale.saleDate;
      const totalPrice = parseFloat(sale.totalPrice);
    
      // Check if the saleDate already exists in the sumBySaleDate object
      if (sumBySaleDate[saleDate]) {
        sumBySaleDate[saleDate] += totalPrice;
      } else {
        sumBySaleDate[saleDate] = totalPrice;
      }
    });
    
    // Extract unique saleDates and their corresponding summed total prices
    saleDates = Object.keys(sumBySaleDate);
    saleDates.sort((a, b) => new Date(b) - new Date(a));

    totalPrices = saleDates.map((date) => sumBySaleDate[date]);
    // console.log("Sale Dates:", saleDates);
    // console.log("Total Prices:", totalPrices);
  }


  const chartData = {
    labels: saleDates,
    datasets: [
      {
        label: 'Sales',
        backgroundColor: '#3f98c2',
        borderWidth: 1,
        hoverBackgroundColor: '#1d4e65',
        data: totalPrices, 
        // data: [{id: 'Sales', nested: {value: 1500}}, {id: 'Purchases', nested: {value: 500}}], 
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    // parsing: {
    //   xAxisKey: 'saleDate',
    //   yAxisKey: 'totalPrice'
    // },
    // normalized:true
  };


  // CSV GENERATION
  function generateCSV(data) {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map((row) =>
      Object.values(row)
        .map((value) => `"${value}"`)
        .join(',')
    );
    return [header, ...rows].join('\n');
  }

  function downloadCSV(data) {
    const csvContent = generateCSV(data);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content product">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                      <div className='sales-date-range-merchant-wrapper'>
                        <Label htmlFor="date-range">{`Merchants`}</Label>

                        <Form.Select onChange={(event)=>{setMerchantSelected(event.target.value)}} aria-label="Default select example">
                        {
                          !_.isEmpty(merchantList) && merchantList.map(
                            (merchantItem, index)=> <option key={index} value={merchantItem.merchantId}>{merchantItem.store_name}</option>)
                        }
                        <option selected disabled>Select Merchant</option>
                        </Form.Select>
                        <Label htmlFor="date-range">{`Date Range`}</Label>

                        <DatePicker className='form-control'
                              id={'startDateId'}
                              selected={startDate} onChange={(date) => {
                                // if(endDate) setEndDate(null);
                                setStartDate(date)
                                setEndDate(new Date(new Date(date).setDate(date.getDate()+30))); 
                              } }
                              placeholderText='Start Date'
                              selectsStart
                              dateFormat="dd/MM/yyyy"
                            />

                        <DatePicker className='form-control'
                            id={'endDateId'}
                            selected={endDate ? endDate : startDate} onChange={(date) => setEndDate(date)}
                            placeholderText='End Date'
                            selectsEnd
                            dateFormat="dd/MM/yyyy"
                            minDate={startDate}
                            maxDate={ startDate ? new Date(startDate).setDate(startDate.getDate()+30) : null }
                            />

                        <Button variant="info" className='get-sales-button' onClick={
                          ()=>{
                            if(!_.isEmpty(merchantSelected) && startDate){
                              getSalesData(
                                {
                                  merchantId:merchantSelected,
                                  startDate:getFormattedDate(startDate),
                                  endDate:getFormattedDate(endDate)
                                }
                              )
                            }
                          }
                        }>Get Sales</Button>
                        <Button onClick={()=>{
                          
                          downloadCSV(salesData.map((sale, index)=>{
                              let tempSale = {}
                              tempSale.serial = index+1;
                              tempSale.productName = sale.product.productname;
                              tempSale.brand = sale.product.brand;
                              tempSale.sku = sale.sku;
                              tempSale.createdDate = sale.product.created_at;
                              tempSale.mappedDate = sale.taggedDate;
                              tempSale.saleDate = sale.saleDate;
                              tempSale.quantity = sale.quantity;
                              tempSale.price = sale.totalPrice;
                              return tempSale;
                            })  )}}> Download CSV</Button>
                      </div>
                      {
                        !_.isEmpty(salesData) &&

                      <Bar options={options} data={chartData} />
                      }
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="reels">

                      {/* Listing Component */}
                      <CustomTable
                      data={salesData ?  salesData: []}
                      options = {salesTableConfig.options}
                      columns={salesTableConfig.columns} 
                      />


                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

// SalesPageComponent.propTypes = {
//   reels: PropTypes.arrayOf(PropTypes.shape()),
// };

// SalesPageComponent.defaultProps = {
//   reels: [],
// };

const mapStateToProps = state => ({
  ...state.reelsList,
  merchantList: state.merchants.merchants,
  salesData:state.sales.salesData
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getMerchants,
    getSalesData
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesPageComponent);
