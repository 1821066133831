import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import {Container,Row,Col,Card,CardBody,CardHeader,
  Button, Form as ReactstrapForm,Label,Input,Progress,
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';

import { Form, Field } from 'react-final-form';
import {
  CustomDropdown,
  FormInput,
  MediaItemDropdown,
} from './components'
import * as mediaManagementSelectors from '../../ducks/ad-management/selectors';
import Wizard from './wizardForm';
import {
  getCategoies,
  createMedia,
  uploadAudio,
} from '../../ducks/ad-management/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';

import { language } from '../../config';

const AdManagement = (props) => {
  const [category, setCategory] = useState('');
  const [videopercentage, setVideoPercentage] = useState(0);
  const [audiopercentage, setAudioPercentage] = useState(0);
  const [imagepercentage, setImagePercentage] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [mediaCategoryItems, setMediaCategoryItems] = useState([]);
  const [videoFile, setvideoFile] = useState(null);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const {
    categories,
    imageData,
    videoData,
    audioData,
    imageUploading,
    audioUploading,
    videoUploading,
  } = props;

  // call category and subcategories api to load the data
  // useEffect(() => {
  //   const {
  //     actions,
  //   } = props;
  //   actions.getCategoies();
  // },[])

  const submitData = (formData) => {
    // console.log('media form data: ', {...formData});
  }

  const onSubmit = (values, page) => {
    // window.alert(JSON.stringify(values, 0, 2));
    const {
      actions
    } = props;

    // page 0 will call meta api
    const audio = document.getElementsByClassName('audio')[0].files[0];
    if(page == 0) {
      // console.log('call create media', props);
      actions.uploadAudio(values, updateAudioUploadPercentage, audio);
    }
    // // page 2 will redirect to media list page
    // if(page == 1) {
    //   // callbackFn(values, callbackFn);
    //   navigate('/media-list');
    // }
  }

  const firstvalidatePage= (values, video) => {
    const errors = {}

    if(!values.title) {
      errors.title = 'Title is required';
    }

    if (!values.audio) {
      errors.audio = 'Audio is Required';
    }

    if(!values.product_image_url) {
      errors.product_image_url = 'Product Image URL is required';
    }

    if(!values.product_name) {
      errors.product_name = 'Product Name is required';
    }

    if(!values.timestamp) {
      errors.timestamp = 'Timestamp is required';
    }

    if(!values.redirect_url) {
      errors.redirect_url = 'Redirect URL is required';
    }

    if(!values.merchant_name) {
      errors.merchant_name = 'Merchant Name is required';
    }

    if(!values.merchant_logo) {
      errors.merchant_logo = 'Merchant Logo URL is required';
    }

    // merchant_logo
    // merchant_name
    // redirect_url
    // timestamp
    // product_name
    // product_image_url
    

    // update sub category dropdown
    if (values.category) {
      setCategory(values.category);
      const scIndex = categories.findIndex((el) => el.name == values.category);
      setSubCategories(categories[scIndex].subCategory);
    }
  

    return errors;
  }




  const updateAudioUploadPercentage = (value) => {
    setAudioPercentage(value);
  }



  const handleAudioUpload = (e) => {
    const {
      actions,
      mediaId,
    } = props;
    e.preventDefault();
    // console.log(videoFile);
    const audio = document.getElementsByClassName('audio')[0].files[0];
    //validate
    if (!audio) {
      window.alert('Please select a Audio file!');
    } else {
      // let video = videoFile.video;
      // don't upload if file size if greater than 5 MB.
      // if (picture.size / (1024 * 1024) > 5) {
      //   window.alert('Please size should not exceed more than 5 MB!');
      // }
      // else if (picture.name.substr(picture.name.length - 4) !== 'jpeg' || picture.name.substr(picture.name.length - 3) !== 'jpg') {
      //   window.alert('Please upload jpg files only!');
      // } else {
      actions.uploadAudio(audio, mediaId, updateAudioUploadPercentage);
      // }
      // reset audio form value
      document.getElementsByClassName('audio')[0].value = null;

    }
  }

  const handleAudioFileChange = e => {
    const target = e.target;
    // console.log('file', target.files[0]);
  }
  const {
    authenticatedSession,
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content">
        <div className="profile-cover bg-dark"></div>
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4>{"Add Advertisement"}</h4>
                  </CardHeader>
                  <Wizard
                  initialValues={{test: 'main'}}
                  onSubmit={onSubmit}>
                    
                    <Wizard.Page
                      validate={firstvalidatePage}>
                      <CardBody>
                        <Row>
                          <Col md="6 mb-3">
                            <Label htmlFor="audio">{"Upload Audio"}</Label>
                            <Field name="audio">
                              {props => (
                                <>
                                  <Input
                                    className="form-control audio"
                                    name={props.input.name}
                                    onChange={props.input.onChange}
                                    // onChange={(e)=>handleAudioFileChange(e)}
                                    type="file" />
                                  {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                                    <span className="error-text">{props.meta.error || props.meta.submitError}</span>
                                  )}
                                </>
                              )}
                            </Field>
                            <br></br>
                            <Progress animated value={audiopercentage} />
                          </Col>
                          <Col md="6 mb-3">
                            <Label htmlFor="title">{"Audio Title"}</Label>
                            <Field name="title" placeholder="Enter Audio Title">
                              {props => (
                                <FormInput {...props} />
                              )}
                            </Field>
                          </Col>
                          <Col md="6 mb-3 upload-btn">
                            {/* <Button  color="primary" disabled={audioUploading} onClick={handleAudioUpload} className="mr-1">{"Upload"}</Button> */}
                          </Col>
                        </Row>
                        {/* <Row>
                          <span>Audio size should not exceed 10 MB and only .mp3</span>
                        </Row> */}
                        {/* percentage bar */}
                        <Row>
                          <Col md="6 mb-3">
                            <Label htmlFor="merchant_name">{"Merchant Name"}</Label>
                            <Field name="merchant_name" placeholder="Enter Merchant Name">
                              {props => (
                                <FormInput {...props} />
                              )}
                            </Field>
                          </Col>
                          <Col md="6 mb-3">
                            <Label htmlFor="merchant_logo">{"Merchant Logo URL"}</Label>
                            <Field name="merchant_logo" placeholder="Enter Merchant Logo">
                              {props => (
                                <FormInput {...props} />
                              )}
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            <Label htmlFor="product_name">{"Product Name"}</Label>
                            <Field name="product_name" placeholder="Enter Product Name">
                              {props => (
                                <FormInput {...props} />
                              )}
                            </Field>
                          </Col>
                          <Col md="6 mb-3">
                            <Label htmlFor="product_image_url">{"Product Image URL"}</Label>
                            <Field name="product_image_url" placeholder="Enter Product Image URL">
                              {props => (
                                <FormInput {...props} />
                              )}
                            </Field>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            <Label htmlFor="redirect_url">{"Redirect URL"}</Label>
                            <Field name="redirect_url" placeholder="Enter Redirect URL">
                              {props => (
                                <FormInput {...props} />
                              )}
                            </Field>
                          </Col>
                          <Col md="6 mb-3">
                            <Label htmlFor="timestamp">{"Timestamp"}</Label>
                            <Field name="timestamp" placeholder="Enter Timestamp">
                              {props => (
                                <FormInput {...props} />
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </CardBody>
                    </Wizard.Page>
                    
                  </Wizard>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </main>
    </>
  )
}

AdManagement.propTypes = {
  categories: PropTypes.arrayOf(),
};

AdManagement.defaultProps = {
  categories: [],
};

const mapStateToProps = state => ({
  ...state.mediaManagement,
  data: mediaManagementSelectors.dataSelector(state),
  categories: mediaManagementSelectors.categoriesSelector(state),
  mediaData: mediaManagementSelectors.mediaDataSelector(state),
  imageData: mediaManagementSelectors.imageDataSelector(state),
  videoData: mediaManagementSelectors.videoDataSelector(state),
  audioData: mediaManagementSelectors.audioDataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getCategoies,
    createMedia,
    uploadAudio,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdManagement);
