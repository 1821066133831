import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as creatorsSelectors from '../../ducks/content-creators/selectors';
import { AddSubCategoryForm } from './components';
import {
  getCreators,
  // updatesubCategoryFormData,
} from '../../ducks/content-creators/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';

import { creatorsTableConfig } from '../../ducks/content-creators/constants';
import CustomTable from '../../components/table';

const ContentCreators = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);

  // Toggle for Modal
  // const toggle = (data) => {
  //   const {
  //     actions
  //   } = props;
  //   if (data.type == 'click') {
  //     actions.updatesubCategoryFormData({});
  //   } else {
  //     actions.updatesubCategoryFormData(data);
  //   }

  //   setModal(!modal)
  // }

  const submitData = () => setModal(!modal);
  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getCreators();
  },[]);

  const {
    authenticatedSession,
    data,
    contentCreators,
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content category">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4 className="category-header-label">Content Creators</h4>
                    {/* <div>
                      <Modal isOpen={modal} className="category">
                        <ModalHeader
                          close={<FontAwesomeIcon onClick={toggle} icon={faTimes}></FontAwesomeIcon>}
                        >
                          Add Subcategory
                        </ModalHeader>
                        <ModalBody>
                          <AddSubCategoryForm {...props} />
                        </ModalBody>
                      </Modal>
                    </div> */}
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="content-creators">
                      {/* mediaTableConfig */}
                      <CustomTable
                      data={contentCreators}
                      // onViewClick={toggle}
                      options = {creatorsTableConfig.options}
                      columns={creatorsTableConfig.columns} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </main>
    </>
  )
}

ContentCreators.propTypes = {
  merchants: PropTypes.arrayOf(PropTypes.shape()),
};

ContentCreators.defaultProps = {
  merchants: [],
};

const mapStateToProps = state => ({
  ...state.contentCreators,
  data: creatorsSelectors.dataSelector(state),
  categories: creatorsSelectors.categoriesSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getCreators,
    // updatesubCategoryFormData,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentCreators);
