export const ACTION_PREFIX = 'mediaManagement';

const DATA_LOADING = `${ACTION_PREFIX}/loading`;
const DATA_LOAD_SUCCESS = `${ACTION_PREFIX}/load/success`;
const DATA_LOAD_ERROR = `${ACTION_PREFIX}/load/error`;
const DATA_CHANGED = `${ACTION_PREFIX}/data/change`;

export default {
  DATA_LOADING,
  DATA_LOAD_ERROR,
  DATA_LOAD_SUCCESS,
  DATA_CHANGED,
};
