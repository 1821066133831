import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Form as ReactstrapForm, CardBody, Row, Input, Label, Col, Button
} from 'reactstrap';
import FormInput from './formInput';
import CustomDropdown from './dropdown';
import { trackingArrayData } from './../../../config';

const AddTrackingDetails = (props) => {
  const onSubmit = values => {
    const {
      handleSubmit,
      orderDetails1,
      trackFormToggle,
      orderId,
    } = props;
    trackFormToggle();
    handleSubmit(values, orderDetails1, orderId);
  }

  const validatePage= values => {
    const errors = {}
    if (!values.trackingType) {
      errors.trackingType = 'Type is Required';
    }

    if (!values.trackingstate) {
      errors.trackingstate = 'Notes is Required';
    }
    

    return errors;
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validatePage}
        // initialValues={{subcategory: subCategoryFormData.name, category: getCategoryId(subCategoryFormData.parentCategory)}}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
        <ReactstrapForm onSubmit={handleSubmit} className="form theme-form">
          <CardBody>
            <Row>
              <Col>
                <Field name="trackingType" data={trackingArrayData} label={"Type"}>
                  {props => (<CustomDropdown {...props} />)}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col md="mb-3">
                <Label htmlFor="images">{"Notes"}</Label>
                <Field name="trackingstate" placeholder="Enter Notes">
                  {props => (
                    <>
                      <Input
                      className={`form-control ${props.input.name}`}
                      name={props.input.name}
                      placeholder={props.placeholder}
                      onChange={props.input.onChange}
                      type="textarea" />
                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
                      )}
                    </>
                  )}
                </Field>
              </Col>
            </Row>
          </CardBody>
          <div className="form-footer">
            <Button type="submit" color="primary">{'Add'}</Button>
            {/* <Button type="submit" color="primary">{subCategoryFormData.id ? 'Update' : 'Add'}</Button> */}
          </div>
        </ReactstrapForm>
      )}
    />
    </>
  )
};

export default AddTrackingDetails;