import React, { Fragment, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Container,Row,Col,Card,CardBody,CardHeader,Table} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDisplay, faMusic, faVideo, faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import * as dashboardSelectors from '../../ducks/dashboard/selectors';
import { dashboardTable } from '../../config';
import Breadcrumbs from './../../components/breadcrumbs';
import TableList from './tableList';
import CustomTable from '../../components/table';
import { tableConfig } from '../../ducks/dashboard/constants';
import {
  getMediaList,
} from '../../ducks/dashboard/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import "./dashboard.scss";

const Dashboard = (props) => {
const auth = useContext(AuthContext);
const navigate = useNavigate();
useEffect(() => {
  const {
    actions,
  } = props;
  const { getData } = actions;
  getData();
}, []);

  const {
    data,
    authenticatedSession
  } = props;
  // if(!authenticatedSession) {
  //   auth.setUserDataToNull();
  //   navigate('/', {replace: true});
  // }
  const updatedData = data.recentItems || [];// totalMediaCount
  return (
    <>
    <Sidebar />
    <Header />
      <main className="page-content">
      <div className="profile-cover bg-dark"></div>

        <div className="page-breadcrumb d-none d-sm-flex align-items-center">
          <div className="ps-3">
            <Breadcrumbs title={"Dashboard"} />
          </div>
        </div>
        {/* <div className="profile-cover bg-dark"></div> */}
        <Container>
          <Row>
            {/* <Col xl="3" lg="3" md="3" className="box-col-3"> */}
            <Col className="box-col-3">
              <Card className="o-hidden">
                <CardBody>
                  <div className="ecommerce-widgets media">
                    <div className="media-body">
                      <p className="f-w-500 font-roboto">Total Media</p>
                      <h4 className="f-w-500 mb-0 f-26"><span className="counter"><CountUp  end={data.totalMedia || 0} /></span></h4>
                    </div>
                    <div className="ecommerce-box light-bg-primary"><FontAwesomeIcon icon={faPhotoFilm}></FontAwesomeIcon></div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
            <Card className="o-hidden">
                <CardBody>
                  <div className="ecommerce-widgets media">
                    <div className="media-body">
                      <p className="f-w-500 font-roboto">Total Content Creators</p>
                      <h4 className="f-w-500 mb-0 f-26"><span className="counter"><CountUp  end={data.totalContentCreator || 0} /></span></h4>
                    </div>
                    <div className="ecommerce-box light-bg-primary"><FontAwesomeIcon icon={faVideo}></FontAwesomeIcon></div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
            <Card className="o-hidden">
                <CardBody>
                  <div className="ecommerce-widgets media">
                    <div className="media-body">
                      <p className="f-w-500 font-roboto">Total Merchants</p>
                      <h4 className="f-w-500 mb-0 f-26"><span className="counter"><CountUp  end={data.totalMerchant || 0} /></span></h4>
                    </div>
                    <div className="ecommerce-box light-bg-primary"><FontAwesomeIcon icon={faMusic}></FontAwesomeIcon></div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col>
            <Card className="o-hidden">
                <CardBody>
                  <div className="ecommerce-widgets media">
                    <div className="media-body">
                      <p className="f-w-500 font-roboto">Total Products</p>
                      <h4 className="f-w-500 mb-0 f-26"><span className="counter"><CountUp  end={data.totalProducts || 0} /></span></h4>
                    </div>
                    <div className="ecommerce-box light-bg-primary"><FontAwesomeIcon icon={faDisplay}></FontAwesomeIcon></div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="best-seller-table responsive-tbl">
                    <div className="item">
                      <div className="table-responsive product-list dashboard">
                        <CustomTable
                        data={updatedData}
                        options = {tableConfig.options}
                        columns={tableConfig.columns} />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

Dashboard.propTypes = {
  username: PropTypes.string,
}
Dashboard.defaultProps = {
  username: '',
}

const mapStateToProps = state => ({
  ...state.dashboard,
  data: dashboardSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getData: getMediaList,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
