import React, { useMemo, useState } from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faShuffle, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import GlobalFilter from './components/globalFilter';
import ColumnFilter from './components/columnFilter';
import {Table, Button,  Row,Col,FormGroup,Label,Input,Pagination,PaginationItem,PaginationLink} from 'reactstrap';
import DEFAULT_IMAGE_THUMBNAIL from '../../images/media_logo1.jpeg';
import { PUBLIC_IMAGE_URL } from '../../config';
import { GetStatusLocalizeText, GetUserToken, GetImageUrl } from './../../utils';
import './index.scss';


// function SelectColumnFilter({
//   column: { filterValue, setFilter, preFilteredRows, id }
// }) {
//   // Calculate the options for filtering
//   // using the preFilteredRows
//   const options = React.useMemo(() => {
//     const options = new Set();
//     preFilteredRows.forEach((row) => {
//       options.add(row.values[id]);
//     });
//     return [...options.values()];
//   }, [id, preFilteredRows]);

//   // Render a multi-select box
//   return (
//     <select
//       value={filterValue}
//       onChange={(e) => {
//         setFilter(e.target.value || undefined);
//       }}
//     >
//       <option value="">All</option>
//       {options.map((option, i) => (
//         <option key={i} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// }

// // Define a default UI for filtering
// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter
// }) {
//   debugger;
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <span>
//       Search:{" "}
//       <input
//         value={value || ""}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`${count} records...`}
//         style={{
//           fontSize: "1.1rem",
//           border: "0"
//         }}
//       />
//     </span>
//   );
// }

const CustomTable = ({columns, options, data, onEditClick, onDeleteClick, onCheckboxClick,
  onViewClick, onStatusClick, onStockClick, onMapProductClick, searchHandler, searchFunctionality}) => {
  const updatedColumns = useMemo(() => columns, []);
  const updatedData = useMemo(() => data, []);
  // const defaultColumn = React.useMemo(
  //   () => ({
  //     // Let's set up our default Filter UI
  //     Filter: SelectColumnFilter
  //   }),
  //   []
  // );
  const {
    getTableProps,
    headerGroups,
    // rows,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 10 },
    // defaultColumn,
  },
  useFilters, // useFilters!
  useGlobalFilter, // useGlobalFilter!
  usePagination,
  );


  //Search functionality
  const [searchTerm, setSearchTerm] = useState('');
    // Function to handle the search input change
    const handleSearchChange = (event) => {
      const newValue = event.target.value;
      setSearchTerm(newValue);
    };

  return (
    <>
      <Row>
        {options && options.displaySearch && <Col md="12 my-1">
          <FormGroup>
            <div style={{display:"flex",alignItems:"baseline", justifyContent:"space-between"}}>
              <div>
                <Label htmlFor="filterRecords">{`Filter ${data?.length} Records`}</Label>
                {/* <Input type="text" name="seach" className="form-control digits" placeholder="Search..." /> */}
                <GlobalFilter filter={state.globalFilter} setFilter={setGlobalFilter} />
              </div>
              

              {/* SEARCH BAR  */}
              {
                searchFunctionality && 
                <div style={{display:"flex", alignItems:"flex-end"}}>
                  <div>
                    <Label htmlFor="search">{`Search Data`}</Label>

                    {/* SEARCH INPUT FIELD*/}
                    <Input className="form-control" 
                      value={  (()=> {
                        if(searchTerm){
                          return searchTerm
                        }else if(searchFunctionality?.isSearchState && searchFunctionality?.searchTextState ){
                          return searchFunctionality.searchTextState;
                        }
                          return searchTerm;
                        })() } placeholder="Search..." onChange={handleSearchChange} />
                  </div>

                  <Button style={{marginLeft:"10px"}} color="success" onClick={()=>searchFunctionality.searchHandler(searchTerm)} >Search</Button>
                  {
                    // SEARCH CLEAR BUTTON
                    searchFunctionality?.searchClear  && searchFunctionality?.isSearchState &&
                      <Button style={{marginLeft:"10px"}} color="secondary" 
                      onClick={()=>{
                        setSearchTerm('');
                        searchFunctionality.searchClear();
                      }} >Clear</Button>
                  }
                </div>
              }


            </div>
          </FormGroup>
        </Col>}
        <Col md="5"></Col>
      </Row>
      <div className="custom-table" style={{maxHeight: options.maxHeight ? options.maxHeight : '500', overflowY: 'scroll'}}>
      <Table {...getTableProps()}>
        <thead>
          {
            headerGroups.map((headerGroup,i) => (
              <tr key={"header-group-"+i} {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map((column,columnIndex) =>(
                    <th key={columnIndex} {...column.getHeaderProps()}>
                      {column.render('Header')}
                      {
                        options.displayStatusFilter && column.id == 'order_status'
                        ?
                        <div className="filter-order-status">
                        <span>Filter By Status</span>
                        <ColumnFilter {...column} />
                        </div>
                        : ''
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  // special column
                  if (cell.column.id == 'Action') {
                    return (
                      <td key={"cell-"+i} className="media-edit" {...cell.getCellProps()}>
                        {onEditClick && <FontAwesomeIcon onClick={() => onEditClick(row.original)} icon={faPencil}></FontAwesomeIcon>}
                        {onViewClick && <FontAwesomeIcon onClick={() => onViewClick(row.original)} icon={faEye} title="Click to View"></FontAwesomeIcon>}
                        {onDeleteClick && <FontAwesomeIcon onClick={() => onDeleteClick(row.original)} icon={faTrash} title="Click to Delete row"></FontAwesomeIcon>}
                        {onMapProductClick && <FontAwesomeIcon onClick={() => onMapProductClick(row.original)} icon={faShuffle} title="Click to Map Product"></FontAwesomeIcon>}
                        {onCheckboxClick && <Input type='checkbox' checked={ row.original.mapped } onChange={() => onCheckboxClick(row.original)} />}
                        
                      </td>
                    )
                  }
                  if (cell.column.meta && cell.column.meta.video_column) {
                    return (
                      <td key={"cell-"+i} {...cell.getCellProps()}>
                        <div className="align-middle item-detail">
                          <img className="img-40 m-r-15 cover align-top" src={GetImageUrl(row.original.thumbnailUrl) || DEFAULT_IMAGE_THUMBNAIL} alt=""/>
                        </div>
                      </td>
                    )
                  }
                  
                  /**
                   * Song column - tile image
                   */
                  if (cell.column.meta && cell.column.meta.song_column) {
                    return (
                      <td key={"cell-"+i} {...cell.getCellProps()}>
                        <div className="align-middle item-detail">
                          <img className="img-40 m-r-15 cover align-top" src={GetImageUrl(row.original.thumbnailUrl) || DEFAULT_IMAGE_THUMBNAIL} alt=""/>
                        </div>
                      </td>
                    )
                  }
                  /**
                   * Movie column - cover image
                   */
                  if (cell.column.meta && cell.column.meta.movie_column) {
                    return (
                      <td key={"cell-"+i} {...cell.getCellProps()}>
                        <div className="align-middle item-detail">
                          <img className="img-40 m-r-15 cover align-top" src={GetImageUrl(row.original.coverImage) || DEFAULT_IMAGE_THUMBNAIL} alt=""/>
                        </div>
                      </td>
                    )
                  }

                  if (cell.column.type == 'static') {
                    if (cell.column.id == 'order_status') {
                      let status_color = 'green';
                      let orderDetailStatus = cell.value;
                      if(orderDetailStatus === 'FAILED' || orderDetailStatus === 'CANCELLED' || orderDetailStatus === 'RETURNED' || orderDetailStatus === 'REJECTED' || orderDetailStatus === 'RETURN_REQUEST') {
                        status_color = 'red';
                      }
                      return (
                      <td key={"cell-"+i} className="status_column">
                        <span className={`status_dot ${status_color}`}>&nbsp;</span>
                        <span className="text">{GetStatusLocalizeText(cell.value)}</span>
                      </td>
                      )
                    }
                    if(cell.column.id == 'Status') {
                      return (
                        <td key={"cell-"+i} className="media-status" {...cell.getCellProps()}><Button color="success">
                          Active
                          <span style={{display: "none"}}>{cell.render('Cell')}</span>
                          </Button>
                          </td>
                      )
                    } else if (cell.column.id == 'stock') {
                      return (
                        <td key={"cell-"+i} className="stock-status" {...cell.getCellProps()}>
                          <Button color={`${cell.value? 'success' :'danger'}`} onClick={onStockClick ? () => onStockClick(row.original) : () => undefined}>
                            {`${cell.value ? 'In-Stock' :'Out of Stock'}`}
                            {/* <span style={{display: "none"}}>{cell.render('Cell')}</span> */}
                          </Button>
                          </td>
                      )
                    } else {
                      return (
                        <td key={"cell-"+i} className="media-status" {...cell.getCellProps()}>
                          <Button color={`${cell.value? 'success' :'danger'}`} onClick={onStatusClick ? () => onStatusClick(row.original) : () => undefined}>
                            {`${cell.value ? 'Active' :'Inactive'}`}
                            {/* <span style={{display: "none"}}>{cell.render('Cell')}</span> */}
                          </Button>
                          </td>
                      )
                    }
                    
                  }

                  return (
                    <td key={"cell-"+i} {...cell.getCellProps()} style={{width: `${cell.column.meta && cell.column.meta.width ? cell.column.meta.width : ''}`}}>
                      {cell.render('Cell')}
                    </td>
                  )
                  
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
    {/* {options && options.displayPagination &&  */}
    <div className="pagination">
      <Pagination aria-label="..." className="nav-pagination">
        <PaginationItem disabled={!canPreviousPage}><PaginationLink  onClick={() => gotoPage(0)}>{"First"}</PaginationLink></PaginationItem>
        <PaginationItem disabled={!canPreviousPage}><PaginationLink  onClick={() => previousPage()}>{"Previous"}</PaginationLink></PaginationItem>
        <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => nextPage()}>{"Next"}</PaginationLink></PaginationItem>
        <PaginationItem disabled={!canNextPage}><PaginationLink onClick={() => gotoPage(pageCount - 1)}>{"Last"}</PaginationLink></PaginationItem>
      </Pagination>
    </div>
    {/* } */}
    </>
  )
};

export default CustomTable;