export const REDUCER_KEY = 'imagesList';
import { BASE_URL } from './../../config';
export const DATA = 'data';
export const IMAGE_DATA = 'imageData';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const PRODUCTS_API = `${BASE_URL}/product/all?page=1&limit=500`;
export const IMAGES_API = `${BASE_URL}/image/all`;
export const IMAGES_STATUS_UPDATE_API = id => `${BASE_URL}/image/status/${id}`;
export const PRODUCT_STOCK_UPDATE_API = id => `${BASE_URL}/product/updateStockStatus/${id}`;
export const IMAGES_DELETE_API =`${BASE_URL}/image`;
export const DELETE_REEL_MAPPING_API = `${BASE_URL}/image/product/map/`;

export const CATEGORY_ITEM_ID = 'categoryItemId';
export const MEDIA_ID = 'mediaId';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const FORM_DATA = 'formData';
export const IMAGES = 'images';
export const ACTIVE = 'active';
export const REEL = 'testReels';
export const SHOW_DELETE_FORM = 'showDeleteForm';
export const SELECTED_ROW_ID = 'selectedRowId';
export const PRODUCTS_LIST = 'productList';

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [CATEGORY_ITEM_ID]: '',
  [MEDIA_ID]: '',
  [AUTHENTICATED_SESSION]: true,
  [FORM_DATA]: {},
  [IMAGES]: [],
  [ACTIVE]: true,
  [SHOW_DELETE_FORM]: false,
  [SELECTED_ROW_ID]: '',
  [PRODUCTS_LIST]:[],
};

export const ordersTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header: 'Title',
      accessor: (row, i) => row.title || '---',
      id: 'title',
    },
    {
      Header: 'Created',
      accessor: (row, i) => {
        const d= new Date(row.created_at).toString();
        if(d) {
          return d.substring(0, 21);
        } else {
          return '---'
        }
      },
      id: 'brand',
    },
    {
      Header: 'Tags',
      accessor: (row, i) => row.tags && row.tags.join(', ') || '---',
      id: 'tags',
    },
    {
      Header: 'Description',
      accessor: (row, i) => row.description || '---',
      id: 'description'
    },

    {
      Header: 'Status',
      type: 'static',
      accessor: (row) => row.active || false,
      id: 'active'
    },
    {
      Header: 'Action',
    },
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};


export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
