import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Form as ReactstrapForm, CardBody, Row, Input, Label, Col, Button, Progress
} from 'reactstrap';
import FormInput from './formInput';
import CustomDropdown from './dropdown';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { format, isValid, toDate } from "date-fns";

const AddForm = (props) => {
  const [videopercentage, setVideoPercentage] = useState(0);
  const [imagepercentage, setImagePercentage] = useState(0);
  const [locations, setLocations] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  
  const {
    imageUploading,
    videoUploading,
  } = props;

  const onSubmit = values => {
    const {
      handleSubmit
    } = props;
    handleSubmit(values);
  }

  const validatePage= values => {
    const errors = {}
    if (!values.minBidAmount) {
      errors.minBidAmount = 'Minimum Bid Amount is Required';
    }

    if (!values.name) {
      errors.name = 'Name is Required';
    }
    

    return errors;
  }

  const updateVideoUploadPercentage = (value) => {
    setVideoPercentage(value);
  }

  const updateImageUploadPercentage = (value) => {
    setImagePercentage(value);
  }

  const onLocationClick = (e) => {
    e.preventDefault();
    // console.log(form);
    setLocations((oldArray) => {
      return [...oldArray, ['location name', 'location address']]
    })
  }

  const handlePictureUpload = (e) => {
    const {
      actions,
      mediaId,
    } = props;
    e.preventDefault();

    const picture = document.getElementsByClassName('thumbnail')[0].files[0];
    // console.log(picture);
    return;
    //validate
    if (!picture) {
      window.alert('Please select a Cover photo file!');
    } else {
      // let video = videoFile.video;
      // don't upload if file size if greater than 5 MB.
      // if (picture.size / (1024 * 1024) > 5) {
      //   window.alert('Please size should not exceed more than 5 MB!');
      // }
      // else if (picture.name.substr(picture.name.length - 4) !== 'jpeg' || picture.name.substr(picture.name.length - 3) !== 'jpg') {
      //   window.alert('Please upload jpg files only!');
      // } else {
      actions.uploadPicture(picture, mediaId, updateImageUploadPercentage);
      // }

    }
  }

  const {
    name,
    minBidAmount,
    onCancelClick,
  } =props;

  return (
    <>
      <h3>Add Auction</h3>
      <Form
        onSubmit={onSubmit}
        validate={validatePage}
        // initialValues={{subcategory: subCategoryFormData.name, category: getCategoryId(subCategoryFormData.parentCategory)}}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
        <ReactstrapForm onSubmit={handleSubmit} className="form theme-form">
          <CardBody>
            <Row>
              <Col md="6">
                <Label htmlFor='name'>Name</Label>
                <Field name="name" label={"Name"} placeholder="Enter Name">
                  {props => <FormInput {...props} />}
                </Field>
              </Col>
              <Col md="6">
                <Label htmlFor='minBidAmount'>Minimum Bid Amount</Label>
                <Field name="minBidAmount" placeholder="Enter Minimum Bid Amount">
                  {props => <FormInput {...props} />}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col md="6 mb-3">
                <Label htmlFor="images">{"Images"}</Label>
                <Field name="images">
                  {props => (
                    <>
                      <Input
                        className="form-control images"
                        name={props.input.name}
                        onChange={props.input.onChange}
                        // onChange={(e)=>handleAudioFileChange(e)}
                        type="file" />
                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
                      )}
                    </>
                  )}
                </Field>
              </Col>
                <Col md="6 mb-3 upload-btn">
                  <Button  color="primary" disabled={imageUploading} onClick={handlePictureUpload} className="mr-1">{"Upload"}</Button>
                </Col>
            </Row>
            {/* percentage bar */}
            <Row>
              <Col md="12 mb-5">
                <Progress animated value={imagepercentage} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label htmlFor="description">{"Description"}</Label>
                <Field name="description" placeholder="Enter Description">
                  {props => (
                    <>
                      <Input
                      className={`form-control ${props.input.name}`}
                      name={props.input.name}
                      placeholder={props.placeholder}
                      onChange={props.input.onChange}
                      type="textarea" />
                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
                      )}
                    </>
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label htmlFor="notes">{"Notes"}</Label>
                <Field name="notes" placeholder="Enter Notes">
                  {props => (
                    <>
                      <Input
                      className={`form-control ${props.input.name}`}
                      name={props.input.name}
                      placeholder={props.placeholder}
                      onChange={props.input.onChange}
                      type="textarea" />
                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
                      )}
                    </>
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
            <Col md="6">
                <Label htmlFor="startdate">{"Start Date"}</Label>
                <Field name="startdate">
                  {props => (
                    <>
                      <DatePicker
                        placeholderText="Select Date"
                        dateFormat="P"
                        selected={startDate} // needs to be checked if it is valid date
                        // disabledKeyboardNavigation
                        name={props.input.name}
                        onChange={(date) => {
                          // On Change, you should use final-form Field Input prop to change the value
                          // debugger;
                          if (isValid(date)) {
                            props.input.onChange(format(new Date(date), "dd-MM-yyyy"));
                            setStartDate(date);
                          } else {
                            props.input.onChange(null);
                            setStartDate(null);
                          }
                        }}
                      />
                    </>
                  )}
                </Field>
              </Col>
              <Col md="6">
                <Label htmlFor="enddate">{"End Date"}</Label>
                <Field name="enddate">
                  {props => (
                    <>
                      <DatePicker
                        placeholderText="Select Date"
                        dateFormat="P"
                        selected={endDate} // needs to be checked if it is valid date
                        // disabledKeyboardNavigation
                        name={props.input.name}
                        onChange={(date) => {
                          // On Change, you should use final-form Field Input prop to change the value
                          // debugger;
                          if (isValid(date)) {
                            props.input.onChange(format(new Date(date), "dd-MM-yyyy"));
                            setEndDate(date);
                          } else {
                            props.input.onChange(null);
                            setEndDate(null);
                          }
                        }}
                      />
                    </>
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Label htmlFor="locationname">{"Location Details"}</Label>
                <Field name="locationname" placeholder="Enter Location Name">
                  {props => (
                    <>
                      <Input
                      className={`form-control ${props.input.name}`}
                      name={props.input.name}
                      placeholder={props.placeholder}
                      onChange={props.input.onChange}
                      type="test" />
                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
                      )}
                    </>
                  )}
                </Field>
              </Col>
              <Col md="6">
                <Label htmlFor="locationAddress">{"Address"}</Label>
                <Field name="locationAddress" placeholder="Enter Address">
                  {props => (
                    <>
                      <Input
                      className={`form-control ${props.input.name}`}
                      name={props.input.name}
                      placeholder={props.placeholder}
                      onChange={props.input.onChange}
                      type="test" />
                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
                      )}
                    </>
                  )}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Label htmlFor="coordinates">{"Location Co-Ordinates"}</Label>
                <Field name="coordinates" placeholder="Enter Location Co-Ordinates">
                  {props => (
                    <>
                      <Input
                      className={`form-control ${props.input.name}`}
                      name={props.input.name}
                      placeholder={props.placeholder}
                      onChange={props.input.onChange}
                      type="test" />
                      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
                        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
                      )}
                    </>
                  )}
                </Field>
              </Col>
              <Col md="6 upload-btn">
                <Button type="button" color="primary" className="upload" onClick={onLocationClick}>{'Add Location Details'}</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {
                  locations ? locations.map((el, index) => {
                    return <div>
                      {index + 1}: {el}
                    </div>
                  }) : ''
                }
              </Col>
            </Row>
          </CardBody>
          <div className="form-footer">
            <Button type="submit" color="primary">{'Submit'}</Button>
            <Button type="button" color="secondary" onClick={onCancelClick}>{'Cancel'}</Button>
          </div>
        </ReactstrapForm>
      )}
    />
    </>
  )
};

export default AddForm;