import { BASE_URL } from './../../config';
export const REDUCER_KEY = 'orders';

export const DATA = 'data';
export const MEDIA_DATA = 'mediaData';
export const IMAGE_DATA = 'imageData';
export const AUDIO_DATA = 'audioData';
export const VIDEO_DATA = 'videoData';

export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';
export const MEDIA_MANAGEMENT_FORM_DATA = 'mediaManagementFormData';
export const CATEGORY_API = `${BASE_URL}/media/category/all`;
export const SUBCATEGORY_API = `${BASE_URL}/media/subcategory/all`;
export const INNER_SUBCATEGORY_API = `${BASE_URL}/media/innerSubCategory/all`;
export const ADD_MEDIA_CATEGORY_API = `${BASE_URL}/media/category/add`;
export const UPDATE_MEDIA_CATEGORY_API = `${BASE_URL}/media/category/update`;
export const ADD_MEDIA_SUBCATEGORY_API = `${BASE_URL}/media/subcategory/add`;
export const UPDATE_MEDIA_SUBCATEGORY_API = `${BASE_URL}/media/subcategory/update`;
export const ADD_MEDIA_INNER_SUBCATEGORY_API = `${BASE_URL}/media/innerSubCategory/add`;
export const UPDATE_MEDIA_INNER_SUBCATEGORY_API = `${BASE_URL}/media/innerSubCategory/update`;
export const TRACKING_UPDATE_API = `${BASE_URL}/tracking/create`;
export const GENERATE_INVOICE_API = orderId => `${BASE_URL}/order/invoice/create/${orderId}`;
export const DOWNLOAD_INVOICE_API = orderId => `${BASE_URL}/order/invoice/download/${orderId}`;
export const ACCEPT_ORDER_API = `${BASE_URL}/order/accept`;
export const REJECT_ORDER_API = `${BASE_URL}/order/reject`;

export const ORDERS_API = `${BASE_URL}/order/all`;

export const CATEGORIES = 'categories';
export const SUBCATEGORIES = 'subcategories';
export const INNER_SUBCATEGORIES = 'innersubcategories';
export const CATEGORY_ITEM_ID = 'categoryItemId';
export const MEDIA_ID = 'mediaId';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const FORM_DATA = 'formData';
export const SUBCATEGORY_FORM_DATA = 'subCategoryFormData';
export const ORDERS = 'orders';
// export const ORDER_DETAILS = 'orderDetails';
export const TRACKING_DETAILS = 'trackingDetails';
import { GetStatusLocalizeText } from './../../utils';

import ColumnFilter from './../../components/table/components/columnFilter';


export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [MEDIA_MANAGEMENT_FORM_DATA]: {},
  [CATEGORIES]: [],
  [SUBCATEGORIES]: [],
  [INNER_SUBCATEGORIES]: [],
  [CATEGORY_ITEM_ID]: '',
  [MEDIA_ID]: '',
  [AUTHENTICATED_SESSION]: true,
  [FORM_DATA]: {},
  [SUBCATEGORY_FORM_DATA]: {},
  [ORDERS]: [],
  [TRACKING_DETAILS]: {},
};

export const ordersTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index',
      Filter:  ColumnFilter
    },
    {
      Header: 'Order Number',
      accessor: (row, i) => row.orderId || '---',
      id: 'order_id',
      Filter:  ColumnFilter
    },
    {
      Header: 'Total Item Price',
      accessor: (row, i) => <>&#8377;{row.totalItemPrice}</> || '---',
      id: 'total_order_price',
      Filter:  ColumnFilter
    },
    {
      Header: 'Quantity',
      accessor: (row, i) => row.quantity || '---',
      id: 'total_prodcuts',
      Filter:  ColumnFilter
    },
    {
      Header: 'Created',
      accessor: (row, i) => {
        const d= new Date(row.created_at).toString();
        if(d) {
          return d.substring(0, 21);
        } else {
          return '---'
        }
      },
      id: 'created_at',
      Filter:  ColumnFilter
    },
    {
      Header: 'Customer Name',
      accessor: (row, i) => {
        if (row.address) {
          const address = row.address;
          return `${address.name}`
        } else {
          return '---'
        }
      },
      id: 'address',
      Filter:  ColumnFilter
    },
    {
      Header: 'Status',
      accessor: (row, i) => {
        return row.status;
        const orderDetailStatus = row.status;
        let status_color = 'green';
        if(orderDetailStatus === 'FAILED' || orderDetailStatus === 'CANCELLED' || orderDetailStatus === 'RETURNED' || orderDetailStatus === 'REJECTED' || orderDetailStatus === 'RETURN_REQUEST') {
          status_color = 'red';
        }
        return <div className="status_column">
          <span className={`status_dot ${status_color}`}>&nbsp;</span>
          <span className="text">{GetStatusLocalizeText(orderDetailStatus)}</span>
        </div>
      },
      id: 'order_status',
      type: 'static',
      Filter: ColumnFilter
    },
    {
      Header: 'Action',
      type: 'static',
      Filter: ColumnFilter
    },
    // {
    //   Header: 'Action',
    // },
  ],
  options: {
    displayFilter: true,
    displayStatusFilter: true,
    displaySearch: true,
    maxHeight: 1400
  },
};


export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
