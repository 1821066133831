import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';

export { REDUCER_KEY } from './constants';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

const beforeSubmit = (values) => {
  let updatedValues = {};

  updatedValues.firstName = values.firstName;
  updatedValues.lastName = values.lastName;
  updatedValues.password = values.password;
  updatedValues.phoneNumber = values.phoneNumber;
  updatedValues.email = values.email;
  updatedValues.usertype = 'admin';
  updatedValues.address = {
    address_line1: values.address_line1 || '',
    address_line2: values.address_line2 || '',
    city: values.city || '',
    postal_code: values.postal_code || '',
    state: values.state || '',
    country: values.country || '',
    landmarks: values.landmarks || '',
    telephone: values.telephone || ''
  }
  return updatedValues;
}

export const createUser = (data) => (dispatch) => {
  const updatedData = beforeSubmit(data);
  // http post request
  axios.post([constants.CREATE_USER_API], updatedData,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
  .then(function (response) {
    const responseData = response.data;

    if (responseData.errorMessage) {
      dispatch(
        dataChanged({
          [constants.DATA]: responseData.errorMessage
        })
      )
    } else {
      dispatch(
        dataChanged({
          [constants.DATA]: responseData
        })
      )
    }
    
    dispatch(boundSuccess({data: response, loading: false}));
  })
  .catch(function (error) {
    // console.log(error);
    dispatch(boundError({data: error, loading: false}));
  });
}

export const updatedLogin = () => (dispatch) => {
  dispatch(dataChanged({
    login: true,
  }))
}

export const verifyUser = (data, callback) => (dispatch) => {
  // console.log(data);
  const updatedData = {
    otp: data.otp,
    email: data.email
  };
  // http post request
  axios.post([constants.VERIFY_USER_API], updatedData,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
  .then(function (response) {
    const data = response.data;
    if (data.responseData) {
      callback(data.responseData);
    } else {
      // callback(data.errorMessage);
      dispatch(
        dataChanged({
          [constants.DATA]: data.errorMessage
        })
      )
    }
    // dispatch(boundSuccess({data: response, loading: false}));
  })
  .catch(function (error) {
    // console.log(error);
    dispatch(boundError({data: error, loading: false}));
  });
}
