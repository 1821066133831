import React from 'react';
import {
  FormGroup,
  Label,
  Input
} from 'reactstrap';
const InputFormField = (props) => {
  return(
    <FormGroup>
      <Label htmlFor={props.input.name}>{props.label}</Label>
      <Input onChange={props.input.onChange} className="form-control" name={props.input.name} value={props.input.value} type={props.type ? props.type : 'text'} placeholder={props.placeholder} />
      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
      )}
    </FormGroup>
  )
  
};

export default InputFormField;
