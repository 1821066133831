import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, Modal, ModalHeader, ModalBody} from 'reactstrap';
import _ from 'lodash';

import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as reelsSelectors from '../../ducks/reels-list/selectors';
import { ViewMapProduct, DeleteForm } from './components';
import {
  getReels,
  viewFormData,
  updateActive,
  toggleDeleteForm,
  deleteReelForm,
  toggleMapProduct,
  mapReelToProduct,
  getReelSearchResult,
   searchResultOff
} from '../../ducks/reels-list/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';
import { ordersTableConfig } from '../../ducks/reels-list/constants';
import CustomTable from '../../components/table';

const ReelsList = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [selectedReel, setSelectedReel] = useState(null);
  // const [status, setStatus] = useState(true);

  // Toggle for Modal
  const toggle = (data) => {
    const {
      actions
    } = props;
    actions.toggleMapProduct(data);
    setSelectedReel(data)

    setModal(!modal)
  }

  const handleUpdateActive = (data) => {
    const {
      actions
    } = props;
    actions.updateActive(data);
  }

  const [mapClicked, setMapClicked] = useState(false);
  const handleProductRoute = (data) => {
    navigate(`/products/reel/${data.id}`, {replace: true});
  }

  const submitData = () => setModal(!modal);
  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getReels();
  },[]);

  const {
    authenticatedSession,
    data,
    reels,
    active,
    actions,
    showDeleteForm,
    reelSearch
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }

  //Search functionality
  const searchFunction = (searchText)=>{
    const {
      actions,
    } = props;
    if(_.isEmpty(_.trim(searchText))){

      actions.searchResultOff(false);
    }else{
      actions.getReelSearchResult(searchText);
    }
  }


  useEffect(() => {
    return () => {
      const { actions } = props;
        // actions.searchResultOff(false);
    }
  }, [])

  
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content product">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4 className="category-header-label">Reels List</h4>
                    <div>
                      <Modal isOpen={modal} className="map-product">
                        <ModalHeader
                          close={<FontAwesomeIcon onClick={toggle} icon={faTimes}></FontAwesomeIcon>}
                        >
                          Mapped Products
                        </ModalHeader>
                        <ModalBody>
                          <ViewMapProduct {...props}  selectedReel = {selectedReel}/>
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={showDeleteForm} className="delete-form">
                        <ModalBody>
                          <DeleteForm {...props} />
                        </ModalBody>
                    </Modal>
                    </div>
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="reels">
                      {/* orderTableConfig */}
                      <CustomTable
                      data={reelSearch.isSearch ?  reelSearch.searchResult: reels}
                      onViewClick={toggle}
                      onStatusClick={handleUpdateActive}
                      onDeleteClick={actions.toggleDeleteForm}
                      onMapProductClick={handleProductRoute}
                      // onStockClick={handleUpdateStock}
                      options = {ordersTableConfig.options}
                      columns={ordersTableConfig.columns}
                      searchFunctionality = {{
                        isSearchState : reelSearch.isSearch,
                        searchTextState : reelSearch.searchText,
                        
                        searchHandler : (searchText)=>{ searchFunction(searchText) },
                        searchClear :actions.searchResultOff
                      }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

ReelsList.propTypes = {
  reels: PropTypes.arrayOf(PropTypes.shape()),
};

ReelsList.defaultProps = {
  reels: [],
};

const mapStateToProps = state => ({
  ...state.reelsList,
  data: reelsSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getReels,
    viewFormData,
    updateActive,
    toggleDeleteForm,
    deleteReelForm,
    toggleMapProduct,
    mapReelToProduct,
    getReelSearchResult,searchResultOff
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReelsList);
