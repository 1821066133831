import React from 'react';
import {
  FormGroup,Label,Input,
} from 'reactstrap';
export default function CustomDropdown(props) {
  return (
    <>
      <FormGroup>
        <Label htmlFor={props.input.name}>{props.label}</Label>
        <Input type="select" name={props.input.name} value={props.input.value} onChange={props.input.onChange} className="form-control digits">
          <option value={null}>{"Select"}</option>
          {
          props.meta.data.map((el)=> {
            return <option key={el.id} value={el.id}>{el.name ? el.name : el}</option>
          })
          }
        </Input>
      </FormGroup>
      {(props.meta.error || props.meta.submitError) && props.meta.touched && (
        <span className="error-text">{props.meta.error || props.meta.submitError}</span>
      )}
    </>
  )
}

