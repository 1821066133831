import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as mediaManagementSelectors from '../../ducks/media-management/selectors';
import {
  initFormValues,
} from '../../ducks/media-management/index';
import {
  Button, Form as ReactstrapForm, Spinner, UncontrolledAlert
} from 'reactstrap';
class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  }
  static Page = ({ children }) => children;

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {}
    }
  }
  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }))

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }))

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = (values,formEvents) => {
    const { reset } = formEvents;
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      // const audio = document.getElementsByClassName('audio')[0].files[0];
      return onSubmit(values, page, reset);
    } else {
      // this.next(values);
      return onSubmit(values, page, this.next);
    }
  }

  componentDidMount() {
    const {
      actions
    } = this.props;
    actions.initFormValues();
  }

  
  render() {
    const {
      children,
      loading,
      mediaData,
      imageData,
      audioData,
      videoData,
      audioKey,
      videoKey,
      imageKey,
      data,
    } = this.props;

    console.log('props', this.props);

    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    // console.log('wizard props', this.props);
    const audioSuccess = (typeof audioData == 'object' && Object.keys(audioData).length > 0);
    return (
      <>
      <div>
        {typeof data == 'string' && <UncontrolledAlert color="success">{data}</UncontrolledAlert>}
        {(typeof data == 'object' && Object.keys(data).length > 0) && <UncontrolledAlert color="success">{data.message}</UncontrolledAlert>}
      </div>

      <div>
        {typeof imageData == 'string' && <UncontrolledAlert color="danger">{imageData}</UncontrolledAlert>}
        {(typeof imageData == 'object' && Object.keys(imageData).length > 0) && <UncontrolledAlert color="success">{imageData.message}</UncontrolledAlert>}
      </div>

      <div>
        {typeof audioData == 'string' && <UncontrolledAlert color="danger">{'There is a problem with uploading the audio! Please try again.'}</UncontrolledAlert>}
        {audioSuccess && <UncontrolledAlert color="success">{'Audio upload is successfull!'}</UncontrolledAlert>}
      </div>

      <div>
        {typeof videoData == 'string' && <UncontrolledAlert color="danger">{videoData}</UncontrolledAlert>}
        {(typeof videoData == 'object' && Object.keys(videoData).length > 0) && <UncontrolledAlert color="success">{videoData.message}</UncontrolledAlert>}
      </div>
      
      <div className={`loading-wrapper ${loading ? 'show' : ''}`}></div>
      <Form
        initialValues={audioSuccess ? {} : values}
        validate={this.validate}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, reset, submitting, values }) => (
          <ReactstrapForm onSubmit={handleSubmit} values={values} reset={reset} className={`media-form ${page}`}>
            {activePage}
            <div className="buttons">
              {/* {page > 0 && (
                <Button color="secondary" onClick={this.previous} className="mr-1">{"Previous"}</Button>
              )} */}
              {!isLastPage && <Button type="submit" color="primary" disabled={loading} className="mr-1">{"Next"}</Button>}
              {loading && <Spinner color="secondary">Loading...</Spinner>}
              {isLastPage && (
                <Button type="submit" color="primary" className="mr-1">{"Submit"}</Button>
              )}
            </div>
          </ReactstrapForm>
          
        )}
      </Form>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.mediaManagement,
  mediaData: mediaManagementSelectors.mediaDataSelector(state),
  imageData: mediaManagementSelectors.imageDataSelector(state),
  videoData: mediaManagementSelectors.videoDataSelector(state),
  audioData: mediaManagementSelectors.audioDataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    initFormValues,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);