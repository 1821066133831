import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
export { REDUCER_KEY } from './constants';
import { GetUserToken } from '../../utils';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

export const getAuctions = (type) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  axios.get([constants.AUCTIONS_API],
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const auctionsData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        // update category array
        dispatch(dataChanged({
          [constants.AUCTIONS]: auctionsData || [],
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
    });
};

export const deleteAuction = (data) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  let api = [constants.AUCTIONS_API];
  api = api + `/${data.id + 1}`;
  axios.delete(api,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    }
  )
  .then(
    function (response) {
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        dispatch(dataChanged({
          loading: false
        }));
      }
      dispatch(getAuctions());
    }
  )
  .catch(
    function (error) {
      const { status } = error && error.response || 0;
     if (status == UN_AUTHERIZED_CODE) {
       //redirect to login, clear local storage
       localStorage.removeItem('userData');
       dispatch(dataChanged({loading: false, authenticatedSession: false}));
     } else {
       dispatch(dataChanged({loading: false}));
     }
   }
  )
};

export const submitAuction = (data) => (dispatch, getState) => {
  // dispatch(dataChanged({loading: true}));
  // let api = [constants.AUCTIONS_API];
  // api = api + `/${data.id + 1}`;
  // axios.delete(api,
  //   {
  //     headers: {
  //       // 'Access-Control-Allow-Origin': '*',
  //       'authorization': GetUserToken(),
  //     },
  //   }
  // )
  // .then(
  //   function (response) {
  //     if (data.errorMessage) {
  //       dispatch(
  //         dataChanged({
  //           [constants.DATA_ERROR]: data.errorMessage,
  //           loading: false
  //         })
  //       )
  //     } else {
  //       dispatch(dataChanged({
  //         loading: false
  //       }));
  //     }
  //     dispatch(getAuctions());
  //   }
  // )
  // .catch(
  //   function (error) {
  //     const { status } = error && error.response || 0;
  //    if (status == UN_AUTHERIZED_CODE) {
  //      //redirect to login, clear local storage
  //      localStorage.removeItem('userData');
  //      dispatch(dataChanged({loading: false, authenticatedSession: false}));
  //    } else {
  //      dispatch(dataChanged({loading: false}));
  //    }
  //  }
  // )
};

export const getBiddingByAuctionId = (data) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  let api = [constants.BIDDING_API];
  api = api + `/${data.id}`;
  axios.get(api,
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    }
  )
  .then(
    function (response) {
      const data = response.data;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        dispatch(dataChanged({
          [constants.BIDDING_DATA]: data.responseData,
          loading: false
        }));
      }
      dispatch(getAuctions());
    }
  )
  .catch(
    function (error) {
      const { status } = error && error.response || 0;
     if (status == UN_AUTHERIZED_CODE) {
       //redirect to login, clear local storage
       localStorage.removeItem('userData');
       dispatch(dataChanged({loading: false, authenticatedSession: false}));
     } else {
       dispatch(dataChanged({loading: false}));
     }
   }
  )
};

export const deleteAuctionForm = (data) => (dispatch, getState) => {
  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
    })
  )
};

export const AddAuctionForm = (data) => (dispatch, getState) => {
  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
    })
  )
};

export const initFormValues = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MEDIA_ID]: '',
    })
  )
}

export const viewFormData = (data) => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
    })
  )
};


export const uploadPicture = (file, mediaId, callback) => (dispatch, getState) => {
  // console.log('image uploading');
  // console.log(file, mediaId);
  return;
  const state =getState();
  // http post request
  // create a formData
  let data = new FormData();
  data.append('thumbnail', file);
  data.append('mediaId', mediaId);

  //options
  const options = {
    onUploadProgress: (progressEvent) => {
      const {
        total,
        loaded
      } = progressEvent;
      let percentage = Math.floor((loaded * 100)/total);
      callback(percentage);
    },
    headers: {
      'authorization': GetUserToken(),
    }
  };

  //add a loader to upload button
  dispatch(dataChanged({[constants.IMAGE_UPLOADING]: true}));

  axios.post([constants.IMAGE_UPLOAD_API], data, options)
  .then(function (response) {
    const data = response.data;
    if(data.errorMessage) {
      dispatch(dataChanged({
        [constants.IMAGE_DATA]: data.errorMessage,
        [constants.IMAGE_UPLOADING]: false
      }));
    } else {
      dispatch(dataChanged({
        [constants.IMAGE_DATA]: data,
        [constants.IMAGE_UPLOADING]: false,
        [constants.IMAGE_KEY]: data.responseData[0].key,
      }));
    }
    
    dispatch(boundSuccess({videoID: response, loading: false}));
  })
  .catch(function (error) {
    const { status } = error.response;
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      dispatch(dataChanged({loading: false, authenticatedSession: false}));
    } else {
      dispatch(dataChanged({
        [constants.IMAGE_UPLOADING]: false,
        [constants.IMAGE_DATA]: error.message
      }));
    }    
    // dispatch(boundError({data: error, loading: false}));
  });
}
