import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as merchantsSelectors from '../../ducks/merchants/selectors';
import { AddSubCategoryForm } from './components';
import {
  getMerchants,
  updatesubCategoryFormData,getMerchantSearchResult, searchResultOff , updateActive
} from '../../ducks/merchants/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';
import _ from 'lodash';
import { merchantsTableConfig } from '../../ducks/merchants/constants';
import CustomTable from '../../components/table';

const Merchants = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);


  // Toggle for Modal
  const toggle = (data) => {
    const {
      actions
    } = props;
    if (data.type == 'click') {
      actions.updatesubCategoryFormData({});
    } else {
      actions.updatesubCategoryFormData(data);
    }

    setModal(!modal)
  }

  const submitData = () => setModal(!modal);
  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getMerchants();
  },[]);

  const handleUpdateActive = (data) => {
    const {
      actions
    } = props;
    actions.updateActive(data);
  }

  const searchFunction = (searchText)=>{
    const {
      actions,
    } = props;
    if(_.isEmpty(_.trim(searchText))){

      actions.searchResultOff(false);
    }else{
      actions.getMerchantSearchResult(searchText);
    }
  }

  const {
    authenticatedSession,
    data,
    merchants,
    merchantsSearch,actions
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }

  useEffect(() => {
  return () => {
    // actions.searchResultOff(false);
  }
}, [])
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content category">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4 className="category-header-label">Merchants</h4>
                    <div>
                      <Modal isOpen={modal} className="category">
                        <ModalHeader
                          close={
                            <FontAwesomeIcon
                              onClick={toggle}
                              icon={faTimes}
                            ></FontAwesomeIcon>
                          }
                        >
                          Add Subcategory
                        </ModalHeader>
                        <ModalBody>
                          <AddSubCategoryForm {...props} />
                        </ModalBody>
                      </Modal>
                    </div>
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="merchants">
                      {/* mediaTableConfig */}
                      <CustomTable
                        data={
                          merchantsSearch.isSearch
                            ? merchantsSearch.searchResult
                            : merchants
                        }
                        onViewClick={toggle}
                        options={merchantsTableConfig.options}
                        columns={merchantsTableConfig.columns}
                        onStatusClick={handleUpdateActive}
                        searchFunctionality={{
                          isSearchState: merchantsSearch.isSearch,
                          searchTextState: merchantsSearch.searchText,

                          searchHandler: (data) => { searchFunction(data) },
                          searchClear : actions.searchResultOff,
                        }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
}

Merchants.propTypes = {
  merchants: PropTypes.arrayOf(PropTypes.shape()),
};

Merchants.defaultProps = {
  merchants: [],
};

const mapStateToProps = state => ({
  ...state.merchants,
  data: merchantsSelectors.dataSelector(state),
  categories: merchantsSelectors.categoriesSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getMerchants,
    updatesubCategoryFormData,
    getMerchantSearchResult,
    searchResultOff, updateActive
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Merchants);
