export const REDUCER_KEY = 'advManagement';
import { BASE_URL } from './../../config';

export const DATA = 'data';
export const MEDIA_DATA = 'mediaData';
export const IMAGE_DATA = 'imageData';
export const AUDIO_DATA = 'audioData';
export const VIDEO_DATA = 'videoData';

export const DATA_LOADING = 'loading';
export const IMAGE_UPLOADING = 'imageUploading';
export const VIDEO_UPLOADING = 'videoUploading';
export const AUDIO_UPLOADING = 'audioUploading';
export const DATA_ERROR = 'error';
export const MEDIA_MANAGEMENT_FORM_DATA = 'mediaManagementFormData';
export const VIDEO_UPLOAD_API = `${BASE_URL}/media/upload/video`;
export const IMAGE_UPLOAD_API = `${BASE_URL}/media/upload/image`;
export const AUDIO_UPLOAD_API = `${BASE_URL}/acr/buckets/upload/audio`;
export const CATEGORY_API = `${BASE_URL}/media/category/all`;
export const ADD_MEDIA_API = `${BASE_URL}/media/add`;
export const CATEGORIES = 'categories';
export const SUBCATEGORIES = 'subcategories';
export const CATEGORY_ITEM_ID = 'categoryItemId';
export const MEDIA_ID = 'mediaId';
export const AUDIO_KEY = 'audioKey';
export const VIDEO_KEY = 'videoKey';
export const IMAGE_KEY = 'imageKey';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const DISPLAY_LOADER = 'displayLoader';

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: {},
  [MEDIA_MANAGEMENT_FORM_DATA]: {},
  [CATEGORIES]: [],
  [SUBCATEGORIES]: [],
  [CATEGORY_ITEM_ID]: '',
  [MEDIA_DATA]: {},
  [IMAGE_DATA]: {},
  [VIDEO_DATA]: {},
  [AUDIO_DATA]: {},
  [MEDIA_ID]: '',
  [IMAGE_UPLOADING]: false,
  [VIDEO_UPLOADING]: false,
  [AUDIO_UPLOADING]: false,
  [AUDIO_KEY]: '',
  [VIDEO_KEY]: '',
  [IMAGE_KEY]: '',
  [AUTHENTICATED_SESSION]: true,
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
