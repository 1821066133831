import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as orderSelectors from '../../ducks/orders/selectors';
import { ViewOrderDetails, AddTrackingDetails } from './components';
import {
  getOrders,
  viewFormData,
  // addTrackFormData,
  generateInvoice,
  addUpdateTracking,
  downloadInvoice,
} from '../../ducks/orders/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';

import { ordersTableConfig } from '../../ducks/orders/constants';
import CustomTable from '../../components/table';
import { actions } from 'react-table';

const Orders = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [trackModal, setTrackModal] = useState(false);


  const trackFormToggle = (data) => {
    setTrackModal(!trackModal)
  }

  // Toggle for Modal
  const toggle = (data) => {
    const {
      actions
    } = props;
    actions.viewFormData(data);
    setModal(!modal)
  }
  const submitData = () => setModal(!modal);
  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getOrders();
  },[]);

  const {
    authenticatedSession,
    data,
    orders,
    actions,
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }
  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content category">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                {
                  modal ? 
                  <ViewOrderDetails
                  trackModal={trackModal}
                  trackFormToggle={trackFormToggle}
                  generateInvoice={actions.generateInvoice}
                  downloadInvoice={actions.downloadInvoice}
                  addUpdateTracking={actions.addUpdateTracking}
                  {...props}
                  toggle={toggle} /> :
                  <CardBody>
                    <CardHeader>
                      <h4 className="category-header-label">Orders</h4>
                    </CardHeader>
                    <div className="item  mb-3">
                      <div className="orders">
                        {/* orderTableConfig */}
                        <CustomTable
                        data={orders}
                        onViewClick={toggle}
                        options = {ordersTableConfig.options}
                        columns={ordersTableConfig.columns} />
                      </div>
                    </div>
                  </CardBody>
                }
              </Card>
            </Col>
          </Row>
        </Container>

      </main>
    </>
  )
}

Orders.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()),
};

Orders.defaultProps = {
  orders: [],
};

const mapStateToProps = state => ({
  ...state.orders,
  data: orderSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getOrders,
    viewFormData,
    // addTrackFormData,
    addUpdateTracking,
    generateInvoice,
    downloadInvoice,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
