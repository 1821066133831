import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  Form as ReactstrapForm, CardBody, Row, CardFooter, Col, Button, Label
} from 'reactstrap';
import FormInput from './formInput';

const AddCategoryForm = (props) => {

  const onSubmit = values => {
    const {
      handleSubmit
    } = props;
    handleSubmit(values);
  }

  const validatePage= values => {
    const errors = {}
    if (!values.category) {
      errors.category = 'Category is Required';
    }

    return errors;
  }

  const {
    category,
    categoryFormData
  } =props;

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validatePage}
        initialValues={{category: categoryFormData.name}}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
        <ReactstrapForm onSubmit={handleSubmit} className="form theme-form">
          <CardBody>
            <Row>
              <Col md="mb-3">
                <Label htmlFor='category'>Category</Label>
                <Field name="category" placeholder="Enter Category">
                  {props => <FormInput {...props} />}
                </Field>
              </Col>
            </Row>
          </CardBody>
          <div className="form-footer">
            <Button type="submit" color="primary">{categoryFormData.id ? 'Update' : 'Add'}</Button>
          </div>
        </ReactstrapForm>
      )}
    />
    </>
  )
};

export default AddCategoryForm;