export const REDUCER_KEY = 'mediaListArray';
import { BASE_URL } from '../../config';

export const DATA = 'data';
export const DATA_LOADING = 'loading';
export const DATA_ERROR = 'error';






export const MEDIA_API = `${BASE_URL}/media/all?page1&limit=20`;
export const FUNCTION_REEL_UNMAP_FROM_MEDIA = (reelId, mediaId) =>`${BASE_URL}/media/map/reels/${reelId}/${mediaId}`;




export const CATEGORY_ITEM_ID = 'categoryItemId';
export const MEDIA_ID = 'mediaId';
export const AUTHENTICATED_SESSION = 'authenticatedSession';
export const FORM_DATA = 'formData';
export const MEDIA = 'media';
export const REELS = 'reels';

export const ACTIVE = 'active';
export const REEL = 'testReels';
export const SHOW_DELETE_FORM = 'showDeleteForm';
export const SELECTED_ROW_ID = 'selectedRowId';
export const PRODUCTS_LIST = 'productList';
export const SELECTED_MEDIA = 'selectedMedia';

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: [],
  [CATEGORY_ITEM_ID]: '',
  [MEDIA_ID]: '',
  [AUTHENTICATED_SESSION]: true,
  [FORM_DATA]: {},
  [MEDIA]: [],
  [REELS]: [],
  [ACTIVE]: true,
  [SHOW_DELETE_FORM]: false,
  [SELECTED_ROW_ID]: '',
  [PRODUCTS_LIST]:[],
  [SELECTED_MEDIA] :[]
};

export const ordersTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index'
    },
    {
      Header:"Cover Photo",
      id: 'cover_photo',
      meta: {
        video_column: true,
      }
    },
    {
      Header: 'Title',
      accessor: (row, i) => row.title || '---',
      id: 'title',
    },
    {
      Header: 'Artist',
      accessor: (row, i) => row.artist || '---',
      id: 'artist',
    },
    {
      Header: 'Composer',
      accessor: (row, i) => row.composer || '---',
      id: 'composer',
    },

    {
      Header: 'Language',
      accessor: (row, i) => row.language_id || '---',
      id: 'language_id',
    },
    {
      Header: 'Action'
    }
  ],
  options: {
    displayFilter: false,
    displaySearch: true,
    maxHeight: 1400
  },
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
