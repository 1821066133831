import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
export { REDUCER_KEY } from './constants';
import { GetUserToken } from '../../utils';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

export const getMedia = (type) => (dispatch, getState) => {
  const { mediaCategory } = getState();
  const { subcategories } = mediaCategory;
  dispatch(dataChanged({loading: true}));
  axios.get([constants.MEDIA_API],
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      const mediaData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            authenticatedSession: true,
            loading: false
          })
        )
      } else {
        dispatch(dataChanged({
          [constants.MEDIA]: mediaData[0].media ,
          authenticatedSession: true,
          loading: false
        }));
      }
      
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}
export const unmapReelFromMedia = (reelId, mediaId) => (dispatch, getState) => {
  const mediaState = getState().mediaListArray;
  console.log(mediaState);
  dispatch(dataChanged({loading: true}));
  const apiUrl = constants.FUNCTION_REEL_UNMAP_FROM_MEDIA(reelId, mediaId)
  axios.delete([apiUrl],
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      const mediaData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            authenticatedSession: true,
            loading: false
          })
        )
      } else {
        let mediaList = mediaState.media;
       let mediaIndex = Array(...mediaList).findIndex((media)=>media.mediaId === mediaId) 
       
       if(mediaIndex > 0){
        console.log("worked successfully")
        mediaList[mediaIndex] = response.responseData[0];
       }
        
        dispatch(dataChanged({
          [constants.MEDIA]: mediaList,
          authenticatedSession: true,
          loading: false
        }));
      }
      
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}
export const setSelectedMedia = (data) => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.SELECTED_MEDIA]: data,
    })
  )
};