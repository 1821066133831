import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as imageSelectors from '../../ducks/images-list/selectors';
import { DeleteForm, ViewMapProduct } from './components';
import {
  getImages,
  updateActive,
  toggleDeleteForm,
  deleteImagesForm,
  toggleMapProduct,
  mapImageToProduct,
} from '../../ducks/images-list/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';

import { ordersTableConfig } from '../../ducks/images-list/constants';
import CustomTable from '../../components/table';

const ImagesList = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  // const [status, setStatus] = useState(true);

  const toggle = (data) => {
    const {
      actions
    } = props;
    actions.toggleMapProduct(data);

    setModal(!modal)
  }

  const handleUpdateActive = (data) => {
    const {
      actions
    } = props;
    actions.updateActive(data);
  };

  const handleProductRoute = (data) => {
    navigate(`/products/image/${data.id}`, {replace: true});
  };

  const submitData = () => setModal(!modal);
  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getImages();
  },[]);

  const {
    authenticatedSession,
    data,
    images,
    active,
    actions,
    showDeleteForm,
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }

  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content product">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4 className="category-header-label">Images List</h4>
                    <div>
                    <Modal isOpen={modal} className="map-product">
                        <ModalHeader
                          close={<FontAwesomeIcon onClick={toggle} icon={faTimes}></FontAwesomeIcon>}
                        >
                          Mapped Products
                        </ModalHeader>
                        <ModalBody>
                          <ViewMapProduct {...props} />
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={showDeleteForm} className="delete-form">
                        <ModalBody>
                          <DeleteForm {...props} />
                        </ModalBody>
                      </Modal>
                    </div>
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="images">
                      {/* orderTableConfig */}
                      <CustomTable
                      data={images}
                      onViewClick={toggle}
                      onStatusClick={handleUpdateActive}
                      onDeleteClick={actions.toggleDeleteForm}
                      // onStockClick={handleUpdateStock}
                      onMapProductClick={handleProductRoute}
                      options = {ordersTableConfig.options}
                      columns={ordersTableConfig.columns} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

ImagesList.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()),
};

ImagesList.defaultProps = {
  images: [],
};

const mapStateToProps = state => ({
  ...state.imagesList,
  data: imageSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getImages,
    updateActive,
    toggleDeleteForm,
    deleteImagesForm,
    toggleMapProduct,
    mapImageToProduct,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagesList);
