import { createSelector } from "reselect";
import * as constants from './constants';

// login page base selector
export const baseSelector = state => state[constants.REDUCER_KEY];

export const loadingSelector = createSelector(
  baseSelector,
  state => state[constants.DATA_LOADING],
);

export const errorSelector = createSelector(
  baseSelector,
  state => state[constants.DATA_ERROR],
);

export const dataSelector = createSelector(
  baseSelector,
  state => state[constants.DATA] || {},
);

export const categoriesSelector = createSelector(
  baseSelector,
  state => state[constants.CATEGORIES] || {},
);

export const mediaDataSelector = createSelector(
  baseSelector,
  state => state[constants.MEDIA_DATA] || {},
);

export const audioDataSelector = createSelector(
  baseSelector,
  state => state[constants.AUDIO_DATA] || {},
);

export const imageDataSelector = createSelector(
  baseSelector,
  state => state[constants.IMAGE_DATA] || {},
);

export const videoDataSelector = createSelector(
  baseSelector,
  state => state[constants.VIDEO_DATA] || {},
);

export default baseSelector;