import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import {Container,Row,Col,Card,CardBody,CardHeader,Button, Label, Input, Form as ReactstrapForm,
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import { Form, Field } from "react-final-form";
import * as profileSelectors from '../../ducks/profile/selectors';
import {
  getProfileData,
  editFormData,
  updateProfileData,
} from '../../ducks/profile/index';
import { InputFormField } from './components';
import './index.scss';
const Profile = (props) => {
  const auth = useContext(AuthContext);
  useEffect(()=>{
    const {
      actions,
      authenticatedSession,
    } = props;
    if(!authenticatedSession) {
      auth.setUserDataToNull();
    }
    actions.getProfileData();
  },[]);
  const [_logo, setLogo] = useState({});

  const submitData = (formData) => {
    if (!_logo.size) {
      window.alert('Please select a logo!!;');
      return;
    } 
    actions.updateProfileData({...formData, logo: _logo});
  }

  const handleLogoChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const file = target.files[0];
    if (!file) {
      return;
    }
    if (file.type.indexOf('image') < 0) {
      window.alert('Please select a valid image file');
    } else {
      setLogo(file);
    }
  }

  const validatePage= values => {
    const errors = {}
    if (!values.firstname) {
      errors.firstname = 'First name is Required';
    }
    if (!values.lastname) {
      errors.lastname = 'Last Name is Required';
    }

    return errors
  }

  const handleEditClick = (e) => {
    e.preventDefault();
    const target = e.target;
    const {
      actions
    } = props;
    actions.editFormData();
  }
  const {
    actions,
    data,
    editForm,
  } = props;

  const {
    firstname,
    lastname,
    email,
    phoneNumber,
    businessName
  } = data;

  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content profile">
        <div className="profile-cover bg-dark"></div>
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4 className="profile-header">{"My Account"}</h4>
                    {/* {!editForm && <Button onClick={handleEditClick} color="primary">Edit</Button>}
                    {editForm && <Button type="submit" color="primary">Update</Button>} */}
                  </CardHeader>
                  <Form
                    onSubmit={submitData}
                    validate={validatePage}
                    initialValues={{firstname, lastname, phoneNumber, email}} // if needed we can pass it
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <ReactstrapForm onSubmit={handleSubmit} className="form theme-form">
                      <CardBody>
                        <div className="profile-update-button">
                          {!editForm && <Button onClick={handleEditClick} color="primary">Edit</Button>}
                          {editForm && <Button type="submit" color="primary">Update</Button>}
                        </div>
                        <Row>
                          <Col md="6 mb-3">
                            {editForm && <Field name="firstname" label="First Name" placeholder="Enter First Name">
                              {props => <InputFormField {...props} />}
                            </Field>}
                            {!editForm && <div className="mb-3">
                              <Label className="form-label">First Name</Label>
                              <div>{firstname || '---'}</div>
                            </div>}
                          </Col>
                          <Col md="6 mb-3">
                            {editForm && <Field name="lastname" label="Last Name" placeholder="Enter Last Name">
                              {props => <InputFormField {...props} />}
                            </Field>}
                            {!editForm && <div className="mb-3">
                              <Label className="form-label">Last Name</Label>
                              <div>{lastname || '---'}</div>
                            </div>}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <div>{email || '---'}</div>
                            </div>
                          </Col>
                          <Col md="6 mb-3">
                            <div className="mb-3">
                              <Label className="form-label">Phone</Label>
                              <div>{phoneNumber || '---'}</div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            {editForm && <Field name="businessName" label="Business Name" placeholder="Enter Business Name">
                              {props => <InputFormField {...props} />}
                            </Field>}
                            {!editForm && <div className="mb-3">
                              <Label className="form-label">Business Name</Label>
                              <div>{businessName || '---'}</div>
                            </div>}
                          </Col>
                          {editForm && <Col md="6 mb-3"> 
                            <Label htmlFor="logo">{"Upload Logo"}</Label>
                            <Field name="logo">
                              {props => (
                                <>
                                  <Input
                                    className="form-control logo"
                                    name={props.input.name}
                                    onChange={(e)=>handleLogoChange(e)}
                                    type="file" />
                                </>
                              )}
                            </Field>
                            <span>logo size ratio 2:1. e.g., 100x50</span>
                          </Col>}
                        </Row>
                      </CardBody>
                      {/* <CardFooter>
                        <Button  color="primary" className="mr-1">{"Submit"}</Button>
                        <Button color="light" type="reset">{"Reset"}</Button>
                      </CardFooter> */}
                    </ReactstrapForm>
                  )}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

Profile.propTypes = {
  data: PropTypes.shape(),
  editForm: PropTypes.bool,
}

const mapStateToProps = state => ({
  ...state.profile,
  data: profileSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getProfileData,
    editFormData,
    updateProfileData,
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
