import React from 'react';
import MenuList from './components/menuList';
import Header from './components/header';
import './index.scss';

export const navItems = [
  {
    linkTo: '/dashboard',
    icon: 'House',
    title: 'Dashboard'
  },
  {
    linkTo: '#',
    icon: 'CollectionPlayFill',
    title: 'Media Category',
    subNav: [
      {
        linkTo: '/media-category',
        title: 'Category',
      },
      {
        linkTo: '/media-subcategory',
        title: 'Subcategory',
      },
      {
        linkTo: '/media-inner-subcategory',
        title: 'Inner Subcategory',
      }
    ]
  },
  {
    linkTo: '#',
    icon: 'GridFill',
    title: 'Product Category',
    subNav: [
      {
        linkTo: '/product-category',
        title: 'Category',
      },
      {
        linkTo: '/product-subcategory',
        title: 'Subcategory',
      },
      {
        linkTo: '/product-inner-subcategory',
        title: 'Inner Subcategory',
      }
    ]
  },
  {
    linkTo: '#',
    icon: 'GridFill',
    title: 'Media Management',
    subNav: [
      {
        linkTo: '/media-management',
        title: 'Upload Media',
      },
      {
        linkTo: '/movie-list',
        title: 'Movies',
      },
      {
        linkTo: '/songs',
        title: 'Songs',
      },
      {
        linkTo: '/reels-list',
        title: 'Reels',
      },
      {
        linkTo: '/images-list',
        title: 'Images',
      },
      
    ]
  },
  {
    linkTo: '#',
    icon: 'CollectionPlayFill',
    title: 'Advertisement',
    subNav: [
      {
        linkTo: '/adv-management',
        title: 'Upload Advertisement',
      },
      {
        linkTo: '/adv-list',
        title: 'Advertisement List',
      }
    ]
  },
  {
    linkTo: '#',
    icon: 'GraphUp',
    title: 'Reports',
    subNav: [
      {
        title: 'Sales',
        linkTo: '/sales',
      },
      {
        title: 'Product Map Report',
        linkTo: '/product-map-report',

      },
    ]
  },
  {
    linkTo: '/merchants',
    icon: 'MusicPlayerFill',
    title: 'Merchants'
  },
  {
    linkTo: '/content-creators',
    icon: 'PaletteFill',
    title: 'Content Creators'
  },
  {
    linkTo: '/orders',
    icon: 'BasketFill',
    title: 'Orders'
  },
  {
    linkTo: '/products',
    icon: 'Box2Fill',
    title: 'Products'
  },
  {
    linkTo: '/auctions',
    icon: 'Hammer',
    title: 'Auctions'
  },
  {
    linkTo: '/track-order',
    icon: 'GeoAltFill',
    title: 'Track Order'
  },

];

export class Sidebar extends React.Component {
  static propTypes = {
    // username: PropTypes.string,
  }
  static defaultProps = {
    // username: '',
  }
  render() {
    return (
      <>
        <aside className="sidebar-wrapper" data-simplebar="init">
            <div className="simplebar-wrapper">
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer"></div>
              </div>
              <div className="simplebar-mask">
                <div className="simplebar-offset">
                  <div className="simplebar-content-wrapper">
                    <div className="simplebar-content mm-active">
                      <Header />
                      <MenuList menuList={navItems} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="simplebar-placeholder">
              </div>
            </div>
            <div className="simplebar-track simplebar-vertical">
              <div className="simplebar-scrollbar">
              </div>
            </div>
          </aside>
      </>
    )
  }
}

export default Sidebar;