import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import {UN_AUTHERIZED_CODE} from '../../config';
export { REDUCER_KEY } from './constants';
import { GetUserToken } from '../../utils';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

export const getDashboardData = () => (dispatch) => {
  
  // console.log('getting dashboard data..');
  // http post request
  // axios.post([constants.LOGIN_API], {
  //   // options
  //   userName: username,
  //   password: password
  // })
  // .then(function (response) {
  //   console.log(response);
  //   dispatch(boundSuccess({data: response, loading: false}));
  // })
  // .catch(function (error) {
  //   console.log(error);
  //   dispatch(boundError({data: error, loading: false}));
  // });
}


export const getMediaList = () => (dispatch, getState) => {
  const userData = localStorage.getItem('userData') != 'undefined' && JSON.parse(localStorage.getItem('userData')) || null;
  // const updatedData = beforeSubmit(data, currentState.mediaManagement);
  // http post request
  dispatch(dataChanged({loading: true}));
  const getApi = constants.MEDIA_LIST_API;
  const options = {
    headers: {
      'authorization': GetUserToken(),
      'Access-Control-Allow-Origin': '*',
    },
  };

  axios.get(getApi(userData.userid),options)
  .then(function (response) {
    const data = response.data;
    if (data.errorMessage) {
      dispatch(
        dataChanged({
          [constants.DATA_ERROR]: data.errorMessage,
          authenticatedSession: true,
          loading: false
        })
      )
    } else {
      dispatch(
        dataChanged({
          [constants.DATA]: data.responseData,
          authenticatedSession: true,
          loading: false
        })
      );
    }
    
    dispatch(boundSuccess({data: response, loading: false}));
  })
  .catch(function (error) {
     const { status } = error && error.response || 0;
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      dispatch(dataChanged({loading: false, authenticatedSession: false}));
    } else {
      dispatch(dataChanged({loading: false}));
    }
    // dispatch(boundError({data: error, loading: false}));
  });
};
