import React, {useState} from 'react';
import { Form, Field } from 'react-final-form';
import {
  Form as ReactstrapForm, CardBody, Row, CardFooter, Col, Button, Label
} from 'reactstrap';
import FormInput from './formInput';
import CustomDropdown from './dropdown';

const AddSubCategoryForm = (props) => {
  const [subCategories, setsubCategories] = useState([]);
  const onSubmit = values => {
    const {
      handleSubmit
    } = props;
    handleSubmit(values);
  }

  const validatePage= values => {
    const {
      categories,
    } =props;
    const errors = {}
    if (!values.categoryId || values.categoryId === 'Select') {
      errors.categoryId = 'Category is Required';
    }

    if (!values.subCategoryId || values.subCategoryId === 'Select') {
      errors.subCategoryId = 'Subcategory is Required';
    }

    if (!values.name) {
      errors.name = 'Inner Subcategory is Required';
    }

    if (values.categoryId) {
      const scIndex = categories.findIndex((el) => el.id == Number(values.categoryId));
      if(scIndex !== -1) {
        setsubCategories(categories[scIndex].productSubCategory);
      }
    }

    return errors;
  }

  const {
    categories,
    innerSubCategoryFormData,
    categoryId,
  } =props;

  return (
    <>
      <Form
        onSubmit={onSubmit}
        validate={validatePage}
        initialValues={{
          name: innerSubCategoryFormData.name,
          subCategoryId:innerSubCategoryFormData.productSubCategory && innerSubCategoryFormData.productSubCategory.subCategoryId,
          categoryId: innerSubCategoryFormData.productCategory && innerSubCategoryFormData.productCategory.id
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
        <ReactstrapForm onSubmit={handleSubmit} className="form theme-form">
          <CardBody>
            <Row>
              <Col>
                <Field name="categoryId" data={categories} label={"Category"}>
                  {props => (<CustomDropdown {...props} />)}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field name="subCategoryId" data={subCategories} label={"Subcategory"}>
                  {props => (<CustomDropdown {...props} />)}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col md="mb-3">
                <Label htmlFor='name'>Inner Subcategory</Label>
                <Field name="name" placeholder="Enter Inner Subcategory">
                  {props => <FormInput {...props} />}
                </Field>
              </Col>
            </Row>
          </CardBody>
          <div className="form-footer">
            <Button type="submit" color="primary">{innerSubCategoryFormData.productCategoryItemId ? 'Update' : 'Add'}</Button>
          </div>
        </ReactstrapForm>
      )}
    />
    </>
  )
};

export default AddSubCategoryForm;