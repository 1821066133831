import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import AuthContext from '../../pages/authProvider';
import Loader from '../../components/loader';
import * as productSelectors from '../../ducks/products/selectors';
import { ViewProduct } from './components';
import {
  getProducts,
  viewFormData,
  updateActive,
  updateStock,
  addOrRemoveProductToMappingArray,
  mapReelToProduct,
  getProductSearchResult,
  searchProductResultOff
} from '../../ducks/products/index';
import {
  Sidebar
} from '../../pages';
import Header from '../header';
import './index.scss';
import _ from 'lodash';


import { ordersTableConfig } from '../../ducks/products/constants';
import CustomTable from '../../components/table';

const Products = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [productMap, setProductMap] = useState(null);
  const pathName = window.location.pathname;
  const isImage = pathName.split('/').includes('image');
  // Toggle for Modal
  const toggle = (data) => {
    const {
      actions
    } = props;
    actions.viewFormData(data);

    setModal(!modal)
  }

  const handleUpdateActive = (data) => {
    const {
      actions
    } = props;
    actions.updateActive(data);
  };

  const handleCheckBoxClick = (data) => {
    const {
      actions
    } = props;
    actions.addOrRemoveProductToMappingArray(data);
  }

  const handleMapProductClick = (event) => {
    const {
      actions
    } = props;
    actions.mapReelToProduct(productMap, isImage);
  }

  const handleUpdateStock = (data) => {
    const {
      actions
    } = props;
    actions.updateStock(data);
  };

  const submitData = () => setModal(!modal);

  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    
    const {
      customId,
    } = params;
    if (customId) setProductMap(customId, isImage);
    actions.getProducts(customId, isImage);
  },[]);
  

  const {
    authenticatedSession,
    data,
    products,
    active,
    actions,
    mappedProducts,
    productSearch
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  }

  //Search functionality
  const searchHandler= (searchText)=>{
    const {
      actions,
    } = props;

    if(_.isEmpty(_.trim(searchText))){
      actions.searchProductResultOff(false);
    }else{
      actions.getProductSearchResult(searchText);
    }
  }


  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content product">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    {
                      productMap
                      ?
                      (
                        <>
                          <Link to= {`${isImage ? '/images-list' : '/reels-list'}`} className="back-to-reels">
                            <FontAwesomeIcon icon={faArrowLeft} /> Go back to {isImage ? 'Images' : 'Reels'}
                          </Link>
                          <Row>
                            <Col md="6">
                              <h4 className="category-header-label">Product Mapping</h4>
                            </Col>
                            <Col md="6" className="map-button">
                                <Button disabled={mappedProducts.length < 1} color="primary" onClick={handleMapProductClick}>Map Selected Products</Button>
                            </Col>
                          </Row>
                        </>
                      )
                      :
                      (
                        <h4 className="category-header-label">Products</h4>
                      )
                    }
                    
                    <div>
                      <Modal isOpen={modal} className="product">
                        <ModalHeader
                          close={<FontAwesomeIcon onClick={toggle} icon={faTimes}></FontAwesomeIcon>}
                        >
                          View Product
                        </ModalHeader>
                        <ModalBody>
                          <ViewProduct {...props} />
                        </ModalBody>
                      </Modal>
                    </div>
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="products">

                      {/* TABLE SECTION */}
                      <CustomTable
                      data={productSearch.isSearch ?  productSearch.searchResult: products}
                      onViewClick={productMap ? null : toggle}
                      onStatusClick={handleUpdateActive}
                      onStockClick={handleUpdateStock}
                      onCheckboxClick={productMap ? handleCheckBoxClick : null}
                      options = {ordersTableConfig.options}
                      columns={ordersTableConfig.columns} 
                      searchFunctionality={{
                        isSearchState: productSearch.isSearch,
                        searchTextState: productSearch.searchText,

                        searchHandler: (data) => { searchHandler(data) },
                        searchClear : actions.searchProductResultOff,
                      }}                      
                      />


                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

Products.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape()),

};

Products.defaultProps = {
  products: [],
};

const mapStateToProps = state => ({
  ...state.products,
  data: productSelectors.dataSelector(state),
});

const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getProducts,
    viewFormData,
    updateActive,
    updateStock,
    addOrRemoveProductToMappingArray,
    mapReelToProduct,
    getProductSearchResult,
    searchProductResultOff
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
