import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {Container,Row,Col,Card,CardBody,CardHeader, ModalFooter, Button, Modal, ModalHeader, ModalBody, Spinner
} from 'reactstrap';
import AuthContext from '../authProvider';
import Loader from '../../components/loader';
import * as movieSelectors from '../../ducks/movie-list/selectors';
import { ViewMapProduct, DeleteForm } from './components';
import {
  getMovieList
} from '../../ducks/movie-list/index';
import {
  Sidebar
} from '..';
import Header from '../header';
import './index.scss';

import { ordersTableConfig } from '../../ducks/movie-list/constants';
import CustomTable from '../../components/table';

const MovieComponent = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  // const [status, setStatus] = useState(true);

  // Toggle for Modal
  const handleToggle = (data) => {
    const {
      actions
    } = props;
    actions.toggleMapProduct(data);

    setModal(!modal)
  }

  const handleUpdateActive = (data) => {
    const {
      actions
    } = props;
    actions.updateActive(data);
  }

  const handleProductRoute = (data) => {
    navigate(`/products/reel/${data.id}`, {replace: true});
  }

  const submitData = () => setModal(!modal);
  // call category and subcategories api to load the data
  useEffect(() => {
    const {
      actions,
    } = props;
    actions.getMovieList();
  },[]);

  const {
    authenticatedSession,
    data,
    movieList,
    active,
    actions,
    showDeleteForm,
  } = props;
  if(!authenticatedSession) {
    auth.setUserDataToNull();
    navigate('/', {replace: true});
  };
  console.log(movieList);

  return (
    <>
      <Sidebar />
      <Header />
      <main className="page-content product">
        <div className="profile-cover bg-dark"></div>
        <Loader {...props} />
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardHeader>
                    <h4 className="category-header-label">Movies List</h4>
                    <div>
                      <Modal isOpen={modal} className="map-product">
                        <ModalHeader
                          close={<FontAwesomeIcon onClick={handleToggle} icon={faTimes}></FontAwesomeIcon>}
                        >
                          Mapped Products
                        </ModalHeader>
                        <ModalBody>
                          <ViewMapProduct {...props} />
                        </ModalBody>
                      </Modal>
                      <Modal isOpen={showDeleteForm} className="delete-form">
                        <ModalBody>
                          <DeleteForm {...props} />
                        </ModalBody>
                    </Modal>
                    </div>
                  </CardHeader>
                  <div className="item  mb-3">
                    <div className="reels">
                      {/* orderTableConfig */}
                      <CustomTable
                      data={movieList}
                      onViewClick={handleToggle}
                      onStatusClick={handleUpdateActive}
                      onDeleteClick={actions.toggleDeleteForm}
                      onMapProductClick={handleProductRoute}
                      // onStockClick={handleUpdateStock}
                      options = {ordersTableConfig.options}
                      columns={ordersTableConfig.columns} />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

MovieComponent.propTypes = {
  movieList: PropTypes.arrayOf(PropTypes.shape()),
};

MovieComponent.defaultProps = {
  movieList: [],
};

const mapStateToProps = state => ({
  ...state.movieList,
  data: movieSelectors.dataSelector(state),
});


const mapDispatchToProps = (dispatch) => {
  const actions = bindActionCreators({
    getMovieList
  }, dispatch);

  return {
    actions,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MovieComponent);
