import { BASE_URL } from './../../config';

export const REDUCER_KEY = 'profile';

export const DATA = 'data';

export const DATA_LOADING= 'loading';
export const DATA_ERROR= 'error';
export const FIRST_NAME= 'firstName';
export const LAST_NAME= 'lastName';
export const FORM_DATA= 'profileFormData';
export const EDIT_FORM= 'editForm';
export const PROFILE_UPDATE_API= `${BASE_URL}/contentCreator/profile/update`;
export const PROFILE_GET_API= `${BASE_URL}/contentCreator/profile/get`;
export const LOGO_FILE = 'logoFile';
export const AUTHENTICATED_SESSION = 'authenticatedSession';

export const RESOURCE_DEFAULTS = {
  [DATA_ERROR]: '',
  [DATA_LOADING]: false,
  [DATA]: {},
  [FORM_DATA]: {},
  [EDIT_FORM]: false,
  [LOGO_FILE]: {},
  [AUTHENTICATED_SESSION]: true,
};

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
