import { createSelector } from "reselect";
import * as constants from './constants';

// login page base selector
export const baseSelector = state => state[constants.REDUCER_KEY];

export const loadingSelector = createSelector(
  baseSelector,
  state => state[constants.DATA_LOADING],
);

export const errorSelector = createSelector(
  baseSelector,
  state => state[constants.DATA_ERROR],
);

export const dataSelector = createSelector(
  baseSelector,
  state => state[constants.DATA] || {},
);

export const verifyDataSelector = createSelector(
  baseSelector,
  state => state[constants.VERIFY_DATA] || {},
);

export const authenticatedSessionSelector = createSelector(
  baseSelector,
  state => state[constants.AUTHENTICATED_SESSION] || false,
);

export default baseSelector;
