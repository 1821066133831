import { BASE_URL } from '../../config';
import ColumnFilter from './../../components/table/components/columnFilter';

export const REDUCER_KEY = 'sales';


export const SALES_DATA = 'salesData';
export const PRODUCT_MAPPING_DATA = 'productMappingData';


export const SALES_DATA_API = `${BASE_URL}/sales/salesData`;
export const PRODUCT_MAPPING_REPORT_API = `${BASE_URL}/report/productmapping`;


export const RESOURCE_DEFAULTS = {
  // [DATA_ERROR]: '',
  [SALES_DATA]: [],
  [PRODUCT_MAPPING_DATA]: [],
};

export const salesTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index',
    },
    {
      Header: 'Brand',
      accessor: (row) => row.brand || '---',
      Filter:  ColumnFilter
    },
    {
      Header: 'SKU',
      accessor: (row, i) => row.sku || '---',
      Filter:  ColumnFilter
    },
    {
      Header: 'Qty',
      accessor: (row, i) => row.quantity || '---',
      id: 'total_prodcuts',
      Filter:  ColumnFilter
    },
    {
      Header: 'Sale Date',
      accessor: (row, i) => row.saleDate || '---',
      Filter:  ColumnFilter
    },
    {
      Header: 'Total Price',
      accessor: (row, i) => <>&#8377;{row.totalPrice}</>  || '---',
      Filter:  ColumnFilter

    },
    {
      Header: 'Product',
      accessor: (row, i) => row.product.productname || '---',
      Filter:  ColumnFilter
    },
  ],
  options: {
    displayFilter: true,
    displayStatusFilter: true,
    displaySearch: true,
    maxHeight: 1400
  },
};

export const productMapReportTableConfig = {
  columns: [
    {
      Header: 'No.',
      accessor: (row, i) => i + 1,
      id: 'index',
    },
    {
      Header: 'Title',
      accessor: (row) => row.title || '---',
      Filter:  ColumnFilter
    },
    {
      Header: 'SKU',
      accessor: (row, i) => row.sku || '---',
      Filter:  ColumnFilter
    },
    {
      Header: 'Product',
      accessor: (row, i) => row.productname || '---',
      Filter:  ColumnFilter
    },
    {
      Header: 'Brand',
      accessor: (row, i) => row.brand || '---',
      Filter:  ColumnFilter
    },
    {
      Header: 'Price',
      accessor: (row, i) => <>&#8377;{row.price}</>  || '---',
      Filter:  ColumnFilter
    },

    {
      Header: 'Mapped Date',
      accessor: (row, i) => dateFormatting(row.product_mapped_at) || '---',
      Filter:  ColumnFilter
    },

  ],
  options: {
    displayFilter: true,
    displayStatusFilter: true,
    displaySearch: true,
    maxHeight: 1400
  },
};
const dateFormatting=(date)=>{
  const tempDate = new Date(date)
  return `${tempDate.getFullYear()}-${tempDate.getMonth()+1}-${tempDate.getDate()}`
}

export const DEFAULT_STATE = {
  ...RESOURCE_DEFAULTS,
};

export const STORE_DATA = {
  [REDUCER_KEY]: {
    ...DEFAULT_STATE,
  }
};
