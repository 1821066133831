import 'regenerator-runtime/runtime';
import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
import { async } from 'regenerator-runtime';
import { GetUserToken } from '../../utils';

export { REDUCER_KEY } from './constants';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

export const submitForm = (formValues, callbackFn) => (dispatch, getState) => {
  const {
    signedURLData,
    mediaType,
  } = getState().mediaManagement;
  // create a formData
  const updatedValue = {};

  if (Object.keys(signedURLData).length === 0) {
    console.log(signedURLData)
    window.alert('Please upload media first before submit this form');
    return;
  }

  updatedValue.title = formValues.title;
  updatedValue.tags = formValues.tags && formValues.tags.split(',') || [];
  updatedValue.description = formValues.description;
  updatedValue.path = signedURLData.reels.path
  updatedValue.tileImageUrl = signedURLData.tileImageUrl.path
  //options
  const options = {
    headers: {
      'authorization': GetUserToken(),
    }
  };

  //add a loader to upload button
  dispatch(dataChanged({loading: true}));
  let url = [constants.SUBMIT_REELS_DATA_FORM];
  if (mediaType === 'Images') {
    url = [constants.SUBMIT_IMAGES_DATA_FORM];
    updatedValue.path = [signedURLData.path];
  }

  axios.post(url, updatedValue, options)
  .then(function (response) {
    const data = response.data;
    if(data.errorMessage) {
      dispatch(dataChanged({
        [constants.DATA]: data.errorMessage,
        loading: false
      }));
    } else {
      dispatch(dataChanged({
        [constants.DATA]: data,
        [constants.SIGNED_URL_DATA]: {},
        loading: false,
      }));
      callbackFn(data);
    }

    dispatch(boundSuccess({loading: false}));
  })
  .catch(function (error) {
    dispatch(dataChanged({
      [constants.DATA]: error.message,
      loading: false,
    }));
    // dispatch(boundError({data: error, loading: false}));
  });
}

export const uploadReels = (file) => async (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const {
    signedURLData
  } = getState().mediaManagement;
  // create a formData
  let signedResponse = {};

  const fileNameArray= file.name.split('.');
  if (fileNameArray[fileNameArray.length-1] !== 'mp4' && fileNameArray[fileNameArray.length-1] !== 'webm') {
    return;
  }

  try {
    await axios.post([constants.UPLOAD_SIGNED_URL], {
      "ext": fileNameArray[fileNameArray.length-1]
    },
      {
        headers: {
          'authorization': GetUserToken(),
          'Access-Control-Allow-Origin': '*',
        },
      })
    .then(function (response) {
      const data = response.data;
      const responseData = data.responseData[0];
      signedResponse = responseData;
      dispatch(dataChanged({
        [constants.SIGNED_URL_DATA]: {
          ...signedURLData,
          reels: responseData
        },
      }));
    });
  } catch (error) {
    const { status } = error && error.response || {};
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      dispatch(dataChanged({ authenticatedSession: false}));
    } else {
      // dispatch(dataChanged({loading: false}));
    }
  }

  //options
  const options = {
    headers: {
      // 'authorization': GetUserToken(),
      'Content-Type': 'application/octet-stream',
      'Access-Control-Allow-Origin': '*'
    }
  };

  //add a loader to upload button
  dispatch(dataChanged({[constants.VIDEO_UPLOADING]: true}));
  if (signedResponse && signedResponse.preSignedUrl) {
    axios.put(signedResponse.preSignedUrl, file, options)
    .then(function (response) {
      const data = response.data;
      if(data.errorMessage) {
        dispatch(dataChanged({
          [constants.VIDEO_DATA]: 'There is a problem with uploading the media, Please try again!',
          [constants.VIDEO_UPLOADING]: false
        }));
      } else {
        dispatch(dataChanged({
          [constants.VIDEO_DATA]: { message: "Reels Successfully uploaded! Please submit the form data."},
          [constants.VIDEO_UPLOADING]: false,
        }));
      }
      dispatch(dataChanged({loading: false}));
      dispatch(boundSuccess({loading: false}));
    })
    .catch(function (error) {
      const { status } = error.response;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({
          [constants.VIDEO_DATA]: 'There is a problem with uploading the media, Please try again!',
          loading: false,
          [constants.VIDEO_UPLOADING]: false,
        }));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
  }
}

export const uploadImage = (file) => async (dispatch, getState) => {
  const {
    signedURLData
  } = getState().mediaManagement;
  dispatch(dataChanged({loading: true}));
  // create a formData
  let signedResponse = {};

  const fileNameArray= file.name.split('.');
  if (fileNameArray[fileNameArray.length-1] !== 'jpeg'
  && fileNameArray[fileNameArray.length-1] !== 'jpg'
  && fileNameArray[fileNameArray.length-1] !== 'JPG'
  && fileNameArray[fileNameArray.length-1] !== 'JPEG'
  && fileNameArray[fileNameArray.length-1] !== 'png'
  && fileNameArray[fileNameArray.length-1] !== 'PNG'
  && fileNameArray[fileNameArray.length-1] !== 'GIF'
  && fileNameArray[fileNameArray.length-1] !== 'gif') {
    return;
  }

  try {
    await axios.post([constants.UPLOAD_IMAGE_SIGNED_URL], {
      "ext":fileNameArray[fileNameArray.length-1]
    },
      {
        headers: {
          'authorization': GetUserToken(),
          'Access-Control-Allow-Origin': '*',
        },
      })
    .then(function (response) {
      const data = response.data;
      const responseData = data.responseData[0];
      signedResponse = responseData;
      dispatch(dataChanged({
        [constants.SIGNED_URL_DATA]: {
          ...signedURLData,
          tileImageUrl: responseData
        },
      }));
    });
  } catch (error) {
    const { status } = error && error.response || {};
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      dispatch(dataChanged({ authenticatedSession: false}));
    } else {
      // dispatch(dataChanged({loading: false}));
    }
  }

  //options
  const options = {
    headers: {
      // 'authorization': GetUserToken(),
      'Content-Type': 'application/octet-stream',
      'Access-Control-Allow-Origin': '*'
    }
  };

  //add a loader to upload button
  dispatch(dataChanged({[constants.IMAGE_UPLOADING]: true}));
  if (signedResponse && signedResponse.preSignedUrl) {
    axios.put(signedResponse.preSignedUrl, file, options)
    .then(function (response) {
      const data = response.data;
      if(data.errorMessage) {
        dispatch(dataChanged({
          [constants.IMAGE_DATA]: 'There is a problem with uploading the media, Please try again!',
          [constants.IMAGE_UPLOADING]: false
        }));
      } else {
        dispatch(dataChanged({
          [constants.IMAGE_DATA]: { message: "Image Successfully uploaded! Please submit the form data."},
          [constants.IMAGE_UPLOADING]: false,
        }));
      }
      dispatch(dataChanged({loading: false}));
      dispatch(boundSuccess({loading: false}));
    })
    .catch(function (error) {
      const { status } = error.response;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({
          [constants.IMAGE_DATA]: 'There is a problem with uploading the media, Please try again!',
          loading: false,
          [constants.IMAGE_UPLOADING]: false,
        }));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
  }
}

export const getCategoies = () => (dispatch) => {
  dispatch(dataChanged({loading: true}));
  axios.get([constants.CATEGORY_API],
    {
      // headers: {
      //   // 'Access-Control-Allow-Origin': '*',
      //   'authorization': GetUserToken(),
      // },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const categories = data.responseData;
      const subCategories = categories;
      // update sub-category array
      dispatch(dataChanged({
        [constants.CATEGORIES]: categories,
        loading: false
      }));
    })
    .catch(function (error) {
      const { status } = error.response;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}

const beforeSubmit = (values, state) => {
  let updatedValues = {};

  updatedValues.title = values.title;
  // updatedValues.category = values.category;
  // updatedValues.subcategory = values.subcategory;
  updatedValues.categoryItemId = state.categoryItemId || '';
  updatedValues.album = values.album;
  updatedValues.artist = values.artist;
  updatedValues.album_artist = values.album_artist ? values.album_artist.split(',') : [];
  updatedValues.publisher = values.publisher;
  updatedValues.composer = values.composer;
  updatedValues.language_id = values.language_id;
  updatedValues.genre = values.genre ? values.genre.split(',') : [];
  updatedValues.description = values.description;
  updatedValues.moods = values.moods ? values.moods.split(',') : [];

  return updatedValues;
}

export const createMedia = (data, callbackFn) => (dispatch, getState) => {
  const currentState = getState();
  const updatedData = beforeSubmit(data, currentState.mediaManagement);
  // http post request
  dispatch(dataChanged({loading: true, authenticatedSession: true}));
  
  axios.post([constants.ADD_MEDIA_API], updatedData,
    {
      headers: {
        'authorization': GetUserToken(),
        'Access-Control-Allow-Origin': '*',
      },
    })
  .then(function (response) {
    const responseData = response.data;
    if (responseData.errorMessage) {
      dispatch(
        dataChanged({
          [constants.MEDIA_DATA]: responseData.errorMessage,
          loading: false
        })
      )
    } else {
      dispatch(
        dataChanged({
          [constants.MEDIA_DATA]: responseData,
          [constants.MEDIA_ID]: responseData.responseData[0].mediaId,
          loading: false
        })
      );
      callbackFn(data);// form values need to be passed
    }
    
    dispatch(boundSuccess({data: response, loading: false}));
  })
  .catch(function (error) {
    const { status } = error.response;
    if (status == UN_AUTHERIZED_CODE) {
      //redirect to login, clear local storage
      localStorage.removeItem('userData');
      dispatch(dataChanged({loading: false, authenticatedSession: false}));
    } else {
      dispatch(dataChanged({loading: false}));
    }
    // dispatch(boundError({data: error, loading: false}));
  });
}

export const updateCategoryItemID = (categoryId) => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.CATEGORY_ITEM_ID]: categoryId
    })
  )
};

export const updateMediaType = (media) => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MEDIA_TYPE]: media
    })
  )
};

export const initFormValues = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MEDIA_DATA]: {},
      [constants.IMAGE_DATA]: {},
      [constants.VIDEO_DATA]: {},
      [constants.AUDIO_DATA]: {},
      [constants.MEDIA_ID]: '',
      [constants.IMAGE_UPLOADING]: false,
      [constants.VIDEO_UPLOADING]: false,
      [constants.AUDIO_UPLOADING]: false,
      [constants.AUDIO_KEY]: '',
      [constants.VIDEO_KEY]: '',
      [constants.IMAGE_KEY]: ''
    })
  )
}

