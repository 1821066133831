import axios from 'axios';
import {
  createAction,
  loadSuccess,
  loadError,
  loading,
} from '../generics';
import * as constants from './constants';
import actionTypes from './action-types';
import { UN_AUTHERIZED_CODE } from '../../config';
export { REDUCER_KEY } from './constants';
import { GetUserToken } from '../../utils';
import { BASE_URL } from '../../config';

import _ from 'lodash';

const actionTypeExists = Object.keys(actionTypes).reduce((hash, key) => ({
  ...hash,
  [actionTypes[key]]: true,
}), {});

// create reducer
export const reducer = (state = constants.DEFAULT_STATE, action = {}) => {
  if (actionTypeExists[action.type]) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default reducer;

const boundLoading = loading(actionTypes.DATA_LOADING);
const boundSuccess = loadSuccess(actionTypes.DATA_LOAD_SUCCESS);
const boundError = loadError(actionTypes.DATA_LOAD_ERROR);
const dataChanged = createAction(actionTypes.DATA_CHANGED);

export const getProducts = (reelId, isImage) => async (dispatch, getState) => {
  const { mediaCategory } = getState();
  const { subcategories } = mediaCategory;
  dispatch(dataChanged({loading: true}));
  console.log(isImage);
  let mappedProducts = [];
  if (reelId) {
    const prodUrl = isImage ? [constants.GET_PRODUCT_BY_IMAGE_ID] + reelId : [constants.GET_PRODUCT_BY_REEL_ID] + reelId;
    try {
      await axios.get(prodUrl,{
          headers: {
            'authorization': GetUserToken(),
          },
        })
      .then(function (response) {
        const data = response.data;
        // update category array
        const productData = data.responseData;

        if (data.errorMessage) {
          dispatch(
            dataChanged({
              [constants.DATA_ERROR]: data.errorMessage,
              loading: false
            })
          )
        } else {
          mappedProducts = productData.map(el => el.productId);
          dispatch(dataChanged({
            [constants.MAPPED_PRODUCTS]: mappedProducts,
          }));
        }
      });
    } catch (error) {
      dispatch(dataChanged({loading: false}));
      const { status } = error && error.response || {};
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({ authenticatedSession: false}));
      } else {
        dispatch(dataChanged({
          authenticatedSession: true,
        }));
      }
    }
  }

  axios.get([constants.PRODUCTS_API],
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const productData = data.responseData;
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        // update category array
        const productResponse =productData[0] && productData[0].products || [];

        // expense loop
        productResponse.forEach(element => {
          element.mapped = mappedProducts.indexOf(element.productId) > -1
        });
        
        dispatch(dataChanged({
          [constants.PRODUCTS]: productResponse,
          authenticatedSession: true,
          loading: false
        }));
      }
      
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
}




export const initFormValues = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.MEDIA_ID]: '',
    })
  )
}

export const viewFormData = (data) => (dispatch) => {
  // console.log('pr', data);
  dispatch(
    dataChanged({
      [constants.FORM_DATA]: data,
    })
  )
};

export const addOrRemoveProductToMappingArray = (data) => (dispatch, getState) => {
  const productState = getState().products;
  const { mappedProducts, products } = productState;
  const { productId } = data;
  const updatedArray = [...mappedProducts];

  if (updatedArray.indexOf(parseInt(productId)) > -1) {
    updatedArray.splice(updatedArray.indexOf(parseInt(productId)), 1);
  } else {
    updatedArray.push(parseInt(productId));
  }

  // expense loop
  const updatedProducts = [...products];
  updatedProducts.forEach(element => {
    element.mapped = updatedArray.indexOf(element.productId) > -1
  });


  dispatch(
    dataChanged({
      [constants.MAPPED_PRODUCTS]: updatedArray,
      [constants.PRODUCTS]: products,
    })
  )
};

export const updateActive = (productData) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.PRODUCT_STATUS_UPDATE_API;
  const url = api(productData.productId);
  const productState = getState().products;


  axios.post(url,{
    activeStatus: !productData.active
  },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const activeData = data.responseData[0];

      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {

        const updatedData = productState.products.map(element => {
          if (element.productId === productData.productId) {
            element.active = !productData.active
          }
          return element;
        });
      
        if(productState.productSearch.isSearch && !_.isEmpty(productState.productSearch?.searchResult)){
          
          const updatedSearchData = productState.productSearch.searchResult.map(element => {
            if (element.productId === productData.productId) {
              element.active = !productData.active
            }
            return element;
          });
      
          dispatch(dataChanged({
            [constants.PRODUCT_SEARCH]:{
              searchResult:updatedSearchData || []
            }
          }));
        }
      
        dispatch(dataChanged({
          [constants.PRODUCTS]: updatedData || [],

          [constants.ACTIVE]: activeData,
          authenticatedSession: true,
          loading: false
        }));
      
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};

export const updateStock = (data) => (dispatch, getState) => {
  dispatch(dataChanged({loading: true}));
  const api = constants.PRODUCT_STOCK_UPDATE_API;
  const url = api(data.productId);
  const productState = getState().products;

  const updatedData = productState.products.map(element => {
    if (element.productId === data.productId) {
      element.isStockAvailable = !data.isStockAvailable
    }
    return element;
  });

  dispatch(dataChanged({
    [constants.PRODUCTS]: updatedData || [],
    // loading: false
  }));

  axios.post(url,{
    stockStatus: data.isStockAvailable
  },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;
      // update category array
      const activeData = data.responseData[0];
      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        dispatch(dataChanged({
          [constants.ACTIVE]: activeData,
          authenticatedSession: true,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });

  // dispatch(
  //   dataChanged({
  //     [constants.ACTIVE]: data.active,
  //   })
  // );
};


export const mapReelToProduct = (reelId, isImage) => (dispatch, getState) => {
  const productState = getState().products;
  const { mappedProducts } = productState;
  dispatch(dataChanged({loading: true}));
  const api = isImage ? constants.PRODUCTS_IMAGE_MAP_API : constants.PRODUCTS_MAP_API;
  const url = api(reelId);

  console.log('mapReelToProduct', isImage);
  axios.post(url,{
    products: mappedProducts
  },
    {
      headers: {
        // 'Access-Control-Allow-Origin': '*',
        'authorization': GetUserToken(),
      },
    })
    .then(function (response) {
      const data = response.data;

      if (data.errorMessage) {
        dispatch(
          dataChanged({
            [constants.DATA_ERROR]: data.errorMessage,
            loading: false
          })
        )
      } else {
        window.alert('Selected Products Mapped Successfully!');
        dispatch(dataChanged({
          // [constants.ACTIVE]: activeData,
          authenticatedSession: true,
          loading: false
        }));
      }
    })
    .catch(function (error) {
       const { status } = error && error.response || 0;
      if (status == UN_AUTHERIZED_CODE) {
        //redirect to login, clear local storage
        localStorage.removeItem('userData');
        dispatch(dataChanged({loading: false, authenticatedSession: false}));
      } else {
        dispatch(dataChanged({loading: false}));
      }
      // dispatch(boundError({data: error, loading: false}));
    });
};


export const getProductSearchResult =  (searchText)=>(dispatch)=>{
  dispatch(dataChanged({loading: true}));

  axios.post(`${BASE_URL}/product/search?query=${searchText}`,{},
     {
       headers: {
         // 'Access-Control-Allow-Origin': '*',
         'authorization': GetUserToken(),
       },
     }).then( (response)=> {
      const data = response.data;

      dispatch(dataChanged({
        [constants.PRODUCT_SEARCH]:{
          searchResult:  data.responseData,
          isSearch:true,
          searchText:searchText
        },
      }));
      dispatch(dataChanged({loading: false}));

    })
    .catch(function (error) {
       const { status } = error && error.response || 0;

        console.error(error);
        dispatch(dataChanged({loading: false}));
    });
     // return response;
 
 }

 export const searchProductResultOff = () => (dispatch) => {
  dispatch(
    dataChanged({
      [constants.PRODUCT_SEARCH]: {
        isSearch : false,
        searchText:''
      },
    })
  )
};